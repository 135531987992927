export const isValidEmail = email =>
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
)
export const isValidAlpha = value =>
  /^[a-zA-Z]{1,25}$/.test(
    value
)
export const isValidUrl = value =>
/(((^https?)|(^ftp)):\/\/([\-\w]+\.)+\w{2,3}(\/[%\-\w]+(\.\w{2,})?)*(([\w\-\.\?\\\/+@&#;`~=%!]*)(\.\w{2,})?)*\/?)/i.test(
    value
)

export const isValidZip = value =>
/^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/.test(
    value
)

export const isValidPhone = value =>
/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/.test(
    value
)

