import React, { Component } from "react";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import "./feedbackdetail.scss";
import {DragImage} from "../../../components";

class FeeedbackDetail extends Component {
OnReplyAction=()=>{
  this.setState({isReply: true})
}
  renderDate = date => {
    return moment(date).format("M/D/YY");
  };
  renderTime = date => {
    return moment
      .utc(date)
      .local()
      .format("hh:mm a");
  };
  render() {
    const { 
      labels, 
      isReply,
      feedbackData,
      onFormFieldChange,
      OnReplyForm,
      OnReplyAction,
      getImgSrc 
    }  = this.props;
    const{
      FeedbackId,
      Title,
      Content,
      DateTime,
      UserId,
      ReplyTitle,
      ReplyDescription,
      Img,
  } = feedbackData;
    return (
      <div className="feedback-detail-component">
        {!feedbackData.length==0 ? (
          ""
        ) : (
          isReply?
          <div className="feedback-reply-form">
                
                <div className={`${!ReplyTitle  ? "inValid " : " "} input-field`} >
                    <h6>{labels.ADMIN_TITLE_LABEL}</h6>
                    <div>
                    <input
                        name={"ReplyTitle"}
                        type={"text"}
                        value={ReplyTitle}
                        onChange={e => onFormFieldChange(e)}
                        test-data-id={"title-input-FB-reply"}
                        disabled
                    />
                    </div>
                </div>
                <div className={`${!ReplyDescription  ? "inValid " : " "} input-field-textarea `}>
                    <h6>{labels.ADMIN_CONTENT_LABEL}</h6>
                    <textarea
                        name="ReplyDescription"
                        rows={5}
                        type="text"
                        value={!ReplyDescription ? "" : ReplyDescription} 
                        onChange={e => onFormFieldChange(e)}
                        // onBlur={()=>saveProduct()}
                        test-data-id={'workplace-comments'}
                    />
                </div>
                <DragImage getImgSrc={getImgSrc} imgSrc={Img} labels={labels} />
                <button test-data-id={"search-systemlabels-btn"}
                    className="btn btn-login"
                    onClick={() => OnReplyAction()}
                     >
                        {labels.REPLY_LABEL}
                   </button> 
            </div>
            :
          <div className="FeedbackWrapper">
            <div className="titleLabel">{Title}</div>
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                <div className="feedbackItem">
                  <label className="labelName">
                    {labels.ADMIN_DATE_LABEL}:
                  </label>
                  <label className="answerLabel">
                    {this.renderDate(DateTime)}
                  </label>
                </div>

                <div className="feedbackItem">
                  <label className="labelName">
                    {labels.ADMIN_DETAILS_LABEL}
                  </label>
                  <label className="answerLabel">
                    {ReactHtmlParser(Content)}
                  </label>
                </div>
              </div>
            </div>
            <img className="FeedbackImg" src={Img} alt={labels.ADMIN_ATTACHMENT_LABEL} />
            <button test-data-id={"search-systemlabels-btn"}
                    className="btn btn-login"
                    onClick={() => OnReplyForm()}
                     >
                        {labels.REPLY_LABEL}
                   </button> 
          </div>
          
        )}
      </div>
    );
  }
}
export default FeeedbackDetail;
