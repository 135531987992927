import React, { Component } from "react";
import { Column, Button, CustomInput, Select, ConfirmDialog } from "../../components";
import "./features.scss";
import {
  updateUserFeatureApi,
  updateMultiUserFeaturesApi,
  getAllUsersApi,
  getAllFeatures,
  addFeatureApi,
  getsubNavigation,
  addSubFeatureApi,
  updateSubFeatureApi,
  updateSubFeaturesAreaApi,
  getFeaturesAreas,
  getFeatures,
  addSubFeaturesAreaApi,
  deleteSubFeaturesAreaApi,
  deleteSubFeatureApi,
  updateFeatureApi,
  getFeatureAreasApi,
  getAppTypesApi,
  GetAllAdmins,
} from "../../Services/apiCalls";
import AddFeature from "./components/AddFeature.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SALES, COMMON } from "../../Services/labels";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
import { validateInputField } from "../../utilities/validator";
import { isValidEmail } from "../../utilities/validations";

class Sales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      dialogMessage:"",
      isCollapsed2: true,
      appTypes: [],
      featureAreas: [],
      filteredFeatureAreas: [],
      features: [],
      filteredFeatures: [],
      AllUsers: [],
      selectedAppType: {},
      selectedFeatureArea: {},
      selectedFeature: {},
    };
  }
  componentDidMount = () => {
    setTimeout(() => {
      this.getAllAppTypes();
      this.getAllUsers();
    }, 10);
  };

  getAllAppTypes = () => {
    getAppTypesApi().then((res) => {
      const appTypes = res.items.map((item) => {
        return {
          ...item,
          value: item.AppTypeId,
          label: item.Title,
        };
      });
      const selectedAppType = appTypes && appTypes[0];
      this.setState({
        appTypes,
        selectedAppType,
        selectedFeature: {
          appTypes,
          selectedAppType,
        },
      });
      this.getAllFeaturesAreas();
    });
  };

  getAllUsers = () => {
    getAllUsersApi().then((res) => {
      if (res.success) {
        const newList = res.items.map((item) => {
          const newItem = {
            value: item.UserId,
            label: item.Name,
          };
          return newItem;
        });
        newList.unshift({ value: -1, label: "--All--" });
        this.setState({
          AllUsers: newList,
        });
        this.onFormSelectChange("Users", newList[0]);
      }
    });
  };

  getAllFeaturesAreas = () => {
    getFeatureAreasApi().then((res) => {
      const featureAreas = res.items.map((item) => {
        return {
          ...item,
          value: item.FeatureAreaId,
          label: item.Title,
        };
      });
      const filteredFeatureAreas = featureAreas.filter(
        (x) => x.AppTypeId == this.state.selectedAppType.AppTypeId
      );
      const selectedFeatureArea =
        filteredFeatureAreas && filteredFeatureAreas[0];
      this.setState({
        featureAreas,
        selectedFeature: {
          ...this.state.selectedFeature,
          featureAreas,
          filteredFeatureAreas,
          selectedFeatureArea,
        },
        filteredFeatureAreas,
        selectedFeatureArea,
      });
    });
  };

  getUserFeatures = (User) => {
    this.props.toggleLoadingAction(true);
    const { selectedAppType, selectedFeatureArea, selectedUser } = this.state;
    getFeatures(selectedAppType.value, selectedFeatureArea.value, User).then(
      (res) => {
        if (res.success) {
          var itemsList = res.items.map((item) => ({
            ...item,
            isCollapsed: item.SubNavigation.length > 0,
          }));
          const filteredFeatures = itemsList.filter(
            (item) => item.FeatureType == selectedFeatureArea.value
          );
          this.setState({
            features: itemsList,
            filteredFeatures: filteredFeatures,
          });
          this.props.toggleLoadingAction(false);
        }
      }
    );
  };
  getFeatures = (User) => {
    if (User) {
      this.getUserFeatures(User);
    } else {
      this.getAllFeature();
    }
  };
  getAllFeature = () => {
    getAllFeatures().then((res) => {
      const filteredFeatures = res.items
        .filter(
          (item) =>
            item.AppType === this.state.selectedAppType.AppTypeId &&
            item.FeatureType == this.state.selectedFeatureArea.FeatureAreaId
        )
        .map((item) => ({
          ...item,
          isCollapsed: item.SubNavigation.length > 0,
          value: item.Id,
          label: item.Name,
          SubNavigation:
            item.SubNavigation.length < 0 ? [] : item.SubNavigation,
        }));
      console.log({ filteredFeatures });
      const filteredWithNone = [
        { value: 0, label: "None" },
        ...filteredFeatures,
      ];
      this.setState({
        features: res.items.map((item) => ({
          ...item,
          isCollapsed: item.SubNavigation.length > 0,
          value: item.Id,
          label: item.Name,
        })),
        filteredFeatures,
        selectedFeature: {
          ...this.state.selectedFeature,
          filteredFeatures: filteredWithNone,
          selectedParent: filteredWithNone[0],
        },
      });
      this.handleAddNew();
    });
  };

  ///////////////////////////

  handleFormFieldChange = (e, typeOf) => {
    const { name, value, type } = e.target;
    if (typeOf == 1) {
      const { selectedFeature } = this.state;
      const { selectedSubFeature } = selectedFeature;
      this.setState({
        selectedFeature: {
          ...selectedFeature,
          selectedSubFeature: {
            ...selectedSubFeature,
            [name]: value,
            [`isValid${name}`]: validateInputField(value, type),
          },
        },
      });
    } else {
      this.setState({
        selectedFeature: {
          ...this.state.selectedFeature,
          [name]: value,
          [`isValid${name}`]: validateInputField(value, type),
        },
      });
    }
  };
  handleFormFieldBlur = (e) => {
    let { selectedFeature } = this.state;
    selectedFeature = {
      ...selectedFeature,
      AppType:
        selectedFeature.selectedAppType &&
        selectedFeature.selectedAppType.AppTypeId,
      FeatureType:
        selectedFeature.selectedFeatureArea &&
        selectedFeature.selectedFeatureArea.FeatureAreaId,
      ParentFeatureId:
        selectedFeature.selectedParent && selectedFeature.selectedParent.Id,
    };
    console.log("SssS ", selectedFeature);
    const { Name, ParentId, Id, isValidName } = selectedFeature;
    if (Name) {
      console.log("JJJJ ", selectedFeature);
      if (!isValidName) {
        return;
      }
      this.setState({
        lastFocusField: e.target.name,
      });
      if (Id == -1) this.addFeature(selectedFeature);
      else this.updateFeature(selectedFeature);
    }
  };
  //   handleFormSelectChange = (type, selectedOption) => {
  //     this.setState({
  //       selectedFeature: {
  //         ...this.state.selectedFeature,
  //         selectedParent: selectedOption,
  //         ParentId: selectedOption.FeatureAreaId,
  //       },
  //     });
  //   };
  addFeature = (item) => {
    addFeatureApi(item)
      .then((res) => {
        if (res.data.success) {
          const info = {
            message: "You have successfully added the feature",
            status: "success",
          };
          this.props.setNotificationAction(info);
          res.data.items.isActive = true;
          this.setState({
            selectedFeature: {
              ...item,
              Id: res.data.items.Id,
              subNavigation: [],
            },
            features: this.state.features.concat(res.data.items),
            filteredFeatures: this.state.filteredFeatures.concat(
              res.data.items
            ),
          });
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  updateFeature = (item) => {
    updateFeatureApi(item)
      .then((res) => {
        console.log({ res });
        if (res.success) {
          const info = {
            message: "You have successfully updated the feature",
            status: "success",
          };
          this.props.setNotificationAction(info);
          this.setState({
            features: this.state.features.map((a) =>
              a.Id == res.items.Id ? res.items : a
            ),
            filteredFeatures: this.state.filteredFeatures.map((a) =>
              a.Id == res.items.Id ? res.items : a
            ),
          });
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  addSubFeature = (item) => {
    addSubFeatureApi(item)
      .then((res) => {
        res.data.items.isActive = true;
        const { data } = res;
        this.setState({
          selectedFeature: {
            ...this.state.selectedFeature,
            subNavigation: this.state.selectedFeature.subNavigation.concat(
              data.items
            ),
          },
        });
      })
      .catch((err) => console.log("Err ", err));
  };
  updateSubFeature = (item) => {
    updateSubFeatureApi(item)
      .then((res) => {
        const { data } = res;
        res.data.items.isActive = true;
        this.setState({
          selectedFeature: {
            ...this.state.selectedFeature,
            // selectedSubFeature:this.state.selectedFeature.selectedSubFeature.map(a=> a.SubFeatureId == data.items.SubFeatureId ? data.items : a),
            subNavigation: this.state.selectedFeature.subNavigation.map((a) =>
              a.SubFeatureId == data.items.SubFeatureId ? data.items : a
            ),
          },
        });
      })
      .catch((err) => console.log("Err ", err));
  };
  handleListItemClick = (item, type) => {
    const {
      selectedAppType,
      selectedFeatureArea,
      selectedFeature,
      features,
    } = this.state;
    console.log({
      item,
      type,
      dinf: features.find((f) => f.Id === item.ParentFeatureId) || {
        value: -1,
        label: "None",
      },
    });

    this.setState({
      selectedFeature: {
        ...selectedFeature,
        ...item,
        selectedAppType,
        selectedFeatureArea,
        selectedParent: features.find((f) => f.Id === item.ParentFeatureId) || {
          value: -1,
          label: "None",
        },
      },
      filteredFeatures: this.state.filteredFeatures.map((a) => ({
        ...a,
        isSelected: a.Id == item.Id,
        isActive: a.Id == item.Id,
        isCollapsed:
          item.ParentFeatureId && item.ParentFeatureId > 0
            ? false
            : a.Id == item.Id
            ? !a.isCollapsed
            : true,
        ...(item.ParentFeatureId &&
          item.ParentFeatureId &&
          item.SubNavigation && {
            SubNavigation: item.SubNavigation.map((b) => ({
              ...b,
              isSelected: b.Id == item.Id,
              isActive: b.Id == item.Id,
            })),
          }),
      })),
      isCollapsed2: false,
    });
  };
  handleAddNew = (type) => {
    const { filteredSubFeaturesAreas, selectedSubFeaturesArea } = this.state;
    const { selectedFeature, appTypes, features, featureAreas } = this.state;
    const filteredWithNone = [{ value: 0, label: "None" }, ...features];
    this.setState({
      selectedFeature: {
        appTypes,
        filteredFeatureAreas: featureAreas,
        filteredFeatures: filteredWithNone,
        Id: -1,
      },
      isCollapsed2: false,
    });
  };
  handleListItemDelete = (item, type) => {
    if (type == 1) {
      deleteSubFeatureApi(item.SubFeatureId).then((res) => {
        const { selectedFeature } = this.state;
        if (res.success) {
          this.setState({
            selectedFeature: {
              ...selectedFeature,
              selectedSubFeature:
                selectedFeature.selectedSubFeature.SubFeatureId ==
                item.SubFeatureId
                  ? {}
                  : selectedFeature.selectedSubFeature,
              subNavigation: selectedFeature.subNavigation.filter(
                (x) => x.SubFeatureId != item.SubFeatureId
              ),
            },
            isCollapsed3: false,
          });
        }
      });
    }
  };
  AppTypeSelectChange = (type, selectedOption) => {
    const { features } = this.state;
    const filteredFeatures = features.filter(
      (item) => item.FeatureType == selectedOption.AppTypeId
    );
    const filteredFeatureAreas = this.state.featureAreas.filter(
      (x) => x.AppTypeId == selectedOption.AppTypeId
    );
    this.setState({
      selectedAppType: selectedOption,
      filteredFeatures,
      filteredFeatureAreas,
      selectedFeaturesArea: filteredFeatureAreas[0],
    });
    this.onFormSelectChange("FeaturesArea", filteredFeatureAreas[0]);
  };
  onFormSelectChange = (type, selectedOption, subType) => {
    if (subType === 1) {
      const { features, selectedFeature } = this.state;
      const { selectedFeatureArea, selectedAppType } = selectedFeature;
      if (type == "FeaturesArea") {
        let filteredFeatures = features.filter(
          (item) =>
            item.FeatureType == selectedOption.value &&
            item.AppType == selectedAppType.value
        );
        filteredFeatures = [{ value: -1, label: "None" }, ...filteredFeatures];
        this.setState({
          selectedFeature: {
            ...selectedFeature,
            selectedFeatureArea: selectedOption,
            filteredFeatures,
          },
        });
      } else if (type == "AppType") {
        let filteredFeatures = features.filter(
          (item) =>
            item.AppType == selectedOption.AppTypeId &&
            !!selectedFeatureArea &&
            item.FeatureType == selectedFeatureArea.FeatureAreaId
        );
        const filteredFeatureAreas = this.state.featureAreas.filter(
          (x) => x.AppTypeId == selectedOption.AppTypeId
        );
        filteredFeatures = [{ value: -1, label: "None" }, ...filteredFeatures];
        console.log("Filtetered Featires ", filteredFeatureAreas);
        this.setState({
          selectedFeature: {
            ...selectedFeature,
            selectedAppType: selectedOption,
            filteredFeatures,
            filteredFeatureAreas,
            selectedFeatureArea: filteredFeatureAreas[0],
          },
        });
      } else if (type == "Feature") {
        this.setState({
          selectedFeature: {
            ...selectedFeature,
            selectedParent: selectedOption,
          },
        });
      }
    } else {
      const {
        features,
        selectedFeatureArea,
        selectedAppType,
        selectedFeature,
      } = this.state;
      if (type == "FeaturesArea") {
        const filteredFeatures = features.filter(
          (item) => item.FeatureType == selectedOption.FeatureAreaId
        );
        //.filter(item=> item.FeatureType == selectedOption.FeaturesAreaId && item.AppType == selectedAppType.value);
        this.setState({
          selectedFeatureArea: selectedOption,
          filteredFeatures,
        });

        //code to get admins
        if (selectedOption.value == 6) {
          GetAllAdmins().then((res) => {
            const newList = res.items.map((item) => {
              const newItem = {
                value: item.UserId,
                label: item.UserFirstName + " " + item.UserLastName,
              };
              return newItem;
            });
            this.setState({
              AllUsers: [],
              AllUsers: newList,
            });
          });
        } else {
          this.setState({
            AllUsers: [],
          });
          this.getAllUsers();
        }
      } else if (type == "Users") {
        this.setState({ filteredFeatures: [] });
        if (selectedOption.value == -1) {
          this.setState({ userFeaturesOn: false });
          this.getFeatures(false);
        } else {
          this.setState({ userFeaturesOn: true });
          this.getFeatures(selectedOption.value);
        }
        this.setState({
          selectedUser: selectedOption,
        });
      }
    }
  };
  handleCheckBoxClick = (selectedItem, type) => {
    this.props.toggleLoadingAction(true);
    if (type) {
      if (selectedItem.IsActive == true || type.IsActive == true) {
        if (this.state.userFeaturesOn) {
          selectedItem = {
            ...selectedItem,
            IsActive: !selectedItem.IsActive,
          };
          let SubnavFeataures = selectedItem.SubNavigation.map((k) => ({
            ...k,
            IsActive: type.Id == k.Id ? !type.IsActive : k.IsActive,
          }));
          this.setState({
            filteredFeatures: this.state.filteredFeatures.map((k) => ({
              ...k,
              SubNavigation:
                selectedItem.Id == k.Id ? SubnavFeataures : k.SubNavigation,
            })),
          });
          type.UserId = selectedItem.UserId;
          updateUserFeatureApi(type)
            .then((res) => {
              if (res.success) {
                this.props.toggleLoadingAction(false);
              } else {
                const info = {
                  message: "Some Error Occured",
                  status: "error",
                };
                this.props.toggleLoadingAction(false);
                this.props.setNotificationAction(info);
              }
            })
            .catch((err) => console.log("Err ", err));
        } else {
    this.props.toggleLoadingAction(true);
          type.selectedItem = selectedItem;
          this.setState({
            changeFeaturesData: type,
            dialogMessage: "Are you sure you want to update feature? it will also revert the user specific feature as well"
          })
        }
      }
    } else {
      if (this.state.userFeaturesOn) {
        selectedItem = {
          ...selectedItem,
          IsActive: !selectedItem.IsActive,
        };
        var subItemFilter = selectedItem.SubNavigation.map((a) => ({
          ...a,
          IsActive: selectedItem.IsActive,
        }));
        this.setState({
          filteredFeatures: this.state.filteredFeatures.map((k) => ({
            ...k,
            IsActive: selectedItem.Id == k.Id ? !k.IsActive : k.IsActive,
            SubNavigation:
              selectedItem.Id == k.Id ? subItemFilter : k.SubNavigation,
          })),
          features: this.state.features.map((k) => ({
            ...k,
            IsActive: selectedItem.Id == k.Id ? !k.IsActive : k.IsActive,
            SubNavigation:
              selectedItem.Id == k.Id ? subItemFilter : k.SubNavigation,
          })),
        });
        var selectedFeature = {
          AppType: selectedItem.AppType,
          Id: selectedItem.Id,
          IsActive: selectedItem.IsActive,
          SubNavActive: selectedItem.SubNavActive,
          UserId: selectedItem.UserId,
        };

        var selectedSubItems = selectedItem.SubNavigation.map((a) => ({
          ...a,
          IsActive: selectedItem.IsActive,
          UserId: selectedItem.UserId,
        }));
        selectedSubItems.push(selectedFeature);
        updateMultiUserFeaturesApi(selectedSubItems)
          .then((res) => {
            if (res.success) {
              this.props.toggleLoadingAction(false);
            } else {
              const info = {
                message: "Some Error Occured",
                status: "error",
              };
              this.props.toggleLoadingAction(false);
              this.props.setNotificationAction(info);
            }
          })
          .catch((err) => console.log("Err ", err));
      } else { 
        this.props.toggleLoadingAction(true);
        this.setState({
          changeFeaturesData: selectedItem,
          dialogMessage: "Are you sure you want to update feature? it will also revert the user specific feature as well"
        })
      }
    }
  };
  handleYesClick = () => {
    this.props.toggleLoadingAction(true);
    this.setState({ 
      dialogMessage: ""
   });
   
   let selectedItem ="";
    var changeFeaturesData = this.state.changeFeaturesData;
    if(changeFeaturesData.selectedItem){
      selectedItem = changeFeaturesData.selectedItem;
      changeFeaturesData.selectedItem="";
    }

    var model = {
      Id: changeFeaturesData.Id,
      IsActive: !changeFeaturesData.IsActive,
      Name: changeFeaturesData.Name,
      CssClass: changeFeaturesData.CssClass,
      CssClassIcon: changeFeaturesData.CssClassIcon,
      AppType: changeFeaturesData.AppType,
      FeatureType: changeFeaturesData.FeatureType,
      ParentFeatureId: changeFeaturesData.ParentFeatureId||0,
    };
    
    updateFeatureApi(model)
    .then((res) => {
      if (res.success) {
        this.props.toggleLoadingAction(false);
        if(selectedItem){
          selectedItem = {
            ...selectedItem,
            IsActive: !selectedItem.IsActive,
          };
          let SubnavFeataures = selectedItem.SubNavigation.map((k) => ({
            ...k,
            IsActive: changeFeaturesData.Id == k.Id ? !changeFeaturesData.IsActive : k.IsActive,
          }));
          this.setState({
            filteredFeatures: this.state.filteredFeatures.map((k) => ({
              ...k,
              SubNavigation:
                selectedItem.Id == k.Id ? SubnavFeataures : k.SubNavigation,
            })),
          });
        }else{   
          this.setState({
            filteredFeatures: this.state.filteredFeatures.map((k) => ({
              ...k,
              IsActive: changeFeaturesData.Id == k.Id ? !k.IsActive : k.IsActive,
            })),
            features: this.state.features.map((k) => ({
              ...k,
              IsActive: changeFeaturesData.Id == k.Id ? !k.IsActive : k.IsActive,
            })),
          });
        }
        this.setState({ 
          dialogMessage: "",
          singleFeatureupdate: false,
       });
      } else {
        const info = {
          message: "Some Error Occured",
          status: "error",
        };
        this.setState({ 
          changeFeaturesData:"",
          dialogMessage: "",
          singleFeatureupdate: false,
       });
        this.props.toggleLoadingAction(false);
        this.props.setNotificationAction(info);
      }
    })
    .catch((err) => console.log("Err ", err)); 
  };
  handleNoClick = () => {
    this.props.toggleLoadingAction(false);
    this.setState({ dialogMessage: "" });
  };
  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      AllUsers,
      selectedFeatureArea,
      featureAreas,
      filteredFeatures,
      selectedFeature,
      selectedAppType,
      appTypes,
      filteredFeatureAreas,
      selectedUser,
      dialogMessage
    } = this.state;
    console.log("State ", this.state);
    return (
      <div className="columns-wrapper features-wrapper">
        {dialogMessage && (
          <ConfirmDialog>
            <ConfirmDialog.Message>
              {dialogMessage}
            </ConfirmDialog.Message>
            <ConfirmDialog.ActionButtons>
              <button
                className="dialog-btn"
                onClick={this.handleYesClick}
              >
                {"Yes"}
              </button>
              <button
                className="dialog-btn"
                onClick={this.handleNoClick}
              >
                {"No"}
              </button>
            </ConfirmDialog.ActionButtons>
          </ConfirmDialog>
        )}
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={"Features"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={COMMON.EXPAND_HELP}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Features"}</p>
            </div>
            <Button
              testId={"open-system-label-form-btn"}
              className="button-primary AddBtn"
              tooltipButton={labels.ADMIN_OPEN_SYSTEM_LABELS_FORM}
              tooltipHelp={labels.ADMIN_HELP_OPEN_SYSTEM_LABELS_FORM}
              onClick={() => this.handleAddNew()}
            />
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="input-select-field setHeight50p">
              <h6>{"App Types"}</h6>
              <Select
                name="AppType"
                className={`select-input`}
                placeholder={"Please select a FeaturesArea"}
                value={(selectedAppType && selectedAppType) || ""}
                onChange={(selectedOption) =>
                  this.AppTypeSelectChange("AppType", selectedOption)
                }
                clearable={false}
                searchable={false}
                options={appTypes}
              />
            </div>
            <div className="input-select-field setHeight50p">
              <h6>{"Features Area"}</h6>
              <Select
                name="FeeCurrencyType"
                className={`select-input`}
                placeholder={"Please select a Features Area"}
                value={(selectedFeatureArea && selectedFeatureArea) || ""}
                onChange={(selectedOption) =>
                  this.onFormSelectChange("FeaturesArea", selectedOption)
                }
                clearable={false}
                searchable={false}
                options={filteredFeatureAreas}
              />
            </div>
            <div className="input-select-field setHeight50p">
              <h6>{"Users"}</h6>
              <Select
                name="Users"
                className={`select-input`}
                placeholder={"Please select a user"}
                value={(selectedUser && selectedUser) || ""}
                onChange={(selectedOption) =>
                  this.onFormSelectChange("Users", selectedOption)
                }
                clearable={false}
                searchable={false}
                options={AllUsers}
              />
            </div>

            <div className="list-wrapper">
              {filteredFeatures &&
                filteredFeatures.map((item) => (
                  <div className="list-wrapper">
                    <div
                      //   test-data-id={`workplace-list-item-${item.Name && item.Name.replace(
                      //     " ",
                      //     "-"
                      //   ).toLowerCase()}`}
                      className={`list-item ${item.isActive ? "active" : ""}`}
                      key={item.Id}
                    >
                      {
                        <span
                          className={`arrow-icon ${
                            !item.isCollapsed && "uncollapsed"
                          } ${
                            item.SubNavigation && item.SubNavigation.length > 0
                              ? "visible"
                              : "hidden"
                          }`}
                          onClick={() => this.handleListItemClick(item)}
                        ></span>
                      }
                      <span className="list-checkbox">
                        <input
                          type="checkbox"
                          checked={item.IsActive}
                          onChange={() => this.handleCheckBoxClick(item)}
                        ></input>
                      </span>
                      <div
                        className="list_item_title"
                        onClick={() => this.handleListItemClick(item)}
                      >
                        <span>{item.Name}</span>
                      </div>
                      {item.SubNavigation &&
                      item.SubNavigation.length <= 0 &&
                      item.IsActive == false ? (
                        <button
                          test-data-id={`delete-${item.Name}`}
                          type="button"
                          className="delete-btn"
                          onClick={() => this.handleListItemDelete(item)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="sub-list">
                      {!item.isCollapsed &&
                        item.SubNavigation &&
                        item.SubNavigation.map((subItem) => (
                          <div
                            // test-data-id={`workplace-list-item-${subItem.Name && subItem.Name.replace(
                            //   " ",
                            //   "-"
                            // ).toLowerCase()}`}
                            className={`list-item ${
                              subItem.isActive ? "active" : ""
                            }`}
                            key={subItem.Id}
                          >
                            <span className="list-checkbox">
                              <input
                                type="checkbox"
                                checked={subItem.IsActive}
                                onChange={() =>
                                  this.handleCheckBoxClick(item, subItem)
                                }
                              ></input>
                            </span>
                            <div
                              className="list_item_title"
                              onClick={() => this.handleListItemClick(subItem)}
                            >
                              <span>{subItem.Name}</span>
                            </div>
                            {subItem.IsActive == false ? (
                              <button
                                test-data-id={`delete-${subItem.Name}`}
                                type="button"
                                className="delete-btn"
                                onClick={() =>
                                  this.handleListItemDelete(subItem, 1)
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-1">
          <Column.Collapsed
            text={"Feature"}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Feature"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={COMMON.COLLAPSE}
              tooltipHelp={COMMON.COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-2-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="list-wrapper">
              <AddFeature
                onFormFieldBlur={this.handleFormFieldBlur}
                onFormFieldChange={this.handleFormFieldChange}
                onFormSelectChange={this.onFormSelectChange}
                selectedFeature={selectedFeature}
                lastFocusField={this.state.lastFocusField}
                handleListItemClick={this.handleListItemClick}
                handleCheckBoxClick={this.handleCheckBoxClick}
                handleListItemDelete={this.handleListItemDelete}
                labels={labels}
              />
            </div>
            {/* {selectedFeature && selectedFeature.Id > 0  && <button className="add-btn" test-data-id={'add-workplace-btn'} onClick={()=>this.handleAddNew(1)}/>} */}
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(Sales));
