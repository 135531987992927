import React, { Component } from "react";
import CKEditor from "ckeditor4-react";
import { Column, Button, CustomInput, Modal } from "../../components";
import "./systemLabels.scss";
import {
  saveSystemlabel,
  GetSystemLabelByLanguageId,
  getLanguages,
  getSubmoduleBymoduleId,
  updateSystemLabel,
  searchSystemLabel,
  getCategories,
  getUserTypes,
  AddSystemLabelsFromExcel,
} from "./components/apiCalls";
import { getFeatureAreasApi, getModules } from "../../Services/apiCalls";
import SystemLabelsFilter from "./components/SystemLabelsFilter.jsx";
import SystemLabelsList from "./components/SystemLabelsList.jsx";
import AddSystemLabel from "./components/AddSystemLabel.jsx";
import SystemLabelsExceltTable from "./components/SystemLabelsExceltTable.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import XLSX from "xlsx";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
class SystemLabels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: false,
      systemLablesExcelTable: false,
      OnSearchButton: true,
      onHMLEditor: false,
      searchKey: "",
      editorText: "",
      selectedUserTypes: 0,
      selectedCategories: 0,
      selectedModules: 0,
      selectedSubModules: 0,
      selectedLanguage: {
        value: "",
        label: "",
      },
      searchModeValue: 1,
      systemLabelItem: null,
      workplaceList: [],
      csvData: [],
      csvDataUpload: [],
      labelsFilterData: {
        SearchByText: "",
        SearchByIdentifier: "",
        Name: "",
        UserId: -1,
        WorkPlaceId: 0,
        loadingValue: 0,
        languagesList: [],
        selectedLanguage: 1,
      },
      addSystemLabelData: {
        AddSLModuleValue: "",
        languagesList: "",
        AddSLUsertypeValue: "",
        AddSLSubModuleValue: "",
        AddSLCategoryValue: "",
        systemLabelIdentifier: "",
        systemLabelmsg: "",
      },

      isHelpActive: false,
      isQuick: true,
      lastFocusField: "",
      SubModules: [],
      SystemLabelsList: [],
      Categories: [],
      UserTypes: [],
      searchBy: [],
      languages: [],
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { labels } = this.props;
    if (prevProps.labels !== labels) {
      this.setState({
        searchBy: [
          { Id: 1, Title: labels.ADMIN_SYSTEM_LABELS_TEXT, IsChecked: true },
          {
            Id: 2,
            Title: labels.ADMIN_SYSTEM_LABELS_IDENTIFIER,
            IsChecked: false,
          },
          { Id: 3, Title: labels.ADMIN_SYSTEM_LABELS_FILTER, IsChecked: false },
          {
            Id: 4,
            Title: labels.ADMIN_SYSTEM_LABELS_IMPORT_EXPORT,
            IsChecked: false,
          },
        ],
      });
    }
  }
  componentDidMount = () => {
    const { labels } = this.props;
    this.props.toggleLoadingAction(true);
    this.setState({
      searchBy: [
        { Id: 1, Title: labels.ADMIN_SYSTEM_LABELS_TEXT, IsChecked: true },
        {
          Id: 2,
          Title: labels.ADMIN_SYSTEM_LABELS_IDENTIFIER,
          IsChecked: false,
        },
        { Id: 3, Title: labels.ADMIN_SYSTEM_LABELS_FILTER, IsChecked: false },
        {
          Id: 4,
          Title: labels.ADMIN_SYSTEM_LABELS_IMPORT_EXPORT,
          IsChecked: false,
        },
      ],
    });
    this.getAllModules();
    this.getAllCategories();
    this.getAllUserTypes();
    getLanguages().then((response) => {
      if (response.success) {
        const newList = response.items.languages.map((item) => {
          const newItem = {
            value: item.LanguageId,
            label: item.LanguageValue,
          };
          return newItem;
        });

        this.setState({
          languages: newList,
          selectedLanguage: newList[0],
          labelsFilterData: {
            ...this.state.labelsFilterData,
            languagesList: newList,
          },
          addSystemLabelData: {
            ...this.state.addSystemLabelData,
            selectedLanguage: newList[0].value,
          },
        });

        this.props.toggleLoadingAction(false);
      }else{
        this.props.toggleLoadingAction(false);
      }
    });
  };

  getAllModules = () => {
    getModules().then((response) => {
      const newList = response.items.map((item) => {
        const newItem = {
          ModuleId: item.ModuleId,
          Title: item.Title,
          IsChecked: false,
        };
        return newItem;
      });
      newList[0].IsChecked = true;
      const newList1 = response.items.map((item) => {
        const newItem = {
          value: item.ModuleId,
          label: item.Title,
        };
        return newItem;
      });
      this.setState({
        addSystemLabelData: {
          ...this.state.addSystemLabelData,
          Modules: newList1,
          SelectedModule: newList1[0].value,
        },
        Modules: newList,
      });
      this.GetSubmoduleBymoduleId(newList[0].ModuleId);
    });
  };

  handleDownloadExcel = () => {
    // let languageId = this.state.selectedLanguage;
    // GetSystemLabelByLanguageId(languageId)
    // .then(response=>{
    //     const newList = response.items.map(item => {
    //         const newItem = {
    //                 ModuleId: item.ModuleId,
    //                 Title: item.Title,
    //                 IsChecked: false
    //         }
    //         return newItem;
    //     })
    //     this.setState({
    //        csvData:newList
    //     });
    // })
  };

  getAllCategories = () => {
    getCategories().then((response) => {
      const newList = response.items.map((item) => {
        const newItem = {
          CategoryId: item.CategoryId,
          Title: item.Title,
          IsChecked: false,
        };
        return newItem;
      });
      newList[0].IsChecked = true;
      const newList1 = response.items.map((item) => {
        const newItem = {
          value: item.CategoryId,
          label: item.Title,
        };
        return newItem;
      });
      this.setState({
        addSystemLabelData: {
          ...this.state.addSystemLabelData,
          Categories: newList1,
          SelectedCategory: newList1[0],
        },
        Categories: newList,
      });
    });
  };

  getAllUserTypes = () => {
    getUserTypes().then((response) => {
      const newList = response.items.map((item) => {
        const newItem = {
          UserTypeId: item.UserTypeId,
          Title: item.Title,
          IsChecked: false,
        };
        return newItem;
      });
      newList[0].IsChecked = true;
      const newList1 = response.items.map((item) => {
        const newItem = {
          value: item.UserTypeId,
          label: item.Title,
        };
        return newItem;
      });
      this.setState({
        addSystemLabelData: {
          ...this.state.addSystemLabelData,
          UserTypes: newList1,
          SelectedUserType: newList1[0].value,
        },
        UserTypes: newList,
      });
    });
  };

  GetSubmoduleBymoduleId = (id) => {
    getSubmoduleBymoduleId(id).then((response) => {
      if (response.success && response.items.length > 0) {
        let newList = response.items.map((item) => {
          item.IsChecked = false;
          return item;
        });
        newList[0].IsChecked = true;
        const newList1 = response.items.map((item) => {
          const newItem = {
            value: item.SubModuleId,
            label: item.Title,
          };
          return newItem;
        });
        this.setState({
          addSystemLabelData: {
            ...this.state.addSystemLabelData,
            SubModules: newList1,
            SelectedSubModule: newList1[0].value,
          },
          SubModules: newList,
        });
      }
    });
  };

  ExcelTableOnChange = (e, { param }) => {
    let { csvDataUpload } = this.state;
    var SystemLabelsListitems = csvDataUpload.map((item) => {
      if (param.SystemLabelId == item.SystemLabelId) {
        item.Message = e.target.value;
      }
      return item == param ? param : item;
    });
    this.setState({
      csvDataUpload: SystemLabelsListitems,
    });
  };
  handleSystemLabelChange = (e, { param }) => {
    let { SystemLabelsListitems, SystemLabelsList } = this.state;
    SystemLabelsListitems = this.state.SystemLabelsList.map((item) => {
      if (param.SystemLabelId == item.SystemLabelId) {
        item.Message = e.target.value;
      }
      return item == param ? param : item;
    });
    this.setState({
      SystemLabelsList: SystemLabelsListitems,
      systemLabelItem: {
        Category: param.Category,
        SystemLabelId: param.SystemLabelId,
        LanguageId: this.state.labelsFilterData.selectedLanguage,
        Identifier: param.Identifier,
        Message: param.Message,
        UserType: param.UserType,
        SubModule: param.SubModule,
        Module: param.Module,
      },
    });
  };

  handleSearchModeChange = (e, { param }) => {
    if (param.Id == 4) {
      this.setState({
        searchModeValue: param.Id,
        OnSearchButton: false,
      });
    } else {
      this.setState({
        searchModeValue: param.Id,
        OnSearchButton: true,
        systemLablesExcelTable: false,
      });
    }
  };
  handelGetSystemLabelsByLanguageId = () => {
    this.props.toggleLoadingAction(true);
    let languageId = this.state.selectedLanguage.value;
    GetSystemLabelByLanguageId(languageId).then((response) => {
      let info = [];
      if (response.success) {
        const newList = response.items.map((item) => {
          var messageString = item.Message
            ? item.Message.replace(/,/g, "COMMA5588642")
            : "";
          const newItem = {
            Identifier: item.Identifier,
            Module: item.Module,
            Message: messageString,
            SystemLabelId: item.SystemLabelId,
          };
          return newItem;
        });
        if (response.message == "No item for selected language") {
          info = {
            message: this.props.labels
              .ADMIN_SYSTEM_LABELS_IMPORT_NO_SYSTEMLABEL_FOUND_SO,
            status: "success",
          };
        } else {
          info = {
            message: this.props.labels.ADMIN_SYSTEMLABELS_EXPORT_SUCCESS,
            status: "success",
          };
        }
        this.setState({
          csvData: newList,
        });
        let btn = document.querySelector("#csvExportBtn");
        btn.click();
        this.props.setNotificationAction(info);
        this.props.toggleLoadingAction(false);
      } else {
        this.props.toggleLoadingAction(false);
      }
    });
  };
  handleFilterCheckedChange = (e, { param }) => {
    param.IsChecked = !param.IsChecked;
    if (e.target.name == "UserTypes") {
      let { userTypesList, UserTypes } = this.state;
      userTypesList = this.state.UserTypes.map((item) => {
        return item == param ? param : item;
      });
      this.setState({
        UserTypes: userTypesList,
      });
    }
    if (e.target.name == "Categories") {
      let { categoriesList, Categories } = this.state;
      categoriesList = this.state.Categories.map((item) => {
        return item == param ? param : item;
      });
      this.setState({
        Categories: categoriesList,
      });
    }
    if (e.target.name == "Modules") {
      let { modulesList, Modules } = this.state;
      modulesList = this.state.Modules.map((item) => {
        if ((item.ModuleId != param.ModuleId) & (item.IsChecked == true)) {
          item.IsChecked = false;
        }
        return item == param ? param : item;
      });
      this.GetSubmoduleBymoduleId(param.ModuleId);
      this.setState({
        Modules: modulesList,
      });
    }
    if (e.target.name == "SubModules") {
      let { submodulesList, SubModules } = this.state;
      submodulesList = this.state.SubModules.map((item) => {
        return item == param ? param : item;
      });
      this.setState({
        SubModules: submodulesList,
      });
    }

    if (e.target.name == "searchBy") {
      let { searchByItems, searchBy } = this.state;
      searchByItems = this.state.searchBy.map((item) => {
        return item == param ? param : item;
      });
      this.setState({
        searchBy: searchByItems,
      });
    }
  };
  onFormSelectChange = (selectedOption) => {
    const { labelsFilterData, csvDataUpload } = this.state;
    this.setState({
      csvData: [],
      selectedLanguage: selectedOption,
      addSystemLabelData: {
        ...this.state.addSystemLabelData,
        selectedLanguage: selectedOption.value,
      },
      labelsFilterData: {
        ...labelsFilterData,
        selectedLanguage: selectedOption.value,
      },
    });
    if (csvDataUpload && csvDataUpload.length > 0) {
      let listItems = csvDataUpload.map((item) => {
        item.LanguageId = parseInt(selectedOption.value);
        return item;
      });
      this.setState({ csvDataUpload: listItems });
    }
  };
  addSystemLabelSelectChange = (e) => {
    const { addSystemLabelData } = this.state;
    const { name, value, type } = e.target;
    if (name == "SelectedModule") {
      this.GetSubmoduleBymoduleId(value);
    }
    this.setState({
      addSystemLabelData: {
        ...addSystemLabelData,
        [name]: parseInt(value),
      },
    });
    var tt = this.state.addSystemLabelData;
  };
  handleFormFieldChange = (e, typeOf, kkkk, inputType) => {
    const { name, value, type } = e.target;
    if (name == "languageId") {
      this.setState({
        labelsFilterData: {
          ...this.state.labelsFilterData,
          selectedLanguage: value,
        },
      });
    } else {
      this.setState({
        labelsFilterData: {
          ...this.state.labelsFilterData,
          [name]: value,
        },
      });
    }
  };
  addSystemlabelFieldChange = (e, typeOf, kkkk, inputType) => {
    const { name, value, type } = e.target;
    this.setState({
      addSystemLabelData: {
        ...this.state.addSystemLabelData,
        [name]: value,
      },
    });
  };
  handleSystemlabelSave = () => {
    let { addSystemLabelData } = this.state;
    var systemLbelData = {
      Identifier: addSystemLabelData.systemLabelIdentifier,
      Message: addSystemLabelData.systemLabelmsg,
      UserType: addSystemLabelData.SelectedUserType,
      SubModule: addSystemLabelData.SelectedSubModule,
      Module: addSystemLabelData.SelectedModule,
      LanguageId: addSystemLabelData.selectedLanguage,
      Category: addSystemLabelData.SelectedCategory.value,
    };
    if (!addSystemLabelData.systemLabelIdentifier) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    if (!addSystemLabelData.systemLabelIdentifier) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    this.props.toggleLoadingAction(true);
    saveSystemlabel(systemLbelData)
      .then((res) => {
        const { items, success } = res;
        if (success) {
          const info = {
            message: this.props.labels
              .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_ADD_SUCCESS,
            status: "success",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
          this.setState({
            systemLbelData: [],
            addSystemLabelData: {
              ...addSystemLabelData,
              systemLabelmsg: "",
              systemLabelIdentifier: ""
            },
          });
        } else {
          const info = {
            message: this.props.labels
              .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_ADD_FAIL,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  handlsearch = () => {
    this.setState({
      addSystemlabelModel: false,
      systemLablesExcelTable: false,
    });
    let {
      selectedUserTypes,
      selectedCategories,
      selectedSubModules,
      searchModeValue,
      labelsFilterData,
    } = this.state;
    this.props.toggleLoadingAction(true);
    if (searchModeValue == 1 || searchModeValue == 2) {
      var model = {
        LanguageId: labelsFilterData.selectedLanguage,
        SearchText: labelsFilterData.SearchByText,
        SearchIdentifier: labelsFilterData.SearchByIdentifier,
      };
      searchSystemLabel(searchModeValue, model).then((res) => {
        const { items, success } = res;
        if (success) {
          this.setState({
            SystemLabelsList: items,
          });
          this.props.toggleLoadingAction(false);
        }
      });
    } else if (searchModeValue == 3) {
      selectedUserTypes = this.state.UserTypes.filter(function (value) {
        return value.IsChecked;
      });
      selectedCategories = this.state.Categories.filter(function (value) {
        return value.IsChecked;
      });
      selectedSubModules = this.state.SubModules.filter(function (value) {
        return value.IsChecked;
      });

      var data = {
        LanguageId: labelsFilterData.selectedLanguage,
        SelectedUserTypes: selectedUserTypes,
        SelectedCategories: selectedCategories,
        SelectedSubModules: selectedSubModules,
      };
      searchSystemLabel(searchModeValue, data).then((res) => {
        const { items, success } = res;
        if (success) {
          this.setState({
            SystemLabelsList: items,
          });
          this.props.toggleLoadingAction(false);
        }
      });
    }
  };
  handleFormFieldBlur = (e, { param }) => {
    let {
      SystemLabelsListitems,
      SystemLabelsList,
      systemLabelItem,
    } = this.state;
    if (systemLabelItem != null) {
      this.setState({ loadingId: param.SystemLabelId });
      SystemLabelsListitems = this.state.SystemLabelsList.map((item) => {
        if (param.SystemLabelId == item.SystemLabelId) {
          updateSystemLabel(systemLabelItem)
            .then((res) => {
              const { items, success } = res;
              if (success) {
                const info = {
                  message: this.props.labels
                    .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_UPDATE_SUCCESS,
                  status: "success",
                };
                this.props.setNotificationAction(info);
                this.setState({
                  systemLabelItem: null,
                  loadingId: null,
                });
              } else {
                const info = {
                  message: this.props.labels
                    .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_UPDATE_FAIL,
                  status: "error",
                };
                this.props.setNotificationAction(info);
              }
            })
            .catch((err) => console.log("Err ", err));
        }
        return item == param ? param : item;
      });
    }
  };
  HandelHtmlEditorSave = () => {
    let {
      SystemLabelsListitems,
      SystemLabelsList,
      systemLabelItem,
    } = this.state;
    if (systemLabelItem != null) {
      updateSystemLabel(systemLabelItem)
        .then((res) => {
          const { items, success } = res;
          if (success) {
            const info = {
              message: this.props.labels
                .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_UPDATE_SUCCESS,
              status: "success",
            };
            this.props.setNotificationAction(info);
            this.setState({
              loadingId: null,
            });
          } else {
            const info = {
              message: this.props.labels
                .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_UPDATE_FAIL,
              status: "error",
            };
            this.props.setNotificationAction(info);
          }
        })
        .catch((err) => console.log("Err ", err));
    }
  };
  handleLanguageChange = (selectedOption) => {
    if (!selectedOption) return;
    const { labelsFilterData } = this.state;
    this.setState({
      labelsFilterData: {
        ...labelsFilterData,
        selectedLanguage: selectedOption.value,
      },
    });
  };

  HandelFilerender = (e) => {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
    // this.setState({systemLablesExcelTable: false})

    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      var languageId = parseInt(data[0].LanguageId);
      this.setState({ excelDataLanguage: languageId });
      const newList = data.map((item) => {
        var messageString = item.Message
          ? item.Message.replaceAll("COMMA5588642", ",")
          : "";
        const newItem = {
          ...item,
          Message: messageString,
          LanguageId: this.state.addSystemLabelData.selectedLanguage,
          SystemLabelId: parseInt(item.SystemLabelId),
        };
        return newItem;
      });
      /* Update state */
      this.setState({
        csvDataUpload: newList,
      });
    };

    if (rABS) {
      reader.readAsBinaryString(files[0]);
    } else {
      reader.readAsArrayBuffer(files[0]);
    }
  };

  handleCloseModal = () => {
    this.setState({
      systemLablesExcelTable: false,
      onHMLEditor: false,
    });
  };
  handleSaveExcelData = () => {
    if (
      this.state.excelDataLanguage ==
      this.state.addSystemLabelData.selectedLanguage
    ) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_CHANGE_LANGUAGE,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }

    const { csvDataUpload } = this.state;
    this.props.toggleLoadingAction(true);
    AddSystemLabelsFromExcel(csvDataUpload)
      .then((res) => {
        const { items, success } = res;
        if (success) {
          const info = {
            message: this.props.labels
              .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_ADD_SUCCESS,
            status: "success",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        } else {
          const info = {
            message: this.props.labels
              .ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_ADD_FAIL,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => {
        console.log("Err ", err);
        this.props.toggleLoadingAction(false);
      });
  };

  handleUpoladExcel = () => {
    this.setState({
      systemLablesExcelTable: true,
      addSystemlabelModel: false,
    });
  };
  HandlEditorChange = (evt) => {
    let { systemLabelItem } = this.state;
    this.setState({
      systemLabelItem: {
        ...systemLabelItem,
        Message: evt.editor.getData(),
      },
    });
  };
  HandlHTMLEditor = (e, { param }) => {
    this.setState({
      systemLabelItem: {
        Category: param.Category,
        SystemLabelId: param.SystemLabelId,
        LanguageId: this.state.labelsFilterData.selectedLanguage,
        Identifier: param.Identifier,
        Message: param.Message,
        UserType: param.UserType,
        SubModule: param.SubModule,
        Module: param.Module,
      },
      onHMLEditor: true,
    });
  };
  onAddSystemLabel=()=>{
    const{ addSystemLabelData} = this.state;
    this.setState({
      addSystemlabelModel: true,
      systemLablesExcelTable: false,
      addSystemLabelData: {
        ...addSystemLabelData,
        systemLabelmsg: "",
        systemLabelIdentifier: ""
      },
    })
  }

  render() {
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      labelsFilterData,
      addSystemlabelModel,
      onHMLEditor,
      systemLablesExcelTable,
    } = this.state;
    const { labels } = this.props;
    return (
      <div className="columns-wrapper system-labels-wraper">
        {onHMLEditor ? (
          <Modal
            onButtonClose={this.handleCloseModal}
            show={true}
            title={labels.ADMIN_ADD_SYSTEM_LABEL}
          >
            <form>
              <CKEditor
                type="classic"
                data={this.state.systemLabelItem.Message}
                onChange={this.HandlEditorChange}
              />
              <button
                test-data-id={"search-btn"}
                className="btn-login"
                onClick={(e) => this.HandelHtmlEditorSave(e)}
              >
                {labels.ADMIN_SYSTEM_LABELS_SAVE}
              </button>
            </form>
          </Modal>
        ) : (
          ""
        )}
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_FILTER}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_SYSTEM_LABELS_SYSTEM_LABELS_FILTER}</p>
            </div>
            <Button
              testId={"open-system-label-form-btn"}
              className="button-primary AddBtn"
              tooltipButton={labels.ADMIN_OPEN_SYSTEM_LABELS_FORM}
              tooltipHelp={labels.ADMIN_HELP_OPEN_SYSTEM_LABELS_FORM}
              onClick={this.onAddSystemLabel}
            />
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <SystemLabelsFilter
              OnSearchButton={this.state.OnSearchButton}
              selectedLanguage={this.state.selectedLanguage}
              handleUpoladExcel={this.handleUpoladExcel}
              handleDownloadExcel={this.handleDownloadExcel}
              onFormFieldBlur={this.handleFormFieldBlur}
              onFormSelectChange={this.onFormSelectChange}
              handelGetSystemLabelsByLanguageId={
                this.handelGetSystemLabelsByLanguageId
              }
              handleSearchModeChange={this.handleSearchModeChange}
              onFormFieldChange={this.handleFormFieldChange}
              handlsearch={this.handlsearch}
              handleModuleCheckedChange={this.handleModuleCheckedChange}
              handleuserTypesCheckedChange={this.handleuserTypesCheckedChange}
              handleCategoriesCheckedChange={this.handleCategoriesCheckedChange}
              handleFilterCheckedChange={this.handleFilterCheckedChange}
              labelsFilter={labelsFilterData}
              Categories={this.state.Categories}
              UserTypes={this.state.UserTypes}
              Modules={this.state.Modules}
              SubModules={this.state.SubModules}
              languages={this.state.languages}
              searchBy={this.state.searchBy}
              csvData={this.state.csvData}
              searchModeValue={this.state.searchModeValue}
              labels={labels}
              systemLablesExcelTable={systemLablesExcelTable}
              HandelFilerender={this.HandelFilerender}
            />
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-1">
          <Column.Collapsed
            text={
              addSystemlabelModel
                ? labels.ADMIN_ADD_SYSTEM_LABEL
                : labels.ADMIN_SYSTEM_LABEL_LIST
            }
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>
                {addSystemlabelModel
                  ? labels.ADMIN_ADD_SYSTEM_LABEL
                  : systemLablesExcelTable
                  ? labels.ADMIN_IMPORT_SYSTEM_LABELS
                  : labels.ADMIN_SYSTEM_LABEL_LIST}
              </p>
            </div>
            {addSystemlabelModel ? (
              <Button
                className="button-primary CloseBtn"
                tooltipButton={labels.ADMIN_SYSTEM_LABEL_FORM_CLOSE}
                tooltipHelp={labels.ADMIN_SYSTEM_LABEL_FORM_CLOSE_HELP}
                onClick={() =>
                  this.setState({
                    addSystemlabelModel: false,
                    systemLablesExcelTable: false,
                  })
                }
              />
            ) : systemLablesExcelTable ? (
              <div className={"button-wraper"}>
                <Button
                  className="button-primary CloseBtn"
                  tooltipButton={labels.ADMIN_SYSTEM_LABEL_FORM_CLOSE}
                  tooltipHelp={labels.ADMIN_SYSTEM_LABEL_FORM_CLOSE_HELP}
                  onClick={() =>
                    this.setState({
                      addSystemlabelModel: false,
                      systemLablesExcelTable: false,
                    })
                  }
                />
                <Button
                  tooltipButton={labels.ADMIN_SYSTEM_LABELS_SAVE}
                  className="SaveCloudBtn"
                  onClick={this.handleSaveExcelData}
                  test-data-id="save-excel-data-btn"
                />
              </div>
            ) : (
              ""
            )}
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-2-collapse"}
            />
          </Column.Head>
          <Column.Body>
            {addSystemlabelModel ? (
              <AddSystemLabel
                onFormFieldBlur={this.handleFormFieldBlur}
                addSystemLabelSelectChange={this.addSystemLabelSelectChange}
                addSystemlabelFieldChange={this.addSystemlabelFieldChange}
                handleSystemlabelSave={this.handleSystemlabelSave}
                handleModuleCheckedChange={this.handleModuleCheckedChange}
                handleuserTypesCheckedChange={this.handleuserTypesCheckedChange}
                handleCategoriesCheckedChange={
                  this.handleCategoriesCheckedChange
                }
                handleFilterCheckedChange={this.handleFilterCheckedChange}
                addSystemLabelData={this.state.addSystemLabelData}
                Categories={this.state.Categories}
                UserTypes={this.state.UserTypes}
                Modules={this.state.Modules}
                SubModules={this.state.SubModules}
                languages={this.state.languages}
                searchBy={this.state.searchBy}
                searchModeValue={this.state.searchModeValue}
                labels={this.props.labels}
              />
            ) : systemLablesExcelTable ? (
              <SystemLabelsExceltTable
                ExcelTableOnChange={this.ExcelTableOnChange}
                csvData={this.state.csvDataUpload}
                labels={labels}
              />
            ) : (
              <SystemLabelsList
                HandlHTMLEditor={this.HandlHTMLEditor}
                onFormFieldBlur={this.handleFormFieldBlur}
                handleSystemLabelChange={this.handleSystemLabelChange}
                onFormFieldChange={this.handleFormFieldChange}
                SystemLabelsList={this.state.SystemLabelsList}
                loadingId={this.state.loadingId}
                labels={this.props.labels}
              />
            )}
          </Column.Body>
        </Column>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(SystemLabels));
