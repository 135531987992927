import React from "react";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import { CustomInput } from "../../../components";
import { List } from "../../../components";

class LookupCorrelationDetails extends React.Component {
  constructor(props) {
    super(props);
  }
  renderTableData = () => {
    const { CorrelationTableData } = this.props;
    if (CorrelationTableData && CorrelationTableData.length > 0) {
      let testitem =
        CorrelationTableData[CorrelationTableData.length - 1].value;
      return CorrelationTableData.map((item, index) => {
        const { label, value, isEnable, Id } = item; //destructuring
        return (
          <tr
            key={Id}
            test-data-id={item.value == testitem ? "corelated-data-item" : ""}
          >
            <td>{label}</td>
            <td
              onDoubleClick={() =>
                this.props.handelCorrelationDoubleClick(item)
              }
            >
              <input
                name={"value"}
                type={"text"}
                value={value}
                onChange={(e) => this.props.onCorrelationValueChange(e, item)}
                onBlur={(e) => this.props.onCorrelationValueBlur(e, item)}
                test-data-id={
                  item.value == testitem ? "correlation-input-update" : ""
                }
                disabled={isEnable}
                autoFocus={isEnable}
                className={`${
                  isEnable
                    ? "correlationScoreDisable "
                    : "correlationScoreEnable"
                } `}
              />
              <button
                test-data-id={
                  item.value == testitem ? "correlation-delete-btn" : ""
                }
                type="button"
                className="delete-btn"
                onClick={() => this.props.handleDeleteCorrelation(item)}
              />
            </td>
          </tr>
        );
      });
    } else {
      return "";
    }
  };
  renderTableHeader = () => {
    let header = [this.props.selectedLookupToLookupTitle, "Correlation"];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  render() {
    const {
      lookupTolookupSelectChange,
      LookupTypeList,
      onFormFieldChange,
      SearchByText,
      SearchByTextValue,
      lookupCorrelationDropDownSelected,
      selectedLookupToLookupTitle,
      selectedLookupToLookup,
      labels,
    } = this.props;
    return (
      <div className="system_label_filter">
        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LOOKUP_TYPE}</h6>
          <select
            name="LookupToLookupSelected"
            test-data-id={"lokup-to-lookup-select"}
            value={lookupCorrelationDropDownSelected.value}
            onChange={(e) => lookupTolookupSelectChange(e)}
          >
            {lookupCorrelationDropDownSelected.map((item) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <table className="correlation-table">
            <tbody>
              <tr>{this.renderTableHeader()}</tr>
              {this.renderTableData()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default LookupCorrelationDetails;
