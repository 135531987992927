import { ACTION_TYPES } from "./actionTypes";

export default function reducer(state,action){
    switch(action.type){
        case ACTION_TYPES.ACCOUNT.SAVE_USER:
            return {
                ...state,
                user:action.payload
            };
        case ACTION_TYPES.ACCOUNT.DELETE_USER:
            return {
                ...state,
                user:{}
            };
        case ACTION_TYPES.NOTIFICATION.SET_NOTIFICATION:
            return {
                ...state,
                notification:action.payload
            }
        case ACTION_TYPES.LOADING.TOGGLE_LOADING:
            return {
                ...state,
                isLoading:action.payload
            }
        case ACTION_TYPES.LABELS.SET_LABELS:
            return {
                ...state,
                labels:action.payload
            }
        default:{
            return state;
        }
    }
}