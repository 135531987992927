import React, { Component } from "react";
import { Column, Button, Select, CustomInput } from "../../../components";
import "../styles.scss";
import {
  getRoles,
  getRoleFeatures,
  getAllFeatures,
  UpdateRoleFeature,
} from "../../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import loadingIcon from "../../Assests/Images/loading.gif";
// import doneIcon from "../../Assests/Images/done.png";
// import failedIcon from "../../Assests/Images/failed.png";

import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../../Redux/actions";
// import { validateInputField } from '../../utilities/validator';
// import { isValidEmail } from '../../utilities/validations';
class AdminFeaturesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: false,
      isCollapsed3: true,
      filteredRoleFeatures: [],
      RoleFeatures: [],
      adminRoleInput: "",
      selectedRole: "",
    };
  }
  componentDidMount = () => {
    this.GetRoles();
  };

  GetRoles = () => {
    getRoles().then((res) => {
      if (res.success) {
        const newList = res.items.map((item) => {
          const newItem = {
            value: item.RoleId,
            label: item.RoleName,
          };
          return newItem;
        });
        this.setState({
          Roles: newList,
        });
        this.onFormSelectChange("Roles", newList[0]);
      }
    });
  };
  getRoleFeatures = (value) => {
    getRoleFeatures(value).then((res) => {
      this.setState({
        RoleFeatures: res.items,
        filteredRoleFeatures: res.items,
      });
    });
  };

  handleCheckBoxClick = (selectedItem) => {
    selectedItem = {
      ...selectedItem,
      IsActive: !selectedItem.IsActive,
    };
    this.setState({
      RoleFeatures: this.state.RoleFeatures.map((k) => ({
        ...k,
        IsActive: selectedItem.Id == k.Id ? !k.IsActive : k.IsActive,
      })),
      filteredRoleFeatures: this.state.features.map((k) => ({
        ...k,
        IsActive: selectedItem.Id == k.Id ? !k.IsActive : k.IsActive,
      })),
    });
    UpdateRoleFeature(selectedItem)
      .then((res) => {})
      .catch((err) => console.log("Err ", err));
  };

  onFormSelectChange = (type, selectedOption) => {
    if (type == "Roles") {
      this.setState({
        selectedRole: selectedOption,
      });
      this.getRoleFeatures(selectedOption.value);
    }
  };

  onFormFieldChange = (e) => {
    const { name, value, type } = e.target;
    var data = this.state.RoleFeatures;
    const rolesFilter = data.filter((item) =>
      item.Name.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      [name]: value,
      filteredRoleFeatures: rolesFilter,
    });
  };

  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      filteredRoleFeatures,
      Roles,
      searchFeatureValue,
      selectedRole,
    } = this.state;
    return (
      <div className="admin-management-role-wraper columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={"Admin Features Management"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Admin Features Management"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-feature-management">
              <div
                className={`${
                  !selectedRole.value ? "inValid " : " "
                } input-select-field `}
              >
                <h6>{"Roles"}</h6>
                <Select
                  name="roles"
                  className={`select-input`}
                  placeholder={"Please select a role"}
                  value={(selectedRole && selectedRole) || ""}
                  onChange={(selectedOption) =>
                    this.onFormSelectChange("Roles", selectedOption)
                  }
                  clearable={false}
                  searchable={false}
                  options={Roles}
                />
              </div>
              <div className="adminRoleInput-field">
                <div className={`input-field `}>
                  <h6>{"search feature"}</h6>
                  <div>
                    <input
                      name={"searchFeatureValue"}
                      type={"text"}
                      value={searchFeatureValue || ""}
                      onChange={(e) => this.onFormFieldChange(e)}
                      test-data-id={"admin-management-role-input"}
                      placeholder={"Search Feature"}
                    />
                  </div>
                </div>
              </div>
              {filteredRoleFeatures && filteredRoleFeatures.length > 0 ? (
                <div className="list-wrapper">
                  {filteredRoleFeatures.map((item) => (
                    <div
                      test-data-id={`roles-list-item`}
                      className={`list-item`}
                      key={item.Id}
                    >
                      <span className="list-checkbox">
                        <input
                          type="checkbox"
                          checked={item.IsActive}
                          onChange={() => this.handleCheckBoxClick(item)}
                        ></input>
                      </span>
                      <div className="list_item_title">
                        <span>{item.Name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </div>
          </Column.Body>
        </Column>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(AdminFeaturesManagement));
