import React,{Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./page-wrapper.scss";
import Header from "../Header/Header.jsx";

import { Notification } from '..';
import HeaderMobile from '../Header/HeaderMobile.jsx';
import Navigation from './Navigation';


class PageWrapper extends Component{
    state = { isNavShrink: false };
    constructor(props){
        super(props);
        this.state={
            isCollapsed:true
        }
    }
    handleNavigationToggle = () => {
        this.setState({ isNavShrink: !this.state.isNavShrink });
      };
    
      handleSubMenuOpen = () => {
        const { isNavShrink } = this.state;
        if (isNavShrink && window.innerWidth > 1240) {
          this.setState({ isNavShrink: false });
        }
      };
      removeNavShrink = () => {
        if (this.state.isNavShrink && window.innerWidth <= 1240)
          this.setState({ isNavShrink: false });
      };
    
      handleMobileNavShrink = () => {
        if (!this.state.isNavShrink && window.innerWidth <= 1240)
          this.setState({ isNavShrink: true });
      };
    render(){
        const{
            isLoading,
            notification,
            location
        } = this.props;
    const { isNavShrink } = this.state;
        const {
            message,
            status
        } = notification;
        return(
            <div className="page-wrapper">
                {isLoading && <div id="loader-wrapper"><div test-data-id ={"loader"} id="loader"/></div> }
                {message && <Notification message={message} status={status}/>}
                <div className="top-bar">
                    <img className="topBarImg" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABb8AAAABCAMAAADJoyNzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzM5MjdEOUY4RkU3MTFFNjgyRTRDODlGMTQ1MUUwNzEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzM5MjdEQTA4RkU3MTFFNjgyRTRDODlGMTQ1MUUwNzEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMzkyN0Q5RDhGRTcxMUU2ODJFNEM4OUYxNDUxRTA3MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMzkyN0Q5RThGRTcxMUU2ODJFNEM4OUYxNDUxRTA3MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtPSGnwAAAAtUExUReeZjN69g6Hr3vd7dLyl62HSrNe65K+WtwTKp7+Q1Dui9vVJSY+C9faqOvF4SVxpDMkAAAAqSURBVHjaYmDjHG6AhWe4AQa+4QaYuYcbYOcaboCRd7gBVo7hBpgAAgwAfDI++tejFEsAAAAASUVORK5CYII=" alt="img"/>
                </div>
                <HeaderMobile />
                <Header />
                <div className={`page-body ${isLoading ? "disabled":""}`}>
                    {/* <div className={`page-sidebar ${this.state.isCollapsed ? "collapsed":""}`}>
                        {this.state.isCollapsed && <img test-data-id={'sidebar-expand'} src={hamburgerIcon} onClick={()=>this.setState({isCollapsed:false})}/>}
                        {!this.state.isCollapsed && <img test-data-id={'sidebar-collapse'}  onClick={()=>this.setState({isCollapsed:true})} src={arrowIcon}/>} 
                        <div className="menu-items">
                            <div className="menu-item"><img src={dashboardIcon}/><span>Dashboard</span></div>
                            <div className="menu-item"><img src={marketingIcon}/><span>Marketing</span></div>
                            <div onClick={()=>this.props.history.push(privateRoutes.sales.path)} className="menu-item"><img src={salesIcon}/><span>Sales</span></div>
                            <div className="menu-item"><img src={contentIcon}/><span>Content</span></div>
                            <div className="menu-item"><img src={trainingIcon}/><span>Training</span></div>
                            <div className="menu-item"><img src={validationIcon}/><span>Validation</span></div>
                            <div className="menu-item"><img src={mentorshipIcon}/><span>Mentorship</span></div>
                            <div className="menu-item"><img src={statisticIcon}/><span>Statistics</span></div>
                            <div className="menu-item"><img src={feedbackIcon}/><span>Feedback</span></div>
                            <div className="menu-item"><img src={aboutIcon}/><span>About</span></div>
                            <div className="menu-item"><img src={settingIcon}/><span>Settings</span></div>
                        </div>
                    </div> */}
                    <Navigation
                    isNavShrink={isNavShrink}
                    onSubMenuOpen={this.handleSubMenuOpen}
                    onMobileNavShrink={this.handleMobileNavShrink}
                    location={location}
                    onNavShrink={this.removeNavShrink} 
                    />
                    <div className="page-view">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        notification:state.notification,
        isLoading:state.isLoading
    };
}

export default connect(mapStateToProps)(withRouter(PageWrapper)); 