import React, { Component } from "react";
import { Column, Button, List, CustomInput } from "../../../components";
import "../styles.scss";
import {
  getRoles,
  GetAllAdmins,
  RegisterAdmin,
  UpdateAdmin,
  DeleteUser,
} from "../../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdminForm from "./components/AdminForm.jsx";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../../Redux/actions";

class AdminManagementAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: true,
      isCollapsed3: true,
      isUpdate: false,
      Roles: [],
      Admins: [],
      filteredAdmins: [],
      filteredRoles: [],
      adminSearchText: "",
      adminData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        selectedRole: "",
      },
    };
  }
  componentDidMount = () => {
    this.GetRoles();
    this.GetAllAdmins();
  };

  GetAllAdmins = () => {
    GetAllAdmins().then((res) => {
      this.setState({
        Admins: res.items,
        filteredAdmins: res.items,
      });
    });
  };

  GetRoles = () => {
    getRoles().then((res) => {
      this.setState({
        Roles: res.items.map((item) => {
          return {
            value: item.RoleId,
            label: item.RoleName,
          };
        }),
      });
    });
  };

  GetRoles = () => {
    getRoles().then((res) => {
      this.setState({
        Roles: res.items.map((item) => {
          return {
            value: item.RoleId,
            label: item.RoleName,
          };
        }),
      });
    });
  };
  SaveAdmin = () => {
    const { adminData, isUpdate } = this.state;
    const { firstName, lastName, email, password, selectedRole } = adminData;
    if (!selectedRole.value) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    if (!firstName) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    if (!lastName) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    if (!email) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    this.props.toggleLoadingAction(true);
    if (isUpdate) {
      var model = {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        Name: firstName,
        Password: "",
        RoleId: selectedRole.value,
        RoleName: selectedRole.label,
        UserId: adminData.UserId,
        id: "ProdooAdmin.model.Admin-6",
        UserEmail: email,
        UserFirstName: firstName,
      };
      UpdateAdmin(model).then((res) => {
        if (res.success) {
          this.GetAllAdmins();
          const info = {
            message: this.props.labels.ADMIN_UPDATE_SUCCESS_MSG,
            status: "success",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        } else {
          const info = {
            message: res.message,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      });
    } else {
      if (!password) {
        const info = {
          message: this.props.labels
            .ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
          status: "error",
        };
        this.props.setNotificationAction(info);
        return null;
      }
      var model = {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        Name: firstName,
        Password: password,
        RoleName: selectedRole.label,
        UserId: 0,
        id: "ProdooAdmin.model.Admin-11",
      };
      RegisterAdmin(model).then((res) => {
        if (res.success) {
          this.GetAllAdmins();
          const info = {
            message: this.props.labels.ADMIN_REGESTER_SUCCESS_MSG,
            status: "success",
          };
          this.setState({
            adminData: {
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              selectedRole: "",
            },
          });
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        } else {
          const info = {
            message: res.message,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      });
    }
  };
  DeleteUser = (selectedItem) => {
    this.props.toggleLoadingAction(true);
    DeleteUser(selectedItem).then((res) => {
      if (res.success) {
        this.GetAllAdmins();
        const info = {
          message: this.props.labels.ADMIN_DELETE_SUCCESS_MSG,
          status: "success",
        };
        this.props.toggleLoadingAction(false);
        this.props.setNotificationAction(info);
      } else {
        const info = {
          message: res.message,
          status: "error",
        };
        this.props.toggleLoadingAction(false);
        this.props.setNotificationAction(info);
      }
    });
  };

  onFormFieldChange = (e) => {
    const { name, value, type } = e.target;
    var data = this.state.Admins;
    const adminsFilter = data.filter((item) =>
      item.UserFirstName.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      [name]: value,
      filteredAdmins: adminsFilter,
    });
  };

  adminFieldChange = (e) => {
    const { adminData, isUpdate } = this.state;
    const { name, value, type } = e.target;
    this.setState({
      adminData: {
        ...adminData,
        [name]: value,
      },
    });
    if(value=="lastName" && isUpdate){
      this.SaveAdmin();
    }
  };
  adminFormBlur = () => {
    const { isUpdate } = this.state;
    if(isUpdate){
      this.SaveAdmin();
    }
  };


  AdminItemClick = (item) => {
    const { Roles, adminData } = this.state;
    const selectedRole = { value: item.RoleId, label: item.RoleName };
    this.setState({
      isCollapsed2: false,
      isUpdate: true,
      adminData: {
        ...item,
        firstName: item.UserFirstName,
        lastName: item.UserLastName,
        email: item.UserEmail,
        selectedRole: selectedRole,
      },
    });
  };
  handleAddNew = () => {
    this.setState({
      isCollapsed2: false,
      isUpdate: false,
      adminData: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        selectedRole: "",
      },
    })
}

  onFormSelectChange = (type, selectedOption) => {
    const { adminData } = this.state;
    if (type == "Roles") {
      this.setState({
        adminData: {
          ...adminData,
          selectedRole: selectedOption,
        },
      });
    }
  };
  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      filteredAdmins,
      Roles,
      adminSearchText,
    } = this.state;
    return (
      <div className="admin-management-role-wraper columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_MANAGEMENT_LABEL}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_MANAGEMENT_LABEL}</p>
            </div>
            <Button
                testId={"AddAdminForm"}
                className="button-primary AddBtn"
                tooltipButton={labels.ADMIN_OPEN_SYSTEM_LABELS_FORM}
                tooltipHelp={labels.ADMIN_HELP_OPEN_SYSTEM_LABELS_FORM}
                onClick={() => this.handleAddNew()}
            />
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-management-role-col1">
              <div className="adminRoleInput-field">
                <div
                  className={`${
                    !adminSearchText ? "inValid " : " "
                  } input-field `}
                >
                  <h6>{labels.ADMIN_SEARCH_LABEL}</h6>
                  <div>
                    <input
                      name={"adminSearchText"}
                      type={"text"}
                      value={adminSearchText || ""}
                      onChange={(e) => this.onFormFieldChange(e)}
                      test-data-id={"admin-management-role-input"}
                      placeholder={labels.ADMIN_SEARCH_LABEL}
                    />
                  </div>
                </div>
              </div>
              {filteredAdmins && filteredAdmins.length > 0 ? (
                <div className="list-wrapper">
                  {filteredAdmins.map((item) => (
                    <div
                      onClick={() => this.AdminItemClick(item)}
                      test-data-id={`roles-list-item`}
                      className={`list-item`}
                      key={item.UserId}
                    >
                      <div
                        className="list_item_title"
                      >
                        <span>{item.UserFirstName}</span>
                      </div>
                      <button
                        test-data-id={`delete-role-btn`}
                        type="button"
                        className="delete-btn"
                        onClick={() => this.DeleteUser(item)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </div>
          </Column.Body>
        </Column>

        <Column collapse={isCollapsed2} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_SAVE_LABEL}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_SAVE_LABEL}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <AdminForm
              Roles={Roles}
              labels={this.props.labels}
              adminData={this.state.adminData}
              isUpdate={this.state.isUpdate}
              onFormSelectChange={this.onFormSelectChange}
              adminFieldChange={this.adminFieldChange}
              adminFormBlur={this.adminFormBlur}
              adminRegister={this.SaveAdmin}
            />
          </Column.Body>
        </Column>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(AdminManagementAdmin));
