import store from "./store";
import {StorageService} from '../Services/storage';
import {auth} from "../Services/auth";
import { saveUserAction,setLabelsAction } from "./actions";
import { getAdminAllowedFeaturesApi, getAlSystemLabels } from "../Services/apiCalls";
import fromPairs from "lodash/fromPairs";
import map from "lodash/map";

export const initialConfig = () =>{
    const user = StorageService.getUser();
    const labels = StorageService.getLabels();
    if(labels.length != 0){
        store.dispatch(setLabelsAction(labels));
    }
    const authObj=new auth();
    authObj.setBaseUrl();
    getAlSystemLabels()
    .then(( data ) => {
        const labels = fromPairs(
          map(data.items, item => [item.Identifier, item.Message])
        );
        store.dispatch(setLabelsAction(labels));
    })
    .catch(err => console.log("Err ",err))
    if(user){
        authObj.setDefaultAuthHeaders();
        store.dispatch(saveUserAction(user))
    }
    return true;
}