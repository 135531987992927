import React,{Component} from 'react';
import {Column,Button,List, CustomInput} from "../../components";
import moment from "moment";
import "./products.scss";
import {  addProduct, getAllProducts, updateProduct, RegisterDemoUser, DeleteProduct } from '../../Services/apiCalls';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import ProductForm from './components/ProductForm.jsx';
import {setNotificationAction,toggleLoadingAction } from '../../Redux/actions';

class Products extends Component{
    constructor(props){
        super(props);
        this.state={
            isCollapsed1:false,
            isCollapsed2:true,
            isCollapsed3:true,
            isUpdate:false,
            productsData:{
                Description: "",
                Id: -1,
                Logo: "",
                Name: "",
                Price:"",
                selectedType: ""
            },
            productTypes :[],
            filteredProducts: [],
            AllProducts: [],
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { labels } = this.props;
        if (prevProps.labels !== labels) {
          this.setState({
            productTypes :[
                {Id : 1, label: labels.ADMIN_SEARCHER_LABEL},
                {Id : 2, label: labels.ADMIN_IPRO_LABEL},
            ],
          });
        }
      }
    componentDidMount=()=>{
        const { labels } = this.props;
        this.setState({
            productTypes :[
                {Id : 1, label: labels.ADMIN_SEARCHER_LABEL},
                {Id : 2, label: labels.ADMIN_IPRO_LABEL},
            ],
          });
        this.GetAllProducts();
    } 


    GetAllProducts = ()=>{
        getAllProducts()
        .then(res=>{
            this.setState({
                AllProducts: res.items,
                filteredProducts:res.items
            })
        })
    }

    getImgSrc = src => {
        const { productsData } = this.state;
        this.setState({
            productsData: {
            ...productsData,
            Logo: src
          }
        });
      };
    SaveProduct = ()=>{
        const {productsData, isUpdate} = this.state;
        const {  Description, Id, Logo, Name, Price, selectedType} = productsData;
        if(!Name){
            const info = {
                message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
                status: "error"
                };
                this.props.setNotificationAction(info);
                return null;
        }
        if(!selectedType.Id){
            const info = {
                message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
                status: "error"
                };
                this.props.setNotificationAction(info);
                return null;
        }
        this.props.toggleLoadingAction(true);
        if(isUpdate){
            var model = {
                Description: Description,
                Id:  Id,
                Name: Name, 
                Price: 0,
                Logo: Logo,
                Type: selectedType.Id,
            };
            updateProduct(model)
            .then(res=>{
               if(res.success){
                this.GetAllProducts();
                   const info = {
                    message: this.props.labels.ADMIN_PRODUCT_UPDATE_SUCCESS,
                    status: "success"
                    };
                    this.setState({
                        isUpdate: false
                    })
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }
            })
        }else{
            var model = {
                Description: Description,
                Id:  0,
                Name: Name, 
                Price: 0,
                Type: selectedType.Id,
                Logo : Logo
            };
            addProduct(model)
            .then(res=>{
               if(res.success){
                   this.GetAllProducts();
                   const info = {
                    message: this.props.labels.ADMIN_PRODUCT_ADD_SUCCESS,
                    status: "success"
                    };
                    this.setState({
                        isUpdate: true,
                        productsData: {
                            ...this.state.productsData,
                            Id: res.items.Id
                        }
                    })
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }
            })
        }
        
    }
    DeleteUser = (selectedItem)=>{
        this.props.toggleLoadingAction(true);
        DeleteProduct(selectedItem)
        .then(res=>{
            if(res.success){
                this.GetAllProducts();
                const info = {
                    message: this.props.labels.ADMIN_PRODUCT_DELETE_SUCCESS,
                    status: "success"
                    };
                this.props.toggleLoadingAction(false);
                this.props.setNotificationAction(info);
            }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                this.props.toggleLoadingAction(false);
                this.props.setNotificationAction(info);
            }
        })
    }

    searchFieldChange = e =>{
        const{
          name,
          value,
          type
        } = e.target;
        var data = this.state.AllProducts;
        const filteredProducts = data.filter(item=> item.UserName.toLowerCase().includes(value.toLowerCase()));
       
        this.setState({
          [name]:value,
          filteredProducts: filteredProducts
        });
      };
      handleAddNew = () => {
        this.setState({ 
            isCollapsed2: false,
            isUpdate: false,
            productsData: {
                Description: "",
                Id:  0,
                Name: "", 
                Price: 0,
                selectedType: "",
            },
      });
    }

      onFormFieldChange = e =>{
          const {productsData}= this.state;
        const{
          name,
          value,
          type
        } = e.target;      
        this.setState({
            productsData:{
                ...productsData,
                [name]:value,
            },
          
        });
      };
      handleDateChange=(date)=> {
        const {demoUserData}= this.state;
        
        this.setState({
          demoUserData:{
            ...demoUserData,
            ExpiryDate:date,
        },
        })
      }

      ListItemClick=(item)=>{
        const {productsData, productTypes} = this.state;
        const selectedType = productTypes.filter(i=> i.Id==item.Type);
        this.setState({
            isCollapsed2:false,
            isUpdate: true,
            productsData: {
                ...productsData,
                Description: item.Description,
                Id: item.Id,
                Logo: "",
                Name: item.Name,
                Price: item.Price,
                selectedType: selectedType[0]
            },
            
        })
        
      }

      onFormSelectChange = (type,selectedOption) =>{
        const { productsData} = this.state;
        if(type == "types"){
            this.setState({
                productsData:{
                    ...productsData,
                    selectedType: selectedOption,
                }
            })
        }
        
    }
    render(){
        const {labels}= this.props;
        const{
            isHelpActive,
            isCollapsed1,
            isCollapsed2,
            filteredProducts,
            Roles,
            ProductSearchText,
        } = this.state;
        return(
            <div className="products-wraper columns-wrapper">
             <Column collapse={isCollapsed1} className="col-1">
                <Column.Collapsed
                    text={labels.ADMIN_PRODUCTS_MANAGEMENT}
                    onClick={() =>
                    this.setState({ isCollapsed1: false })
                    }
                    tooltipButton={labels.ADMIN_EXPAND}
                    isHelpActive={isHelpActive}
                    tooltipHelp={labels.ADMIN_EXPAND_BTN}
                    tooltipPlace="left"
                    testId={'column-1-expand'}
                />
                <Column.Head>
                    <div className="heading">
                        <p>
                            {labels.ADMIN_PRODUCTS_MANAGEMENT}
                        </p>
                    </div>
                    <Button
                        testId={"open-system-label-form-btn"}
                        className="button-primary AddBtn"
                        tooltipButton={labels.ADMIN_OPEN_SYSTEM_LABELS_FORM}
                        tooltipHelp={labels.ADMIN_HELP_OPEN_SYSTEM_LABELS_FORM}
                        onClick={() => this.handleAddNew()}
                    />
                    <Button
                    onClick={() =>
                        this.setState({ isCollapsed1: true })
                    }
                    className="collapseBtn"
                    tooltipButton={labels.ADMIN_COLLAPSE}
                    tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                    testId={'column-1-collapse'}
                    />
                </Column.Head>
                <Column.Body>
                    <div className="admin-management-role-col1">
                        <div className={`${!ProductSearchText  ? "inValid " : " "} input-field`}>
                            <h6>{labels.ADMIN_PRODUCTS_SEARCH_LABEL}</h6>
                            <div>
                                <input
                                    name={"ProductSearchText"}
                                    type={"text"}
                                    value={ProductSearchText||""}
                                    onChange={e => this.searchFieldChange(e)}
                                    test-data-id={"admin-management-role-input"}
                                    placeholder={labels.ADMIN_PRODUCTS_SEARCH_LABEL}
                                />
                            </div>
                            
                        </div>
                        {filteredProducts && filteredProducts.length > 0 ?
                            <div className="list-wrapper">                
                                    {filteredProducts.map(item=>
                                        <div test-data-id={`roles-list-item`} 
                                        className={`list-item`} 
                                        key={item.Id}
                                        onClick = {() =>this.ListItemClick(item)}
                                        >
                                            <div
                                                className="list_item_title"> 
                                                <span>{item.Name}</span>
                                            </div>
                                            <button  test-data-id={`delete-role-btn`} type="button" className="delete-btn" onClick={()=>this.DeleteUser(item)} />
                                        </div>
                                    )}                        
                            </div>
                            :
                            <div className ="no-item-label"><label >
                                {labels.ADMIN_NO_ITEM_TO_DISPLAY}
                            </label>
                        </div>} 
                    </div>
                </Column.Body>
             </Column>

             <Column collapse={isCollapsed2} className="col-1">
                <Column.Collapsed
                    text={labels.ADMIN_PRODUCT_SAVE_LABEL}
                    onClick={() =>
                    this.setState({ isCollapsed2: false })
                    }
                    tooltipButton={labels.ADMIN_EXPAND}
                    isHelpActive={isHelpActive}
                    tooltipHelp={labels.ADMIN_EXPAND_BTN}
                    tooltipPlace="left"
                    testId={'column-2-expand'}
                />
                <Column.Head>
                    <div className="heading">
                        <p>
                            {labels.ADMIN_PRODUCT_SAVE_LABEL}
                        </p>
                    </div>
                    <Button
                    onClick={() =>
                        this.setState({ isCollapsed2: true })
                    }
                    className="collapseBtn"
                    tooltipButton={labels.ADMIN_COLLAPSE}
                    tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                    testId={'column-1-collapse'}
                    />
                </Column.Head>
                <Column.Body>
                <ProductForm 
                    Roles = {Roles}
                    labels={this.props.labels}
                    productsData={this.state.productsData}
                    isUpdate={this.state.isUpdate}
                    onFormFieldChange={this.onFormFieldChange}
                    onFormSelectChange={this.onFormSelectChange}
                    getImgSrc={this.getImgSrc}
                    saveProduct = {this.SaveProduct}
                    productTypes = {this.state.productTypes}
                    /> 
                </Column.Body>
             </Column>
             
        </div>
        )
    }
}
function mapStateToProps(state){
    return{
      user:state.user,
      isLoading:state.isLoading,
      labels: state.labels
    };
  }
  
  const actions ={
    setNotificationAction,
    toggleLoadingAction
  }
  
export default connect(mapStateToProps,actions)(withRouter(Products));