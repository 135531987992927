import React from "react";
import {
  getAllLanguageApi,
  getAllLookupsApi,
  getSelectedLookupsApi,
  AddLookupItemApi,
  DeleteLookupItemApi,
  getAllLookupTypeStatusApi,
  getAllCandidateLookupTypeApi,
} from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import { CustomInput, Select, Button, List, Column } from "../../../components";

class LookupWorkPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LanguageList: [],
      LookupsList: [],
      LookupsStatusList: [],
      searchKey: "",
      SelectedLookupList: [],
      filteredLookupList: [],
      currentLookup: [
        {
          LookupTypesId: "",
          LookupName: "",
        },
      ],
      currentLanguage: [
        {
          LanguageId: "",
          LanguageValue: "",
        },
      ],
      currentSelectedLookup: [
        {
          LanguageId: "",
          LanguageValue: "",
        },
      ],
      currentSelectedLookupStatus: [
        {
          Value: "",
          Name: "",
        },
      ],
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.props.toggleLoadingAction(true);
      getAllLanguageApi()
        .then((res) => {
          let newLanguageList = res.items.map((item) => {
            return {
              ...item,
              value: item.LanguageId,
              label: item.LanguageValue,
            };
          });
          this.setState({
            LanguageList: newLanguageList,
            currentLanguage: newLanguageList[0],
          });
          // this.onFormLanguageSelectChange(newLanguageList[0])

          //for Lookup call
          getAllLookupsApi()
            .then((res) => {
              let newLookupsList = res.items.map((item) => {
                return {
                  ...item,
                  value: item.LookupTypesId,
                  label: item.LookupName,
                };
              });
              this.setState({
                LookupsList: newLookupsList,
                currentLookup: [
                  {
                    LookupTypesId: newLookupsList[0].LookupTypesId,
                    LookupName: newLookupsList[0].LookupName,
                  },
                ],
              });
              //this.onFormLookupSelectChange(newLookupsList[0])

              // for lookupStatus Api as Approved or Candidtae
              getAllLookupTypeStatusApi()
                .then((res) => {
                  let newList = res.items.map((item) => {
                    return {
                      ...item,
                      value: item.Value,
                      label: item.Name,
                    };
                  });
                  this.setState({
                    LookupsStatusList: newList,
                    currentSelectedLookupStatus: newList[0],
                  });
                  let nameofLookup = this.state.currentLookup[0].LookupName.slice(
                    0,
                    -1
                  );
                  this.ApprovedlookUpApiCall(nameofLookup);
                  this.props.toggleLoadingAction(false);
                  //this.onFormLookupStatusChange(newList[0])
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }, 10);
  }

  onFormLanguageSelectChange = (selectedOption) => {
    const { name, value, type } = selectedOption.target;
    const filteredSubModules = this.state.LanguageList.filter(
      (item) => item.LanguageId == selectedOption.target.value
    );
    this.setState({
      selectedLanguage: value,
      filteredSubModules,
    });
  };

  onFormLookupSelectChange = (selectedOption) => {
    if (this.state.LanguageList.length == 0) {
      const info = {
        message: this.props.labels.ADMIN_SELECT_LANGUAGE_MSG,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return false;
    }
    const { name, value, type } = selectedOption.target;
    const filteredSubModules = this.state.LookupsList.filter(
      (item) => item.LookupTypesId == selectedOption.target.value
    );
    let Selectedlookup = [
      {
        LookupTypesId: this.state.LookupsList[
          selectedOption.target.options.selectedIndex
        ].LookupTypesId,
        LookupName: this.state.LookupsList[
          selectedOption.target.options.selectedIndex
        ].LookupName,
      },
    ];
    this.setState({
      searchKey: "",
      filteredLookupList: [],
      selectedLookupType: value,
      filteredSubModules,
      currentLookup: Selectedlookup,
      SelectedLookupList: [],
      selectedLookupStatusType: this.state.LookupsStatusList,
    });
    this.props.toggleLoadingAction(true);
    this.ApprovedlookUpApiCall(Selectedlookup[0].LookupName.slice(0, -1));
    this.props.toggleLoadingAction(false);
  };

  onFormLookupStatusChange = (selectedOption) => {
    if (this.state.LookupsList.length == 0) {
      const info = {
        message: this.props.labels.ADMIN_SELECT_LOOKUP_MSG,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return false;
    }
    const { name, value, type } = selectedOption.target;
    const filteredSubModules = this.state.LookupsStatusList.filter(
      (item) => item.Value == selectedOption.target.value
    );
    this.setState({
      searchKey: "",
      filteredLookupList: [],
      SelectedLookupList: [],
      selectedLookupStatusType: value,
      filteredSubModules,
    });
    const lookupStatusName = filteredSubModules[0].Name;
    const lookupName = this.state.currentLookup[0].LookupName.slice(0, -1);
    if (lookupStatusName == "Approved") {
      this.props.toggleLoadingAction(true);
      this.ApprovedlookUpApiCall(lookupName);
      this.props.toggleLoadingAction(false);
    } else {
      this.props.toggleLoadingAction(true);
      getAllCandidateLookupTypeApi(lookupName)
        .then((res) => {
          this.props.toggleLoadingAction(false);
          if (res.items == undefined || res.items.length == 0) {
            const info = {
              message: this.props.labels.ADMIN_NO_RECORD_FOUND_MSG,
              status: "error",
            };
            this.props.setNotificationAction(info);
            return false;
          }
          const newList = this.lookUpData(res, lookupName);
          this.setState({
            SelectedLookupList: newList,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  ApprovedlookUpApiCall(lookupName) {
    getSelectedLookupsApi(lookupName)
      .then((res) => {
        if (res.items == undefined) {
          const info = {
            message: this.props.labels.ADMIN_NO_RECORD_FOUND_MSG,
            status: "error",
          };
          this.props.setNotificationAction(info);
          return false;
        }
        const newList = this.lookUpData(res, lookupName);
        this.setState({
          SelectedLookupList: newList,
        });
      })
      .catch((err) => console.log(err));
  }

  lookUpData(data, lookupName) {
    const newList = data.items.map((item) => {
      if (lookupName == "Profile") {
        const newItem = {
          Id: item.ProfileId,
          Title: item.ProfileValue,
        };
        return newItem;
      }

      if (lookupName == "Skill") {
        const newItem = {
          Id: item.SkillId,
          Title: item.SkillValue,
        };
        return newItem;
      }

      if (lookupName == "Keyword") {
        const newItem = {
          Id: item.KeywordId,
          Title: item.KeywordValue,
        };
        return newItem;
      }
      if (lookupName == "Countrie") {
        const newItem = {
          Id: item.CountryId,
          Title: item.CountryName,
        };
        return newItem;
      }
      if (lookupName == "Certification") {
        const newItem = {
          Id: item.CertificationId,
          Title: item.CertificationValue,
        };
        return newItem;
      }

      if (lookupName == "Language") {
        const newItem = {
          Id: item.LanguageId,
          Title: item.LanguageValue,
        };
        return newItem;
      }

      if (lookupName == "Industerie") {
        const newItem = {
          Id: item.IndustryId,
          Title: item.IndustryValue,
        };
        return newItem;
      }
    });
    return newList;
  }

  handleSearchChange = (e) => {
    let filteredlookups = [];
    const { value } = e.target;
    const { SelectedLookupList } = this.state;
    if (SelectedLookupList.length > 0) {
      filteredlookups = this.state.SelectedLookupList.filter((wp) =>
        wp.Title.toLowerCase().includes(value.toLowerCase())
      );
    }
    this.setState({
      searchKey: e.target.value,
      filteredLookupList: filteredlookups,
    });
  };

  handleSearchBlurChange = (e) => {
    const value = this.state.searchKey;
    const { SelectedLookupList } = this.state;
    if (SelectedLookupList.length > 0) {
      const filteredlookups = this.state.SelectedLookupList.filter((wp) =>
        wp.Title.toLowerCase().includes(value.toLowerCase())
      );
    }
    //for api call
    const lookupType = this.state.currentLookup[0].LookupName.slice(0, -1);
    if (!value) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    const ItemName = value;
    this.props.toggleLoadingAction(true);
    AddLookupItemApi(lookupType, ItemName)
      .then((res) => {
        this.props.toggleLoadingAction(false);
        const newList = this.lookUpData(res, lookupType);
        this.setState({
          searchKey: ItemName,
          filteredLookupList: newList,
        });
      })
      .catch((err) => console.log(err));
  };

  deleteRow = (e) => {
    const lookupType = this.state.currentLookup[0].LookupName.slice(0, -1);
    const lookupitemId = e.currentTarget.id;
    const ItemName = this.state.searchKey;
    this.props.toggleLoadingAction(true);
    DeleteLookupItemApi(lookupType, lookupitemId, ItemName)
      .then((res) => {
        if (res.success == false) {
          this.props.toggleLoadingAction(false);
          const info = {
            message: `${res.items}`,
            status: "error",
          };
          this.props.setNotificationAction(info);
          return false;
        }
        this.props.toggleLoadingAction(false);
        const newList = this.lookUpData(res, lookupType);
        this.setState({
          searchKey: ItemName,
          filteredLookupList: newList,
        });
      })
      .catch((err) => console.log(err));
  };
  render() {
    const {
      onFormFieldChange,
      onFormFieldBlur,
      currentLookup,
      lastFocusField,
      searchKey,
      labels,
    } = this.props;
    const { LookupTypesId, LookupName } = this.state.currentLookup;
    const { currentLanguage, currentSelectedLookup } = this.state;

    return (
      <div className="lookupForm">
        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LANGUAGES}</h6>
          <select
            name="LanguageId"
            test-data-id={"LanguageId"}
            value={
              (this.state.selectedLanguage && this.state.selectedLanguage) || ""
            }
            onChange={(selectedOption) =>
              this.onFormLanguageSelectChange(selectedOption)
            }
          >
            {this.state.LanguageList.map((item) => (
              <option value={item.LanguageId} key={item.LanguageId}>
                {item.LanguageValue}
              </option>
            ))}
          </select>
        </div>
        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LOOKUPs_LABEL}</h6>
          <select
            name="LookuptypeId"
            test-data-id={"LookuptypeId"}
            value={
              (this.state.selectedLookupType &&
                this.state.selectedLookupType) ||
              ""
            }
            onChange={(selectedOption) =>
              this.onFormLookupSelectChange(selectedOption)
            }
          >
            {this.state.LookupsList.map((item) => (
              <option value={item.LookupTypesId} key={item.LookupTypesId}>
                {item.LookupName}
              </option>
            ))}
          </select>
        </div>

        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LOOKUP_STATUS_TYPES}</h6>
          <select
            test-data-id={"LookuptypeStatusId"}
            value={
              (this.state.selectedLookupStatusType &&
                this.state.selectedLookupStatusType) ||
              ""
            }
            onChange={(selectedOption) =>
              this.onFormLookupStatusChange(selectedOption)
            }
          >
            {this.state.LookupsStatusList.map((item) => (
              <option value={item.Value} key={item.Value}>
                {item.Name}
              </option>
            ))}
          </select>
        </div>

        <Column className="CustomSearch">
          <Column.Head>
            <div
              className={`${
                !this.state.searchKey ? "inValid " : " "
              } input-field searchfield setHeight50p`}
            >
              <h6>{labels.ADMIN_SEARCH_LABEL}</h6>
              <div>
                <input
                  name={"SearchLookUps"}
                  type={"text"}
                  value={this.state.searchKey}
                  onChange={this.handleSearchChange}
                />
              </div>
            </div>
            <Button className="AddBtn" onClick={this.handleSearchBlurChange} />
          </Column.Head>
        </Column>

        {this.state.filteredLookupList &&
        this.state.filteredLookupList.length > 0 ? (
          <div className="list-wrapper">
            {this.state.filteredLookupList &&
              this.state.filteredLookupList.map((item) => (
                <div
                  test-data-id={`workplace-list-item-${item.Title.replace(
                    " ",
                    "-"
                  ).toLowerCase()}`}
                  className={`list-item ${item.isActive ? "active" : ""}`}
                  key={item.Id}
                >
                  <div className="list_item_title">
                    <span>{item.Title}</span>
                  </div>
                  <button
                    test-data-id={`delete-${item.Title}`}
                    type="button"
                    className="delete-btn"
                    onClick={this.deleteRow}
                    id={item.Id}
                    name={item.Title}
                  />
                </div>
              ))}
          </div>
        ) : (
          <div className="no-item-label">
            <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
          </div>
        )}
      </div>
    );
  }
}

export default LookupWorkPlace;
