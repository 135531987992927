import { ApiUrls } from "../../Services/apiUrls";
import axios from "axios";

export const GetEmailApi = () => {
  return axios
    .get(ApiUrls.Email.GetEmails)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const SendEmailApi = (email) => {
  return axios
    .post(ApiUrls.Email.SendEmail, email)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const DeleteEmail = (Id) => {
  return axios
    .get(ApiUrls.Email.DeleteEmail(Id))
    .then(({ data }) => data)
    .catch((response) => response);
};
