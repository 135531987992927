import React from "react";
import { Select } from "../../../components";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import { CustomInput, Button } from "../../../components";
import { CSVLink, CSVDownload } from "react-csv";
import importIcon from "../../../Assests/Images/import.png";
import exportIcon from "../../../Assests/Images/export.png";
import downloadIcon from "../../../Assests/Images/download.png";

class SystemLabelsFilter extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      onFormFieldChange,
      onFormFieldBlur,
      labelsFilter,
      Categories,
      UserTypes,
      Modules,
      SubModules,
      handleModuleCheckedChange,
      handleFilterCheckedChange,
      handleSearchModeChange,
      languages,
      searchBy,
      searchModeValue,
      onFormSelectChange,
      handlsearch,
      labels,
      selectedLanguage,
      handleDownloadExcel,
      handleUpoladExcel,
      csvData,
      handelGetSystemLabelsByLanguageId,
      systemLablesExcelTable,
      HandelFilerender,
      OnSearchButton,
    } = this.props;
    const { SearchByIdentifier, SearchByText, languagesList } = labelsFilter;

    return (
      <div className="system_label_filter">
        <div className="input-select-field setHeight50p">
          <h6>{labels.ADMIN_LANGUAGES}</h6>
          <Select
            name="selectedLanguage"
            className={`select-input`}
            placeholder={labels.ADMIN_SELECT_MODULE_PLACEHOLDER}
            value={(selectedLanguage && selectedLanguage) || ""}
            onChange={(selectedOption) => onFormSelectChange(selectedOption)}
            clearable={false}
            searchable={false}
            options={languagesList}
          />
        </div>

        <div className="input-field">
          <h6>{labels.ADMIN_SEACRH_BY_LABEL}</h6>
          {searchBy.map((item, i) => {
            return (
              <div className="searchBySection" key={item.Id}>
                <span className="list-checkbox">
                  <input
                    name="searchBy"
                    test-data-id={"search-mod-" + item.Id}
                    type="radio"
                    value={item.Id}
                    checked={searchModeValue === item.Id}
                    onChange={(e) => handleSearchModeChange(e, { param: item })}
                  />
                </span>
                <div>
                  <span>{item.Title}</span>
                </div>
              </div>
            );
          })}
        </div>
        {searchModeValue == 1 ? (
          <div className={"input-field-custom setHeight50p"}>
            <h6>{labels.ADMIN_SEACRH_BY_TEXT_LABEL}</h6>
            <div>
              <input
                test-data-id={"input-search-by-text"}
                name={"SearchByText"}
                type={"text"}
                value={SearchByText}
                onChange={(e) => onFormFieldChange(e)}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {searchModeValue == 2 ? (
          <div className={"input-field-custom"}>
            <h6>{labels.ADMIN_SEACRH_BY_IDENTIFIER_LABEL}</h6>
            <div>
              <input
                test-data-id={"input-search-by-Id"}
                name={"SearchByIdentifier"}
                type={"text"}
                value={SearchByIdentifier}
                onChange={(e) => onFormFieldChange(e)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {searchModeValue == 3 ? (
          <div>
            <div className="input-field">
              <h6>{labels.ADMIN_USER_TYPES_LABEL}</h6>
              {UserTypes.map((item, i) => {
                return (
                  <div className="list-item" key={item.UserTypeId}>
                    <span className="list-checkbox">
                      <input
                        name="UserTypes"
                        test-data-id={"user-types-checbox"}
                        type="checkbox"
                        value={item.UserTypeId}
                        checked={item.IsChecked}
                        onChange={(e) =>
                          handleFilterCheckedChange(e, { param: item })
                        }
                      />
                    </span>
                    <div>
                      <span>{item.Title}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="input-field">
              <h6>{labels.ADMIN_CATEGORIES_LABEL}</h6>
              {Categories.map((item, i) => {
                return (
                  <div className="list-item" key={item.CategoryId}>
                    <span className="list-checkbox">
                      <input
                        name="Categories"
                        type="checkbox"
                        test-data-id={"categories-checbox"}
                        value={item.CategoryId}
                        checked={item.IsChecked}
                        onChange={(e) =>
                          handleFilterCheckedChange(e, { param: item })
                        }
                      />
                    </span>
                    <div>
                      <span>{item.Title}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="input-field">
              <h6>{labels.ADMIN_MODULES_LABEL}</h6>
              {Modules.map((item, i) => {
                return (
                  <div className="list-item" key={item.ModuleId}>
                    <span className="list-checkbox">
                      <input
                        name="Modules"
                        type="checkbox"
                        test-data-id={"modules-checbox"}
                        value={item.ModuleId}
                        checked={item.IsChecked}
                        onChange={(e) =>
                          handleFilterCheckedChange(e, { param: item })
                        }
                      />
                    </span>
                    <div>
                      <span>{item.Title}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="input-field">
              <h6>{labels.ADMIN_SUBMODULES_LABEL}</h6>
              {SubModules.map((item, i) => {
                return (
                  <div className="list-item" key={item.SubModuleId}>
                    <span className="list-checkbox">
                      <input
                        name="SubModules"
                        type="checkbox"
                        test-data-id={"submodules-checbox"}
                        value={item.SubModuleId}
                        checked={item.IsChecked}
                        onChange={(e) =>
                          handleFilterCheckedChange(e, { param: item })
                        }
                      />
                    </span>
                    <div>
                      <span>{item.Title}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {searchModeValue == 4 ? (
          <div className="input-field">
            <h6>{labels.ADMIN_SYSTEM_LABELS_IMPORT_EXPORT}</h6>
            <button
              test-data-id={"btn-excel-model"}
              className="btn-login"
              onClick={handleUpoladExcel}
            >
              <img src={importIcon}></img>
              <div>{labels.ADMIN_SYSTEM_LABELS_IMPORT}</div>
            </button>
            {csvData.length > 0 ? (
              <button
                test-data-id={"btn-export-systemlabels"}
                className="btn-login"
                onClick={handleDownloadExcel}
              >
                <CSVLink
                  id="csvExportBtn"
                  filename={`${
                    "system-labels-" + selectedLanguage.label + ".csv"
                  }`}
                  data={csvData}
                >
                  <img src={exportIcon}></img>
                  <div>{labels.ADMIN_SYSTEM_LABELS_EXPORT}</div>
                </CSVLink>
              </button>
            ) : (
              <button
                test-data-id={"btn-fetch-systemlabels"}
                className="btn-login"
                onClick={handelGetSystemLabelsByLanguageId}
              >
                <img src={exportIcon}></img>
                <div>{labels.ADMIN_SYSTEM_LABELS_EXPORT}</div>
              </button>
            )}
          </div>
        ) : (
          ""
        )}
        {systemLablesExcelTable ? (
          <div className="input-field">
            <h6>{labels.ADMIN_SYSTEM_LABELS_IMPORT_EXPORT}</h6>

            <input
              className="excel-input"
              test-data-id={"upload-file-input"}
              type="file"
              onChange={HandelFilerender}
            />
          </div>
        ) : OnSearchButton ? (
          <button
            test-data-id={"search-systemlabels-btn"}
            className="btn btn-login"
            onClick={handlsearch}
          >
            {labels.ADMIN_SEARCH_LABEL}
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default SystemLabelsFilter;
