import React from "react";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import htmlIcon from "../../../Assests/Images/htmlIcon.png";
import { CustomInput } from "../../../components";

class SystemLabelsList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      loadingId,
      onFormFieldChange,
      onFormFieldBlur,
      currentWorkplace,
      SystemLabelsList,
      handleSystemLabelChange,
      HandlHTMLEditor,
      labels,
    } = this.props;

    return (
      <div
        className="system_labels_list"
        test-data-id={"systemlabels-list-item"}
      >
        {SystemLabelsList && SystemLabelsList.length > 0 ? (
          <div>
            {SystemLabelsList.map((item, i) => {
              return (
                <div
                  className="input-field"
                  test-data-id={"systemlabels-list-item"}
                  key={i}
                >
                  <h6>{item.Identifier}</h6>
                  <div className="labelsListItem">
                    <input
                      name="systemLabels"
                      type="text"
                      value={item.Message}
                      onBlur={(e) => onFormFieldBlur(e, { param: item })}
                      test-data-id={"systemlabels-list-input"}
                      onChange={(e) =>
                        handleSystemLabelChange(e, { param: item })
                      }
                    />
                    {loadingId == item.SystemLabelId ? (
                      <img src={loadingIcon}></img>
                    ) : (
                      <img
                        src={htmlIcon}
                        onClick={(e) => HandlHTMLEditor(e, { param: item })}
                      ></img>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-item-label">
            <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
          </div>
        )}
      </div>
    );
  }
}

export default SystemLabelsList;
