import React,{Component} from 'react';
import { connect } from 'react-redux';
import {setNotificationAction} from "../../Redux/actions";


class Notification extends Component{
    componentDidMount(){
        setTimeout(()=>{
            this.hideNotification();
        },4000);
    }
    hideNotification=()=>{
        this.props.setNotificationAction({
            message:"",
            status:""
        })
    }
    render(){
        const {
            message,
            status
        } = this.props; 
        return(
            <div className={`notification ${status}`}>
                <p>{message}</p>
            </div>
        );
    }
}

const actions ={
    setNotificationAction
};

export default connect(null,actions)(Notification);