import { ACTION_TYPES } from "./actionTypes";

export const deleteUserAction = ()=>{
    return {
        type:ACTION_TYPES.ACCOUNT.DELETE_USER
    }
};

export const saveUserAction = (user)=>{
    return {
        type:ACTION_TYPES.ACCOUNT.SAVE_USER,
        payload:user
    }
};

export const setNotificationAction = (notification) =>{
    return {
        type:ACTION_TYPES.NOTIFICATION.SET_NOTIFICATION,
        payload:notification
    }
}

export const toggleLoadingAction = (isLoading) =>{
    return {
        type:ACTION_TYPES.LOADING.TOGGLE_LOADING,
        payload:isLoading
    }
}

export const setLabelsAction = (labels) =>{
    return {
        type:ACTION_TYPES.LABELS.SET_LABELS,
        payload:labels
    }
}