import React, { Component } from "react";
import { Column, Button, CustomInput } from "../../components";
import "./lookups.scss";
import {
  addWorkplaceApi,
  deleteWorkplaceApi,
  updateWorkplaceApi,
  getAllWorkplaceContactApi,
  addWorkplaceContactApi,
  updateWorkplaceContactApi,
  deleteWorkplaceContactApi,
  getWorkplaceSubscriptionConfigurationApi,
  updateConfigurationValueApi,
  addConfigurationValueApi,
  createDatabaseApi,
  fillDatabaseApi,
  cloneProdooCore,
  cloneProdooWeb,
  cloneProdooAdmin,
  createProdooWebsite,
  createProdooWebApi,
  createProdooAdminWebsite,
  createProdooAdminWebApi,
  deleteWorkplaceSubscriptionAndConfiguration,
  createAdminWebApi,
  isDomainAvailableApi,
  stopWebsitesApi,
  resumeWebsitesApi,
  deallocateResoucesApi,
  addNewlyCreatedSiteInProdooApi,
  upgradeWebsitesApi,
} from "../../Services/apiCalls";
import LookupWorkPlace from "./Components/LookupWorkPlace";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
import { validateInputField } from "../../utilities/validator";
import { isValidEmail } from "../../utilities/validations";
class Lookups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: true,
      isCollapsed3: true,
      isCollapsed4: true,
      isCollapsed5: true,
      isCollapsed6: true,
      isCollapsed7: true,
      searchKey: "",
      workplaceList: [],
      currentWorkplace: {
        Name: "",
        UserId: -1,
        WorkPlaceId: 0,
        isCreatedDB: 1,
        isInsertingLookup: 1,
        isCreatedProdooAdmin: 1,
        isCloningCore: 1,
        isCloningWeb: 1,
        isCloningAdmin: 1,
        isCreatedProdoo: 1,
        isCreatedProdooWebApi: 1,
        isCreatedProdooAdminWebApi: 1,
        isCreatedAdmin: 1,
        loadingValue: 0,
      },
      currentWorkplaceContact: {},
      selectedContact: {
        WorkplaceContactId: 0,
      },
      isHelpActive: false,
      isQuick: true,
      lastFocusField: "",
    };
  }
  componentDidMount = () => {
    setTimeout(() => {}, 10);
  };
  //Prodoo Api Calls
  addNewlyCreatedWebsiteInProdoo = (item) => {
    addNewlyCreatedSiteInProdooApi(item)
      .then((res) => {})
      .catch((err) => {
        console.log("Prodoo error ", err);
      });
  };

  //Workplace Api Calls
  addWorkplace() {
    let { currentWorkplace } = this.state;
    currentWorkplace = {
      ...currentWorkplace,
      CountryId:
        currentWorkplace.CountryId == -1 ? null : currentWorkplace.CountryId,
      loadingValue: 0,
    };
    addWorkplaceApi(currentWorkplace)
      .then((res) => {
        const { items, success } = res;
        if (success) {
          this.setState({
            currentWorkplace: {
              ...items,
              loadingValue: 0,
            },
            workplaceList: this.state.workplaceList.concat(items),
            filteredWorkplacesList:
              !this.state.searchKey ||
              items.Name.toLowerCase().includes(this.state.searchKey)
                ? this.state.filteredWorkplacesList.concat(items)
                : this.state.filteredWorkplacesList,
          });
        }
      })
      .catch((err) => console.log("err ", err))
      .finally(() => {
        this.setState({
          lastFocusField: "",
        });
      });
  }
  updateWorkplace() {
    let { currentWorkplace } = this.state;
    currentWorkplace = {
      ...currentWorkplace,
      CountryId:
        currentWorkplace.CountryId == -1 ? null : currentWorkplace.CountryId,
    };
    updateWorkplaceApi(currentWorkplace)
      .then((res) => {
        const { items, success } = res;
        if (success) {
          this.setState({
            workplaceList: this.state.workplaceList.map((w) =>
              w.WorkPlaceId == currentWorkplace.WorkPlaceId ? items : w
            ),
            filteredWorkplacesList: this.state.filteredWorkplacesList.map((w) =>
              w.WorkPlaceId == currentWorkplace.WorkPlaceId ? items : w
            ),
          });
        }
      })
      .catch((err) => console.log("err ", err))
      .finally(() => {
        this.setState({
          lastFocusField: "",
        });
      });
  }
  deleteWorkplace(item) {
    const {
      workplaceList,
      currentWorkplace,
      filteredWorkplacesList,
    } = this.state;
    deleteWorkplaceApi(item.WorkPlaceId)
      .then((res) => {
        const { items, success } = res;
        if (success) {
          this.setState({
            workplaceList: workplaceList.filter(
              (listItem) => listItem.WorkPlaceId != item.WorkPlaceId
            ),
            filteredWorkplacesList: filteredWorkplacesList.filter(
              (listItem) => listItem.WorkPlaceId != item.WorkPlaceId
            ),
            currentWorkplace:
              currentWorkplace.WorkPlaceId == item.WorkPlaceId
                ? {
                    UserId: this.props.user.UserId,
                    WorkPlaceId: 0,
                    CountryId: -1,
                  }
                : currentWorkplace,
          });
        }
      })
      .catch((err) => console.log("Err ", err));
  }
  ///////////////////////////

  handleFormFieldChange = (e, typeOf, kkkk, inputType) => {
    const { name, value, type } = e.target;
    if (typeOf == 1) {
      this.setState({
        currentWorkplace: {
          ...this.state.currentWorkplace,
          [name]: value,
          [`isValid${name}`]: validateInputField(value, type),
        },
      });
    } else if (typeOf == 2) {
      this.setState({
        currentWorkplace: {
          ...this.state.currentWorkplace,
          selectedContact: {
            ...this.state.currentWorkplace.selectedContact,
            [name]: value,
            [`isValid${name}`]: validateInputField(value, inputType),
          },
        },
      });
    }
  };
  handleFormFieldBlur = (e, typeOf, kkkk, inputType) => {
    const { currentWorkplace } = this.state;
    const {
      Name,
      Website,
      WorkPlaceId,
      isValidName,
      isValidWebsite,
    } = currentWorkplace;
    if (Name && Website) {
      if (typeOf == 1) {
        if (isValidName == false || isValidWebsite == false) {
          return;
        }
        this.setState({
          lastFocusField: e.target.name,
        });
        if (WorkPlaceId == 0) this.addWorkplace();
        else this.updateWorkplace();
      }
      if (typeOf == 2) {
        let { Contacts = [], selectedContact } = currentWorkplace;
        if (!currentWorkplace.WorkPlaceId > 0 || !selectedContact.Name) {
          return;
        }
        if (!validateInputField(e.target.value, inputType)) {
          return;
        }
        if (
          e.target.Name == "Email" &&
          !!selectedContact.Email &&
          !isValidEmail(selectedContact.Email)
        ) {
          return;
        }
        selectedContact = {
          ...selectedContact,
          lastFocusField: e.target.name,
          WorkPlaceId: currentWorkplace.WorkPlaceId,
          WorkplaceContactId:
            selectedContact.WorkplaceContactId == undefined
              ? 0
              : selectedContact.WorkplaceContactId,
          Email: isValidEmail(selectedContact.Email)
            ? selectedContact.Email
            : "",
        };
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            selectedContact,
          },
        });
        if (selectedContact.WorkplaceContactId == 0) {
          addWorkplaceContactApi(selectedContact).then((res) => {
            const { items, success } = res;
            if (success) {
              this.setState({
                currentWorkplace: {
                  ...currentWorkplace,
                  selectedContact: res.items,
                  Contacts: Contacts.concat(items),
                },
              });
            }
          });
        } else {
          updateWorkplaceContactApi(selectedContact).then((res) => {
            const { items, success } = res;
            if (success) {
              selectedContact = res.items;
              var updatedContactsList = Contacts.map((c) =>
                c.WorkplaceContactId == selectedContact.WorkplaceContactId
                  ? selectedContact
                  : c
              );
              const updatedCurrentWorkplace = {
                ...this.state.currentWorkplace,
                Contacts: updatedContactsList,
                selectedContact: {
                  ...selectedContact,
                  lastFocusField: "",
                },
              };
              const updatedList = this.state.filteredWorkplacesList.map((w) =>
                w.WorkPlaceId == updatedCurrentWorkplace.WorkPlaceId
                  ? updatedCurrentWorkplace
                  : w
              );
              this.setState({
                currentWorkplace: updatedCurrentWorkplace,
                filteredWorkplacesList: updatedList,
              });
            }
          });
        }
      }
    }
  };

  //common
  handleListItemClick = (item, type) => {
    if (type == 1) {
      item = {
        ...item,
        CountryId: !item.CountryId ? -1 : item.CountryId,
        loadingValue: 0,
      };
      this.setState({
        currentWorkplace: item,
        isCollapsed2: false,
        isCollapsed3: false,
        isCollapsed5: false,
      });
    } else if (type == 2) {
      this.setState({
        currentWorkplace: {
          ...this.state.currentWorkplace,
          selectedContact: item,
        },
        isCollapsed4: false,
      });
    } else if (type == 3) {
      getWorkplaceSubscriptionConfigurationApi(item.WorkplaceSubscriptionId)
        .then((res) => {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              selectedSubscription: {
                WorkplaceSubscriptionId: item.WorkplaceSubscriptionId,
                Configuration: res.items.map((c) => {
                  const newItem = {
                    ...c,
                    [`isValid${c.Title.replace(" ", "")}`]: "true",
                  };
                  return newItem;
                }),
                IsExecuted:
                  res.items.length > 0 ? !!res.items[0].IsExcueted : false,
                IsStopped:
                  res.items.length > 0 ? !!res.items[0].IsStopped : false,
              },
              isCreatedDB: 1,
              isInsertingLookup: 1,
              isCreatedProdooAdmin: 1,
              isCloningCore: 1,
              isCloningWeb: 1,
              isCloningAdmin: 1,
              isCreatedProdoo: 1,
              isCreatedProdooWebApi: 1,
              isCreatedProdooAdminWebApi: 1,
              isCreatedAdmin: 1,
              loadingValue: 0,
            },
            isCollapsed6: false,
          });
        })
        .catch((err) => console.log("Err ", err));
    }
  };
  handleListItemDelete = (item, type) => {
    if (type == 1) {
      if (item.WorkplaceSubscriptions.length > 0) {
        const info = {
          message: this.props.labels.ADMIN_DELET_ASSOCIATED_SUBSCRIPTION,
          status: "error",
        };
        this.props.setNotificationAction(info);
        return;
      } else {
        this.deleteWorkplace(item);
      }
    } else if (type == 2) {
      deleteWorkplaceContactApi(item.WorkplaceContactId)
        .then((res) => {
          const { items, success } = res;
          if (success) {
            var updatedContactsList = this.state.currentWorkplace.Contacts.filter(
              (c) => c.WorkplaceContactId !== item.WorkplaceContactId
            );
            this.setState({
              currentWorkplace: {
                ...this.state.currentWorkplace,
                Contacts: updatedContactsList,
                selectedContact: {},
              },
            });
          }
        })
        .catch((err) => console.log("Error ", err));
    } else if (type == 3) {
      this.deallocateResources(item, 1);
    }
  };
  handleSearchChange = (e) => {
    const { value } = e.target;
    const { workplaceList } = this.state;
    const filteredWorkplaces = workplaceList.filter((wp) =>
      wp.Name.toLowerCase().includes(value.toLowerCase())
    );
    this.setState({
      searchKey: e.target.value,
      filteredWorkplacesList: filteredWorkplaces,
    });
  };
  handleAddNew = (typeOf) => {
    if (typeOf == 1) {
      this.setState({
        currentWorkplace: {
          UserId: this.props.user.UserId,
          WorkPlaceId: 0,
          CountryId: -1,
          loadingValue: 0,
        },
        isCollapsed2: false,
      });
    } else if (typeOf == 2) {
      this.setState({
        currentWorkplace: {
          ...this.state.currentWorkplace,
          selectedContact: {},
        },
        isCollapsed4: false,
      });
    }
  };
  handleConfigurationValueChange = (e, inputType, item) => {
    if (e.target.name == "Domain") {
      var patt = /^[a-zA-Z]+$/;
      if (e.target.value && !patt.test(e.target.value)) {
        return;
      }
    }
    item = {
      ...item,
      Value: e.target.value,
      [`isValid${e.target.name.replace(" ", "")}`]:
        validateInputField(e.target.value, e.target.type) == true
          ? "true"
          : "false",
    };
    const updatedSelectedSubscription = this.state.currentWorkplace.selectedSubscription.Configuration.map(
      (sub) =>
        sub.SubscriptionConfigurationId == item.SubscriptionConfigurationId
          ? item
          : sub
    );
    this.setState({
      currentWorkplace: {
        ...this.state.currentWorkplace,
        selectedSubscription: {
          ...this.state.currentWorkplace.selectedSubscription,
          Configuration: updatedSelectedSubscription,
        },
      },
    });
  };
  handleConfigurationValueBlur = (e, inputType, item) => {
    item = {
      ...item,
      Value: e.target.value,
    };
    if (item[`isValid${e.target.name.replace(" ", "")}`] != "true") {
      return;
    }
    if (item.WorkplaceSubscriptionConfigurationId > 0 && item.Value != null) {
      updateConfigurationValueApi(
        item.WorkplaceSubscriptionConfigurationId,
        item.Value,
        this.state.currentWorkplace.WorkPlaceId,
        item.SubscriptionConfigurationId
      )
        .then((res) => {
          const updatedSelectedSubscription = this.state.currentWorkplace.selectedSubscription.Configuration.map(
            (sub) =>
              sub.SubscriptionConfigurationId ==
              item.SubscriptionConfigurationId
                ? item
                : sub
          );
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              selectedSubscription: {
                ...this.state.currentWorkplace.selectedSubscription,
                Configuration: updatedSelectedSubscription,
              },
            },
          });
        })
        .catch((err) => console.log("Errrrrrrr", err));
    } else {
      if (item.Value) {
        const model = {
          Value: item.Value,
          SubscriptionConfigurationId: item.SubscriptionConfigurationId,
          WorkplaceSubscriptionId: item.WorkplaceSubscriptionId,
        };
        addConfigurationValueApi(model)
          .then((res) => {
            item = {
              ...item,
              WorkplaceSubscriptionConfigurationId:
                res.items.WorkplaceSubscriptionConfigurationId,
            };
            const updatedSelectedSubscription = this.state.currentWorkplace.selectedSubscription.Configuration.map(
              (sub) =>
                sub.SubscriptionConfigurationId ==
                item.SubscriptionConfigurationId
                  ? item
                  : sub
            );
            this.setState({
              currentWorkplace: {
                ...this.state.currentWorkplace,
                selectedSubscription: {
                  ...this.state.currentWorkplace.selectedSubscription,
                  Configuration: updatedSelectedSubscription,
                },
              },
            });
          })
          .catch((err) => console.log("Error ", err));
      }
    }
  };

  handleExcecute = () => {
    const domainName =
      this.state.currentWorkplace.selectedSubscription &&
      this.state.currentWorkplace.selectedSubscription.Configuration[0].Value;
    const email =
      this.state.currentWorkplace.selectedSubscription &&
      this.state.currentWorkplace.selectedSubscription.Configuration[2].Value;
    if (!domainName || !email) {
      const info = {
        message: this.props.labels.ADMIN_FILL_REQUIRE_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }

    const isValidDomain =
      this.state.currentWorkplace.selectedSubscription &&
      this.state.currentWorkplace.selectedSubscription.Configuration[0]
        .isValidDomain;
    const isValidEmail =
      this.state.currentWorkplace.selectedSubscription &&
      this.state.currentWorkplace.selectedSubscription.Configuration[2]
        .isValidSuperAdmin;

    if (isValidDomain != "true" || isValidEmail != "true") {
      const info = {
        message: this.props.labels.ADMIN_FIX_VALIDATION_ERRORS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }
    this.checkAvailablityAndExecute(domainName, email);
  };
  handleExcecuteOptionChange = (e) => {
    this.setState({
      isQuick: !this.state.isQuick,
    });
  };
  deallocateResources(item, type) {
    const domainName =
      this.state.currentWorkplace.selectedSubscription &&
      this.state.currentWorkplace.selectedSubscription.Configuration[0].Value;
    if (!domainName) {
      getWorkplaceSubscriptionConfigurationApi(item.WorkplaceSubscriptionId)
        .then((res) => {
          if (!(res.items.length > 0 ? !!res.items[0].IsExcueted : false)) {
            deleteWorkplaceSubscriptionAndConfiguration(
              item.WorkplaceSubscriptionId
            )
              .then((res) => {
                if (type == 1) {
                  const workplaceSubscriptions = this.state.currentWorkplace.WorkplaceSubscriptions.filter(
                    (a) =>
                      a.WorkplaceSubscriptionId != item.WorkplaceSubscriptionId
                  );
                  const currentWorkplace = {
                    ...this.state.currentWorkplace,
                    WorkplaceSubscriptions: workplaceSubscriptions,
                    selectedSubscription: {},
                  };
                  const filteredWorkplacesList = this.state.filteredWorkplacesList.map(
                    (listItem) =>
                      listItem.WorkPlaceId ==
                      this.state.currentWorkplace.WorkPlaceId
                        ? currentWorkplace
                        : listItem
                  );
                  this.setState({
                    currentWorkplace,
                    filteredWorkplacesList,
                  });
                } else {
                  this.setState({
                    currentWorkplace: {
                      ...this.state.currentWorkplace,
                      selectedSubscription: {
                        ...this.state.currentWorkplace.selectedSubscription,
                        IsExecuted: false,
                      },
                    },
                  });
                }
                if (res.success) {
                  const info = {
                    message: this.props.labels
                      .ADMIN_DEALLOCATE_RESOURCE_SUCCESS,
                    status: "success",
                  };
                  this.props.setNotificationAction(info);
                } else {
                  const info = {
                    message: this.props.labels.ADMIN_DEALLOCATE_RESOURCE_FAIL,
                    status: "error",
                  };
                  this.props.setNotificationAction(info);
                }
              })
              .catch((err) => console.log("Err ", err));
          } else {
            this.deallocateResourcesMethod(res.items[0].Value, item, type);
          }
        })
        .catch((err) => console.log("Err ", err));
    } else {
      this.deallocateResourcesMethod(domainName, item, type);
    }
  }
  upgradeWebsite(item) {
    const domainName =
      this.state.currentWorkplace.selectedSubscription &&
      this.state.currentWorkplace.selectedSubscription.Configuration[0].Value;
    if (domainName) {
      upgradeWebsitesApi(domainName)
        .then((res) => {
          if (res.success) {
            const info = {
              message: "Successfully upgraded to latest",
              status: "success",
            };
            this.props.setNotificationAction(info);
          } else {
            const info = {
              message: "There was an error while upgrading Please try again",
              status: "error",
            };
            this.props.setNotificationAction(info);
          }
        })
        .catch((err) => console.log("Err ", err));
    }
  }
  deallocateResourcesMethod(domainName, item, type) {
    deallocateResoucesApi(domainName, item.WorkplaceSubscriptionId, type)
      .then((res) => {
        if (!res) throw "There was an error deallocated the resources";
        if (res.success) {
          const info = {
            message: "Successfully deallocated the resources",
            status: "success",
          };
          this.props.setNotificationAction(info);
        } else {
          const info = {
            message: "There was an error while deallocating the resources",
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
        if (type == 1) {
          const workplaceSubscriptions = this.state.currentWorkplace.WorkplaceSubscriptions.filter(
            (a) => a.WorkplaceSubscriptionId != item.WorkplaceSubscriptionId
          );
          const currentWorkplace = {
            ...this.state.currentWorkplace,
            WorkplaceSubscriptions: workplaceSubscriptions,
            selectedSubscription: {},
          };
          const filteredWorkplacesList = this.state.filteredWorkplacesList.map(
            (listItem) =>
              listItem.WorkPlaceId == this.state.currentWorkplace.WorkPlaceId
                ? currentWorkplace
                : listItem
          );
          this.setState({
            currentWorkplace,
            filteredWorkplacesList,
          });
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              selectedSubscription: {
                ...this.state.currentWorkplace.selectedSubscription,
                IsExecuted: false,
              },
            },
          });
        }
      })
      .catch((err) => {
        const info = {
          message: "There was an error while deallocating the resources",
          status: "error",
        };
        this.props.setNotificationAction(info);
        console.log("Err ", err);
      });
  }

  checkAvailablityAndExecute(domainName, email) {
    isDomainAvailableApi(domainName)
      .then((res) => {
        if (!res)
          throw "There was an error while checking the domain availablity Please try again later.";
        if (!res.success) {
          const info = {
            message: "Sorry! This domain is not available",
            status: "error",
          };
          this.props.setNotificationAction(info);
          return;
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedDB: 2, //1-Not Executed 2-In Progress 3-Successfully Completed 4-Completed with error
            },
          });
          this.createDatabaseAndInserLookup(domainName, email);
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCloningCore: 2,
              isCloningWeb: 2,
              isCloningAdmin: 2,
            },
          });
          this.cloneProdooCore(domainName);
          this.cloneProdooWeb(domainName);
        }
      })
      .catch((res) => {
        const info = {
          message: res,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  createDatabaseAndInserLookup(domainName, email) {
    createDatabaseApi(
      domainName,
      this.state.isQuick,
      this.state.currentWorkplace.selectedSubscription.WorkplaceSubscriptionId
    )
      .then((res) => {
        if (!res) throw "There was an error while Created database";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedDB: 3,
              isInsertingLookup: 2,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
          this.insertLookupAndCreateAdmin(domainName, email);
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedDB: 4,
              isInsertingLookup: 1,
            },
          });
          const info = {
            message: res.Message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCreatedDB: 4,
            isInsertingLookup: 1,
          },
        });
        const info = {
          message: err,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  insertLookupAndCreateAdmin(domainName, email) {
    fillDatabaseApi(domainName, this.state.isQuick)
      .then((res) => {
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedDB: 3,
              isInsertingLookup: 3,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
              isCreatedAdmin: 2,
            },
          });
          this.createWebsiteAdmin(email, domainName);
        } else {
          const info = {
            message: res.message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => {
        const info = {
          message: err.message,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  cloneProdooCore(domainName) {
    cloneProdooCore(domainName)
      .then((res) => {
        if (!res) throw "There was an error while cloning Prodoo Core Code";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCloningCore: 3,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCloningCore: 4,
            },
          });
          const info = {
            message: res.Message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCloningCore: 4,
          },
        });
        const info = {
          message: err,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  cloneProdooWeb(domainName) {
    cloneProdooWeb(domainName)
      .then((res) => {
        if (!res) throw "There was an error while cloning Prodoo Web Code";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCloningWeb: 3,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
          this.cloneProdooAdminAndCreateAllWebsites(domainName);
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCloningWeb: 4,
            },
          });
          const info = {
            message: res.Message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((res) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCloningWeb: 4,
          },
        });
        const info = {
          message: res,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  cloneProdooAdminAndCreateAllWebsites(domainName) {
    cloneProdooAdmin(domainName)
      .then((res) => {
        if (!res)
          throw "There was an error while creating Prodoo Admin Website";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCloningAdmin: 3,
              isCreatedProdoo: 2,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
          this.createWebsites(domainName);
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCloningAdmin: 4,
              isCreatedProdoo: 1,
              isCreatedProdooAdmin: 1,
            },
          });
          const info = {
            message: res.Message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((ex) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCloningAdmin: 4,
            isCreatedProdoo: 1,
            isCreatedProdooAdmin: 1,
          },
        });
        const info = {
          message: ex,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  createWebsites(domainName) {
    createProdooWebsite(domainName)
      .then((res) => {
        if (!res)
          throw "There was an erroe while creating Prodoo Website Please try again later";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdoo: 3,
              isCreatedProdooWebApi: 2,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
          this.createProdooWebApiAndAdminWebSites(domainName);
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdoo: 4,
              isCreatedProdooWebApi: 1,
            },
          });
          const info = {
            message: res.message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((ex) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCreatedProdoo: 4,
            isCreatedProdooWebApi: 1,
          },
        });
        const info = {
          message: ex,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  createProdooWebApiAndAdminWebSites(domainName) {
    createProdooWebApi(domainName)
      .then((res) => {
        if (!res)
          throw "There was an erroe while creating Prodoo Web Api Please try again later";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdooWebApi: 3,
              isCreatedProdooAdmin: 2,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
          this.createProdooAdminWebsiteAndWebApi(domainName);
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdooWebApi: 4,
            },
          });
          const info = {
            message: res.message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((ex) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCreatedProdooWebApi: 4,
          },
        });
        const info = {
          message: ex,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  createProdooAdminWebsiteAndWebApi(domainName) {
    createProdooAdminWebsite(domainName)
      .then((res) => {
        if (!res)
          throw "There was an error while creating Prodoo Admin Website Please try again later";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdooAdmin: 3,
              isCreatedProdooAdminWebApi: 2,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
          this.createProdooAdminWebApi(domainName);
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdooAdmin: 4,
              isCreatedProdooAdminWebApi: 1,
            },
          });
          const info = {
            message: res.message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((ex) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCreatedProdooAdmin: 4,
            isCreatedProdooAdminWebApi: 1,
          },
        });
        const info = {
          message: ex,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  createProdooAdminWebApi(domainName) {
    createProdooAdminWebApi(domainName)
      .then((res) => {
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdooAdminWebApi: 3,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
            },
          });
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedProdooAdminWebApi: 4,
            },
          });
          const info = {
            message: res.message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((ex) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCreatedProdooAdminWebApi: 4,
          },
        });
        const info = {
          message: ex,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }

  createWebsiteAdmin(email, domainName) {
    createAdminWebApi(email, domainName)
      .then((res) => {
        if (!res) throw "There was an error while creating Admin";
        if (res.success) {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedAdmin: 3,
              loadingValue: this.state.currentWorkplace.loadingValue + 10,
              selectedSubscription: {
                ...this.state.currentWorkplace.selectedSubscription,
                IsExecuted: true,
              },
            },
          });
          const newlyCreratedSite = {
            WorkPlaceId: this.state.currentWorkplace.WorkPlaceId,
            DomainName: domainName,
            SubscriptionId: this.state.currentWorkplace.selectedSubscription
              .WorkplaceSubscriptionId,
            AffiliateId: this.props.user.UserId,
          };
          this.addNewlyCreatedWebsiteInProdoo(newlyCreratedSite);
        } else {
          this.setState({
            currentWorkplace: {
              ...this.state.currentWorkplace,
              isCreatedAdmin: 4,
            },
          });
          const info = {
            message: res.Message,
            status: "error",
          };
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => {
        this.setState({
          currentWorkplace: {
            ...this.state.currentWorkplace,
            isCreatedAdmin: 4,
          },
        });
        const info = {
          message: err,
          status: "error",
        };
        this.props.setNotificationAction(info);
      });
  }
  handleToggleWebsiteResume = (type) => {
    const domainName =
      this.state.currentWorkplace.selectedSubscription &&
      this.state.currentWorkplace.selectedSubscription.Configuration[0].Value;
    if (type == 1) {
      stopWebsitesApi(
        domainName,
        this.state.currentWorkplace.selectedSubscription.WorkplaceSubscriptionId
      )
        .then((res) => {
          if (!res) throw "Error while pausing website";
          if (res.success) {
            const info = {
              message: "Website successfully stopped!",
              status: "success",
            };
            this.props.setNotificationAction(info);
            this.setState({
              currentWorkplace: {
                ...this.state.currentWorkplace,
                selectedSubscription: {
                  ...this.state.currentWorkplace.selectedSubscription,
                  IsStopped: true,
                },
              },
            });
          }
        })
        .catch((ex) => {
          const info = {
            message: ex,
            status: "error",
          };
          this.props.setNotificationAction(info);
        });
    } else if (type == 2) {
      resumeWebsitesApi(
        domainName,
        this.state.currentWorkplace.selectedSubscription.WorkplaceSubscriptionId
      )
        .then((res) => {
          if (!res) throw "Error while resuming website";
          if (res.success) {
            const info = {
              message: "Website successfully resumed!",
              status: "success",
            };
            this.props.setNotificationAction(info);
            this.setState({
              currentWorkplace: {
                ...this.state.currentWorkplace,
                selectedSubscription: {
                  ...this.state.currentWorkplace.selectedSubscription,
                  IsStopped: false,
                },
              },
            });
          }
        })
        .catch((ex) => {
          const info = {
            message: ex,
            status: "error",
          };
          this.props.setNotificationAction(info);
        });
    }
  };

  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      isCollapsed4,
      isCollapsed5,
      isCollapsed6,
      isCollapsed7,
      searchKey,
      workplaceList,
      currentWorkplace,
      filteredWorkplacesList,
      isQuick,
    } = this.state;
    return (
      <div className="columns-wrapper lookup-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_LOOKUPs_LABEL}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_LOOKUPs_LABEL}</p>
            </div>
          </Column.Head>
          <Column.Body>
            <LookupWorkPlace
              onFormFieldChange={this.handleFormFieldChange}
              setNotificationAction={this.props.setNotificationAction}
              toggleLoadingAction={this.props.toggleLoadingAction}
              labels={labels}
            />
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(Lookups));
