import React from "react";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import { CustomInput, List } from "../../../components";

class LookupCorrelationDataList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      onFormSelectChange,
      LookupTypeList,
      lookuptypesdataFilter,
      LookupListItemClick,
      onFormFieldChange,
      SearchByText,
      SearchByTextValue,
      lookupTypeValueForCorrelationData,
      lookupTypeCorrelationSearchChange,
      LookupTypeCorelationSearchValue,
      DataForCorrelationDataListFilter,
      addCorrelation,
      labels,
    } = this.props;
    return (
      <div className="system_label_filter">
        <div className={"input-field-custom setHeight50p"}>
          <h6>{labels.ADMIN_SEACRH_BY_TEXT_LABEL}</h6>
          <div>
            <input
              name={"LookupTypeCorelationSearchValue"}
              type={"text"}
              value={LookupTypeCorelationSearchValue || ""}
              onChange={(e) => lookupTypeCorrelationSearchChange(e)}
              test-data-id={"lookups-search-input2"}
              placeholder={SearchByText}
            />
          </div>
          {DataForCorrelationDataListFilter &&
          DataForCorrelationDataListFilter.length > 0 ? (
            <List>
              {DataForCorrelationDataListFilter.map((item) => (
                <List.ListItem testId={"lookups-list2-item"} key={item.Id}>
                  {item.Title}
                  <button
                    test-data-id={"add-correlation-btn"}
                    className="addCorrelationBtn"
                    onClick={() => addCorrelation(item)}
                  >
                    +
                  </button>
                </List.ListItem>
              ))}
            </List>
          ) : (
            <div className="no-item-label">
              <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LookupCorrelationDataList;
