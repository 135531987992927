import React, { Component } from "react";
import { getForgotPasswordApi } from "../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setNotificationAction } from "../../Redux/actions";
import { isValidEmail } from "../../utilities/validations";
import { htmlParser } from "../../utilities/heplers";

class ForgotForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      invalidemail: false,
    };
  }
  handleForgotPassword = () => {
    const { email } = this.state;
    const { labels } = this.props;
    if (!email || !isValidEmail(email)) {
      const info = {
        message: labels.FORGOT_PASSWORD_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      this.props.setNotificationAction(info);
      this.setState({
        invalidemail: true,
      });
      return;
    }
    getForgotPasswordApi(email)
      .then((response) => {
        const status = response.success ? "success" : "error";
        const info = {
          message: response.message,
          status,
        };
        this.props.setNotificationAction(info);
        return;
      })
      .catch((error) => console.log(error));
  };
  handleForgotPassword = () => {
    const { Email } = this.state;
    const { labels } = this.props;
    if (!Email || !isValidEmail(Email)) {
      const info = {
        message: "Email is not valid", //labels.FORGOT_PASSWORD_VALIDATION_ERROR_MESSAGE,
        status: "error",
      };
      this.props.setNotificationAction(info);
      this.setState({
        invalidemail: true,
      });
      return;
    }
    getForgotPasswordApi(Email)
      .then((response) => {
        const status = response.success ? "success" : "error";
        const info = {
          message: response.message,
          status,
        };
        this.props.setNotificationAction(info);
        return;
      })
      .catch((error) => console.log(error));
  };
  handleFormFieldChange = (e) => {
    const { name, value, type } = e.target;
    this.setState({
      [name]: value,
      [`isValid${name}`]: !!value,
    });
  };
  handleFormFieldBlur = (e) => {
    const { name, value, type } = e.target;
    this.setState({
      [name]: value,
      [`isValid${name}`]: !!value,
    });
  };
  handleKeyPress = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      //13 is the enter keycode
      this.handleFormSubmit();
    }
  };
  render() {
    const { onClose, onSignInClick, labels } = this.props;
    const { email, invalidemail } = this.state;
    return (
      <div className="login-form">
        <input
          type="email"
          name="Email"
          className={`${isValidEmail ? "" : "inValid"}`}
          placeholder={"Email"}
          onChange={this.handleFormFieldChange}
          onBlur={this.handleFormFieldBlur}
          test-data-id={"login-email"}
          onKeyPress={this.handleKeyPress}
        />
        <button className="login-btn" onClick={this.handleForgotPassword}>
          Submit
        </button>
        {/* <div className="LoginBtn">
              <input
                type="button"
                onClick={this.handleForgotPassword}
                className="LoginSubmit"
                value="Submit"
              />
            </div> */}
        {/* <a className="Forgot" onClick={onSignInClick}>
              {"Already have an account? Sign In"}
            </a> */}
        <button className="login-forgot" onClick={onSignInClick}>
          Already have an account? Sign In
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ state }) => {
  const { labels } = state;

  return { labels };
};
const mapActionToProps = { setNotificationAction };
export default connect(null, mapActionToProps)(withRouter(ForgotForm));
