import React from "react";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import { CustomInput, List } from "../../../components";

class LookupCorrelationsList extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      onFormSelectChange,
      LookupTypeList,
      lookuptypesdataFilter,
      LookupListItemClick,
      onFormFieldChange,
      SearchByText,
      SearchByTextValue,
      labels,
    } = this.props;
    return (
      <div className="system_label_filter">
        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LOOKUP_TYPE}</h6>
          <select
            name="LookupType"
            test-data-id={"lookup-types-select"}
            value={LookupTypeList.value}
            onChange={(e) => onFormSelectChange(e)}
          >
            {LookupTypeList.map((item) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>

        <div className={"input-field-custom setHeight50p"}>
          <h6>{labels.ADMIN_SEACRH_BY_TEXT_LABEL}</h6>
          <div>
            <input
              name={"SearchByTextValue"}
              type={"text"}
              value={SearchByTextValue || ""}
              onChange={(e) => onFormFieldChange(e)}
              test-data-id={"lookups-search-input"}
              placeholder={SearchByText}
            />
          </div>
          {lookuptypesdataFilter && lookuptypesdataFilter.length > 0 ? (
            <List>
              {lookuptypesdataFilter.map((item) => (
                <List.ListItem
                  testId={"lookups-list-item"}
                  onClick={() => LookupListItemClick(item)}
                  isSelected={item.isActive}
                  key={item.Id}
                >
                  {item.Title}
                </List.ListItem>
              ))}
            </List>
          ) : (
            <div className="no-item-label">
              <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LookupCorrelationsList;
