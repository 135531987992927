import { isValidAlpha, isValidEmail, isValidUrl, isValidZip, isValidPhone } from "./validations";

export const validateInputField = (value="",type="text")=>{
    switch(type){
        case "text":
            return !!value;
        case "email":
            return isValidEmail(value);
        case "password":
            return !!value;
        case "url":
            return isValidUrl(value); 
        case "zip":
            return isValidZip(value);
        case "phone":
            return isValidPhone(value);    
        default:
            return true;       
    }
}