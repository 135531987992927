import React, { Component } from "react";
import "./landing-page.scss";
import Header from "./Header.jsx";
import Login from "./Login.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveUserAction, setNotificationAction } from "../../Redux/actions";
import HeadeMobile from "./HeaderMobile.jsx";
import { publicRoutes, privateRoutes } from "../../Routes/Routes";
import { tokenConfirmationApi } from "../../Services/apiCalls";
import ForgotPassword from "./ForgotPassword";

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: 0,
      isLogin: 0,
      showForgotPassword: false,
      showLogin: true,
    };
  }
  componentDidMount() {
    if (
      this.props.location.pathname.indexOf(
        publicRoutes.emailverification.path
      ) >= 0
    ) {
      const { token } = this.props.match.params;
      this.emailConfirmation(token);
    }
  }
  emailConfirmation = (token) => {
    const { labels } = this.props;
    tokenConfirmationApi(token).then((response) => {
      if (response.success) {
        const info = {
          message:
            "You have successfully verified your email address! Please Login to continue",
          status: "success",
        };
        this.props.setNotificationAction(info);
        this.props.history.push(publicRoutes.default.path);
      } else {
        const info = {
          message: "Token Not Verified Please try again later",
          status: "error",
        };
        this.props.setNotificationAction(info);
      }
    });
  };
  handleDetailClick = (type) => {
    this.setState({
      currentView: type,
    });
  };
  handleHeaderButtonClick = (type) => {
    this.setState({
      isLogin: type,
    });
  };
  handleFormClose = () => {
    this.setState({
      isLogin: 0,
    });
  };
  handleForgotPassword = () => {
    this.setState({
      showForgotPassword: true,
      showLogin: false,
    });
  };
  handleLogin = () => {
    this.setState({
      showLogin: true,
      showForgotPassword: false,
    });
  };
  render() {
    const { currentView, isLogin, showForgotPassword, showLogin } = this.state;
    const { isLoading } = this.props;
    return (
      <React.Fragment>
        {isLoading && (
          <div id="loader-wrapper">
            <div test-data-id={"loader"} id="loader" />
          </div>
        )}
        <div className={`landing-page ${isLoading ? "disabled" : ""}`}>
          <div className="top-bar">
            <img
              className="topBarImg"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABb8AAAABCAMAAADJoyNzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzM5MjdEOUY4RkU3MTFFNjgyRTRDODlGMTQ1MUUwNzEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzM5MjdEQTA4RkU3MTFFNjgyRTRDODlGMTQ1MUUwNzEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDMzkyN0Q5RDhGRTcxMUU2ODJFNEM4OUYxNDUxRTA3MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDMzkyN0Q5RThGRTcxMUU2ODJFNEM4OUYxNDUxRTA3MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtPSGnwAAAAtUExUReeZjN69g6Hr3vd7dLyl62HSrNe65K+WtwTKp7+Q1Dui9vVJSY+C9faqOvF4SVxpDMkAAAAqSURBVHjaYmDjHG6AhWe4AQa+4QaYuYcbYOcaboCRd7gBVo7hBpgAAgwAfDI++tejFEsAAAAASUVORK5CYII="
              alt="img"
            />
          </div>
          <HeadeMobile handleDetailClick={this.handleDetailClick} />
          <Header handleDetailClick={this.handleDetailClick} />
          {/* {currentView == 0 && ( <MainBody 
                    handleDetailClick={this.handleDetailClick}
                />)} */}
          {/* {currentView == 1 && ( <MarketingProgram 
                    handleDetailClick={this.handleDetailClick}
                />)}
                {currentView == 2 && ( <SalesProgram 
                    handleDetailClick={this.handleDetailClick}
                />)}
                {currentView == 3 && ( <ContentProgram 
                    handleDetailClick={this.handleDetailClick}
                />)} */}
          {showLogin && (
            <Login
              onFormClose={this.handleFormClose}
              onForgotPassword={this.handleForgotPassword}
            />
          )}
          {showForgotPassword && (
            <ForgotPassword
              onClose={this.handleModalClose}
              onSignInClick={this.handleLogin}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
  };
}

const actions = {
  saveUserAction,
  setNotificationAction,
};

export default connect(mapStateToProps, actions)(withRouter(LandingPage));
