import React, { Component } from "react";
import "./list.scss";

const ListItem = ({
  children,
  onClick,
  isSelected,
  className,
  testId,
  onMouseEnter,
  onMouseLeave,
  isEffect = true
}) => (
  <li
    test-data-id={testId}
    onClick={onClick}
    className={`list-item animated ${isEffect && "fadeIn"} ${
      isSelected ? "selected-item" : ""
    } ${className} `}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </li>
);
const Close = ({ onClick, onFocus, testId }) => (
  <button
    onFocus={onFocus}
    onClick={onClick}
    className="closeBtn"
    test-data-id={testId}
  />
);
class List extends Component {
  static ListItem = ListItem;
  static Close = Close;
  render() {
    const { children, className, testId, isFetching } = this.props;
    return (
      <div className={`list-wrapper ${className}`}>
        {isFetching ? (
          <div></div>
          // <LoadingMaskRow />
        ) : (
          <ul className={`list-ul`} test-data-id={testId}>
            {children}
          </ul>
        )}
      </div>
    );
  }
}
List.defaultProps = {
  className: ""
};
List.ListItem.defaultProps = {
  className: ""
};

export default List;
