import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { filter, map, sortBy, replace } from "lodash";
import NavButton from "./components/NavButton";
import NavigationSkeleton from "./components/NavigationSkeleton";
import NotificationCircle from "../NotificationCircle/NotificationCircle";
import { Tooltip } from "../Tooltip/Tooltip";
import {
  setNotificationAction,
  toggleLoadingAction,
  deleteUserAction,
} from "../../Redux/actions";
import { StorageService } from "../../Services/storage";
import { publicRoutes } from "../../Routes/Routes";
import { htmlParser } from "../../utilities/heplers";
import NavLinkButton from "./components/NavLinkButton";
import {
  getNavigationMenusApi,
  getUserApi,
} from "./navigationApi";
import "./navigation.scss";



const NavigationToolTip = ({ labels, children, Label }) => (
  <>
      <Tooltip
        followCursor={true}
        content={labels[replace(Label, "_LABEL", "_TOOLTIP")]}
      >
        <div>{children}</div>
      </Tooltip>
  </>
);

const SubNav = ({ children }) => (
  <div className="sub-nav-wrapper">{children}</div>
);
class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationMenu: [],
      isNavigationFetching: false,
      IsFreelancer: props.user.IsFreelancer
    };
  }

  getNavigationMenus = () => {
    const {
      user: { IsFreelancer }
    } = this.props;
    const isVisitor = IsFreelancer === null;
    this.setState({ isNavigationFetching: true });
    const featureTypeId = IsFreelancer ? 2 : 1;
    getNavigationMenusApi({ featureTypeId: 6, appTypeId: 3 }).then((response) => {
      if (response.success) {
        var navigationMenu = map(response.items, (item, index) => {
          item.HelpIcon = false;
          item.testId = `navigation-item-${index + 1}`;
          item.SubNavigation = map(item.SubNavigation, (subNav, index) => {
            subNav.testId = `${item.testId}-sub-nav-item-${index + 1}`;
            return subNav;
          });
          return item;
        });
        navigationMenu = navigationMenu.filter(item=> item.Label);
        this.setState({
          navigationMenu,
          IsFreelancer,
          isNavigationFetching: false
        });
      }else {
        this.props.toggleLoadingAction(false);
        this.props.deleteUserAction();
        StorageService.removeUser();
        this.props.history.push(publicRoutes.default.path);
      }
    });
  };

  getCurrentUser = () => {
    this.setState({ isNavigationFetching: true });
    if (this.props.user.IsFreelancer) {
      getUserApi().then(response => {
        if (response.success) {
          this.getNavigationMenus();
        }
      });
    } else {
      this.getNavigationMenus();
    }
  };

  componentDidMount() {
    this.getCurrentUser();
  }

  handleNotificationsCount = nextProps => {
    let { navigationMenu } = this.state;
      this.setState({ navigationMenu });
  };

  toggleNavigation = nextProps => {
    let { navigationMenu } = this.state;
    if (window.innerWidth <= 1240 && nextProps.isNavShrink) {
      return;
    }
    if (
      nextProps.isNavShrink ||
      (window.innerWidth <= 1240 && !nextProps.isNavShrink)
    ) {
      navigationMenu = map(navigationMenu, item => {
        item.SubNavActive = false;
        return item;
      });
      this.setState({ navigationMenu });
    }
  };

  handleNavigationMenuActive = nextProps => {
    const { location, isNavShrink } = nextProps;
    let { navigationMenu } = this.state;
    navigationMenu = map(navigationMenu, menu => {
      if (menu.SubNavigation.length > 0) {
        const currentSubMenu = filter(
          menu.SubNavigation,
          subNav => subNav.Url === location.pathname
        );
        console.log({currentSubMenu})
        if (
          currentSubMenu.length > 0 &&
          currentSubMenu[0].ParentFeatureId === menu.Id
        ) {
          // for mobile devices selcted navigation will be selected and sub navigations will be shown
          if (isNavShrink && window.innerWidth <= 1240) {
            menu.SubNavActive = true;
            menu.isSubNavActive = true;
          } else if (
            // for mobile devices selcted navigation will be selected and sub navigations will not be shown
            // for desktop same behaviour will be implement but when navigation will be shrink
            isNavShrink ||
            (!isNavShrink && window.innerWidth <= 1240)
          ) {
            menu.SubNavActive = false;
            menu.isSubNavActive = true;
          } else {
            // for desktop selected navigation will be shown and active
            menu.SubNavActive = true;
            menu.isSubNavActive = true;
          }
        } else {
          //all sub menu will be deactive when click menu that dont have sub menu
          menu.isSubNavActive = false;
        }
      }
      return menu;
    });
    this.setState({ navigationMenu });
  };

  componentWillReceiveProps(nextProps) {
    this.handleNotificationsCount(nextProps);
    this.toggleNavigation(nextProps);
    this.handleNavigationMenuActive(nextProps);
  }

  handleForSubNavClick = selectedItem => {
    const { Name } = selectedItem;
    let { navigationMenu } = this.state;
    navigationMenu = map(navigationMenu, item => {
      if (item.Name === Name) {
        item.SubNavActive = !item.SubNavActive;
        return item;
      }
      return item;
    });
    this.props.onSubMenuOpen();
    this.props.onMobileNavShrink();
    this.setState({ navigationMenu });
  };


  handleSubMenuClick = ({ selectedMenuId, selectedSubMenuUrl }) => {
    this.setState(
      {
        selectedSubMenuUrl,
        selectedMenuId
      },
      () => {
        let { selectedSubMenuUrl, selectedMenuId, navigationMenu } = this.state;

        if (this.props.location.pathname == selectedSubMenuUrl) {
          navigationMenu = map(navigationMenu, item => {
            item.isSubNavActive = item.Id === selectedMenuId;
            return item;
          });
          this.props.onNavShrink();
          this.setState({ navigationMenu });
        }
      }
    );
  };

  handleMenuClick = () => {
    let { navigationMenu } = this.state;
    navigationMenu = map(navigationMenu, item => {
      item.isSubNavActive = false;
      return item;
    });
    this.props.onNavShrink();
    this.setState({ navigationMenu });
  };

  render() {
    const {
      isNavShrink,
      user: { IsFreelancer }
    } = this.props;
    const { navigationMenu, isNavigationFetching } = this.state;
    return (
      <div
        className={`navigation ${isNavShrink && "nav-shrink-container"}`}
        test-data-id={`${
          IsFreelancer ? "ipro-navigation-list" : "searcher-navigation-list"
        }`}
      >
        {isNavigationFetching ? (
          <NavigationSkeleton />
        ) : (
          <div>
            {map(navigationMenu, menu => {
              const {
                Id,
                Label,
                Url,
                CssClassIcon,
                CssClass,
                Name,
                IsActive,
                NotificationBadge,
                NotificationCount,
                SubNavActive,
                testId,
                SubNavigation,
                isSubNavActive,
                Disabled,
                toggleDisabled
              } = menu;
              if (Name === "NavHeader") {
                return (
                  IsActive && (
                    <Fragment key={Id}>
                      <NavLinkButton
                        className={CssClass}
                        classIcon={CssClassIcon}
                        to={Url}
                        testId={testId}
                        onClick={this.handleMenuClick}
                      >
                        {htmlParser(this.props.labels[Label])}
                      </NavLinkButton>
                    </Fragment>
                  )
                );
              }
              if (SubNavigation && !SubNavigation.length) {
                return (
                  IsActive && (
                    <Fragment key={Id}>
                      <NavigationToolTip
                        labels={this.props.labels}
                        Label={Label}
                        isHelpActive={false}
                      >
                        <NavLinkButton
                          disabled={Disabled}
                          className={CssClass}
                          classIcon={CssClassIcon}
                          to={Url}
                          testId={testId}
                          onClick={this.handleMenuClick}
                        >
                          {htmlParser(this.props.labels[Label])}
                          {NotificationBadge && NotificationCount > 0 && (
                            <NotificationCircle
                              testId={`${testId}-notification`}
                            >
                              {NotificationCount > 9 ? "9+" : NotificationCount}
                            </NotificationCircle>
                          )}
                        </NavLinkButton>
                      </NavigationToolTip>
                    </Fragment>
                  )
                );
              }

              if (SubNavigation && SubNavigation.length > 0) {
                return (
                  IsActive && (
                    <Fragment key={Id}>
                      <NavigationToolTip
                        labels={this.props.labels}
                        Label={Label}
                        isHelpActive={false}
                      >
                        <NavButton
                          disabled={Disabled}
                          onClick={() => this.handleForSubNavClick(menu)}
                          className={`arrowCls ${CssClass} ${
                            isSubNavActive ? "activeBtn" : ""
                          }`}
                          classIcon={CssClassIcon}
                          name={Label}
                          testId={testId}
                        >
                          {htmlParser(this.props.labels[Label])}
                          {NotificationBadge && NotificationCount > 0 && (
                            <NotificationCircle
                              testId={`${testId}-notification`}
                            >
                              {NotificationCount > 9 ? "9+" : NotificationCount}
                            </NotificationCircle>
                          )}
                        </NavButton>
                      </NavigationToolTip>
                      {SubNavActive && (
                        <SubNav>
                          {map(
                            SubNavigation,
                            subNav =>
                              subNav.IsActive && (
                                <NavigationToolTip
                                  key={subNav.Id}
                                  labels={this.props.labels}
                                  Label={subNav.Label}
                                  isHelpActive={false}
                                >
                                  <NavLinkButton
                                    className={subNav.CssClass}
                                    classIcon={subNav.CssClassIcon}
                                    to={subNav.Url}
                                    testId={subNav.testId}
                                    onClick={() =>
                                      this.handleSubMenuClick({
                                        selectedMenuId: menu.Id,
                                        selectedSubMenuUrl: subNav.Url
                                      })
                                    }
                                  >
                                    {htmlParser(
                                      this.props.labels[subNav.Label]
                                    )}
                                    {subNav.NotificationBadge &&
                                      subNav.NotificationCount > 0 && (
                                        <NotificationCircle
                                          testId={`${
                                            subNav.testId
                                          }-notification`}
                                        >
                                          {subNav.NotificationCount > 9
                                            ? "9+"
                                            : subNav.NotificationCount}
                                        </NotificationCircle>
                                      )}
                                  </NavLinkButton>
                                </NavigationToolTip>
                              )
                          )}
                        </SubNav>
                      )}
                    </Fragment>
                  )
                );
              }
            })}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  labels,
  user,
}) => {
  return {
    labels,
    user
  };
};

const actions = {
  setNotificationAction,
  deleteUserAction,
  toggleLoadingAction,
};
export default connect(
  mapStateToProps,
  actions
)(withRouter(Navigation));
