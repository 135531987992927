import React, { Component } from "react";
import { Column, Button, List } from "../../components";
import "./Email.scss";
import { getAllUsersApi } from "../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
import CKEditor from "ckeditor4-react";
import { DeleteEmail, GetEmailApi, SendEmailApi } from "./apiCalls";
import Select from "react-select";
const emailInitialValues = {
  Id: 0,
  Type: 1,
  Title: "",
  SubTitle: "",
  Url: "",
  ButtonText: "",
  Content: "",
  selectedType: { value: 1, label: "Single User" },
};

const types = [
  { value: 1, label: "Single User" },
  { value: 2, label: "All Users" },
  { value: 3, label: "All Searchers" },
  { value: 4, label: "All iPros" },
  { value: 5, label: "All iPros without a role in resume" },
];

class Email extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: true,
      SearchByText: "",
      emails: [],
      filteredEmails: [],
      selectedEmail: emailInitialValues,
    };
  }
  componentDidMount = () => {
    this.getAllUsers();
  };

  handleContentChange = (value) => {
    this.setState({
      selectedEmail: {
        ...this.state.selectedEmail,
        Content: value,
        // ContentValue: draftToHtml(convertToRaw(value.getCurrentContent()))
      },
    });
  };

  handleSubmitBtnClick = () => {
    let { selectedEmail } = this.state;
    const { selectedType, selectedUser } = selectedEmail;
    if (selectedType.value == 1 && !selectedUser) {
      const info = {
        message: "Please Select at least on user",
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }
    selectedEmail = {
      ...selectedEmail,
      Type: selectedEmail.selectedType.value,
      Button: selectedEmail.Url,
      Id: selectedType.value == 1 ? selectedUser.value : -1,
      ...(selectedType.value == 1 && { UserId: selectedUser.value }),
    };
    if (!selectedEmail.Title || !selectedEmail.Type) {
      const info = {
        message: "Please Enter the required fields",
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }
    SendEmailApi(selectedEmail).then((res) => {
      const info = {
        message: "Email sent successfully",
        status: "success",
      };
      this.props.setNotificationAction(info);
      return;
    });
  };
  ButtonChangePasswordClick = (item) => {
    this.setState({
      isCollapsed2: false,
      isUpdate: true,
    });
  };
  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    const { emails } = this.state;
    if (name == "SearchByText") {
      this.setState({
        [name]: value,
        filteredEmails:
          value == "" ? emails : emails.filter((a) => a.Title.includes(value)),
      });
    } else {
      this.setState({
        selectedEmail: {
          ...this.state.selectedEmail,
          [name]: value,
        },
      });
    }
  };
  handleListItemClick = (item) => {
    // let { contentBlocks, entityMap } = htmlToDraft(item.Content || "");
    // const { Content } = this.state.selectedEmail;
    // let contentState = Modifier.replaceWithFragment(
    //   Content.getCurrentContent(),
    //   Content.getSelection(),
    //   ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    // )

    this.setState({
      selectedEmail: {
        ...item,
        Content: "",
        selectedType: types.find((a) => a.value == item.Type),
        selectedUser:
          item.Type === 1
            ? this.state.allUsers.selectedEmail(
                (a) => a.UserId == item.SentUserId
              )
            : {},
      },
      emails: this.state.emails.map((a) => ({
        ...a,
        isActive: item.Id == a.Id,
        selectedUser:
          item.Type === 1
            ? this.state.allUsers.selectedEmail((a) => a.UserId == a.SentUserId)
            : {},
      })),
      filteredEmails: this.state.filteredEmails.map((a) => ({
        ...a,
        isActive: item.Id == a.Id,
        selectedUser:
          item.Type === 1
            ? this.state.allUsers.selectedEmail((a) => a.UserId == a.SentUserId)
            : {},
      })),
      isCollapsed2: false,
    });
  };
  handleDeleteListItem = (item) => {
    DeleteEmail(item.Id).then((res) => {
      if (res.success) {
        this.setState({
          filteredEmails: this.state.filteredEmails.filter(
            (a) => a.Id != item.Id
          ),
          emails: this.state.emails.filter((a) => a.Id != item.Id),
        });
      }
    });
  };
  getAllUsers = () => {
    getAllUsersApi().then((res) => {
      if (res.success) {
        const allUsers = res.items.map((item) => {
          const newItem = {
            value: item.UserId,
            label: item.Name,
          };
          return newItem;
        });
        this.setState({
          allUsers,
        });
        GetEmailApi().then((res) => {
          this.setState({
            emails: res.items,
            filteredEmails: res.items,
          });
        });
        this.onFormSelectChange("selectedUser", allUsers[0]);
      }
    });
  };
  onFormSelectChange = (type, selectedOption) => {
    this.setState({
      selectedEmail: {
        ...this.state.selectedEmail,
        [type]: selectedOption,
      },
    });
  };
  onEditorChange = (evt) => {
    this.setState({
      selectedEmail: {
        ...this.state.selectedEmail,
        Content: evt.editor.getData(),
      },
    });
  };

  handleChange = (changeEvent) => {
    this.setState({
      selectedEmail: {
        ...this.state.selectedEmail,
        Content: changeEvent.target.value,
      },
    });
  };
  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      oldPassword,
      newPassword,
      confirmPassword,
      SearchByText,
      filteredEmails,
      selectedEmail,
      allUsers,
    } = this.state;
    const {
      Type,
      Title,
      SubTitle,
      Url,
      ButtonText,
      Content,
      selectedType,
      selectedUser,
    } = selectedEmail;
    console.log({ state: this.state });
    return (
      <div className="email-module columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={"Emails"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Emails"}</p>
            </div>
            <Button
              className="button-primary AddBtn"
              testId={"open_correlation_form-btn"}
              tooltipButton={labels.ADMIN_OPEN_LOOKUP_CORRELATION_FORM}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              onClick={() =>
                this.setState({
                  selectedEmail: emailInitialValues,
                  isCollapsed2: false,
                })
              }
            />
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="email-list-wrapper">
              <div className={"input-field-custom search-input"}>
                <h6>{labels.ADMIN_SEACRH_BY_TEXT_LABEL}</h6>
                <div>
                  <input
                    test-data-id={"input-search-by-text"}
                    name={"SearchByText"}
                    type={"text"}
                    value={SearchByText}
                    onChange={this.handleFormFieldChange}
                  />
                </div>
              </div>
              {filteredEmails && filteredEmails.length > 0 ? (
                <div className="list-wrapper">
                  {this.state.filteredEmails &&
                    this.state.filteredEmails.map((item) => (
                      <div
                        test-data-id={`workplace-list-item-${item.Title.replace(
                          " ",
                          "-"
                        ).toLowerCase()}`}
                        className={`list-item ${item.isActive ? "active" : ""}`}
                        key={item.Id}
                        onClick={() => this.handleListItemClick(item)}
                      >
                        <div className="list_item_title">
                          <span>{item.Title}</span>
                        </div>
                        <button
                          test-data-id={`delete-${item.Title}`}
                          type="button"
                          className="delete-btn"
                          onClick={() => this.handleDeleteListItem(item)}
                          id={item.Id}
                          name={item.Title}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </div>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-1">
          <Column.Collapsed
            text={"Email Detail"}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Email Detail"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-save-form">
              <div className="input-select-field setHeight50p">
                <h6>{"To"}</h6>
                <Select
                  name="selectedType"
                  className={`select-input `}
                  placeholder={"Select email type"}
                  value={(selectedType && selectedType) || ""}
                  onChange={(selectedOption) =>
                    this.onFormSelectChange("selectedType", selectedOption)
                  }
                  clearable={false}
                  searchable={false}
                  options={types}
                />
              </div>
              {selectedType.value == 1 && (
                <div className="input-select-field setHeight50p">
                  <h6>{"Select a user"}</h6>
                  <Select
                    name="selectedType"
                    className={`select-input select-fix`}
                    placeholder={"User"}
                    value={(selectedUser && selectedUser) || ""}
                    onChange={(selectedOption) =>
                      this.onFormSelectChange("selectedUser", selectedOption)
                    }
                    clearable={false}
                    options={allUsers}
                  />
                </div>
              )}
              <div className={`input-field`}>
                <h6>{"Title"}</h6>
                <div>
                  <input
                    name={"Title"}
                    type={"text"}
                    value={Title}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-identifier"}
                  />
                </div>
              </div>
              <div className={`input-field`}>
                <h6>{"Sub Title"}</h6>
                <div>
                  <input
                    name={"SubTitle"}
                    type={"text"}
                    value={SubTitle}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-text"}
                  />
                </div>
              </div>
              <div className={`input-field`}>
                <h6>{"Button Url Hash"}</h6>
                <div>
                  <input
                    name={"Url"}
                    type={"text"}
                    value={Url}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-text"}
                  />
                </div>
              </div>
              <div className={`input-field`}>
                <h6>{"Button Text"}</h6>
                <div>
                  <input
                    name={"ButtonText"}
                    type={"text"}
                    value={ButtonText}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-text"}
                  />
                </div>
              </div>
              <CKEditor data={Content} onChange={this.onEditorChange} />
            </div>
            <button
              test-data-id={"btn-logout"}
              className="btn btn-login btn-SubmitPass"
              onClick={this.handleSubmitBtnClick}
            >
              {"Send Email"}
            </button>
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(Email));
