import React from 'react';
import { DatePicker, Select, DragImage } from '../../../components';

class ProductForm extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        const{
            handleDateChange,
            onFormFieldChange,
            onFormSelectChange,
            productsData,
            productTypes,
            saveProduct,
            labels,
            getImgSrc
        } = this.props;
        const{
            Description,
            Logo,
            Name,
            selectedType,
            Price,
        } = productsData;
        return(
            <div className="bu-save-form">
                
                <div className={`${!Name  ? "inValid " : " "} input-field `} >
                    <h6>{labels.ADMIN_NAME_LABEL}</h6>
                    <div>
                    <input
                        name={"Name"}
                        type={"text"}
                        value={Name}
                        onChange={e => onFormFieldChange(e)}
                        test-data-id={"input-label-identifier"}
                        onBlur={()=>saveProduct()}
                    />
                    </div>
                </div>
                <div className={`${selectedType && selectedType.Id  ? "" : "inValid"} input-select-field `}>
                    <h6>{labels.ADMIN_TYPE_LABEL}</h6>
                    <Select
                         name="types"
                         className={`select-input select-role`}
                         placeholder={"Please select a type"}
                         value={selectedType && selectedType || ""}
                         onChange={selectedOption =>
                             onFormSelectChange("types", selectedOption)
                         }
                         clearable={false}
                         searchable={false}
                         options={productTypes}
                         onBlur={()=>saveProduct()}
                    />
                </div>
                
                <div className={`input-field `} >
                    <h6>{labels.ADMIN_PRICE_LABEL}</h6>
                    <div>
                    <input
                        name={"Price"}
                        type={"text"}
                        value={Price}
                        onChange={e => onFormFieldChange(e)}
                        test-data-id={"input-field-email"}
                    />
                    </div>
                </div>
                <DragImage getImgSrc={getImgSrc} imgSrc={Logo} labels={labels} />
                <div className="input-field-textarea">
                    <h6>{labels.ADMIN_DESCRIPTION_LABEL}</h6>
                    <textarea
                        name="Description"
                        rows={5}
                        type="text"
                        value={!Description ? "" : Description} 
                        onChange={e => onFormFieldChange(e)}
                        onBlur={()=>saveProduct()}
                        test-data-id={'workplace-comments'}
                    />
                </div>
            </div>
        );
    }
}

export default ProductForm;