import { createStore } from 'redux';
import reducer from './reducers';


const initialState ={
    user:{
        IsAuthenticated:false
    },
    notification:{
        message:"",
        status:""
    },
    isLoading:false,
    labels:[]
}

const store=createStore(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;