import React from "react";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import { CustomInput } from "../../../components";
import { List } from "../../../components";

class SystemLabelsExceltTable extends React.Component {
  constructor(props) {
    super(props);
  }

  renderTableData = () => {
    const { csvData } = this.props;
    if (csvData) {
      return csvData.map((item, index) => {
        const { Module, Identifier, Message } = item; //destructuring
        return (
          <tr key={index}>
            <td>{Module}</td>
            <td>
              <span>{Identifier}</span>
            </td>
            <td>
              <div
                className="input-field"
                test-data-id={"excel-model-list-item"}
              >
                <input
                  name={"value"}
                  type={"text"}
                  value={Message}
                  onChange={(e) =>
                    this.props.ExcelTableOnChange(e, { param: item })
                  }
                  // onBlur={e => this.props.onCorrelationValueBlur(e, item )}
                  test-data-id={"SearchByText"}
                  // autoFocus={isEnable}
                  className="excelimportTable"
                />
              </div>

              {/* <button  test-data-id={`delete-btn`} type="button" className="delete-btn" onClick={()=>this.props.handleDeleteCorrelation(item)} /> */}
            </td>
          </tr>
        );
      });
    } else {
      return "";
    }
  };
  renderTableHeader = () => {
    let header = ["Module", "Identifier", "Message"];
    return header.map((key, index) => {
      return <th key={index}>{key}</th>;
    });
  };

  render() {
    const {
      lookupTolookupSelectChange,
      LookupTypeList,
      onFormFieldChange,
      SearchByText,
      SearchByTextValue,
      lookupCorrelationDropDownSelected,
      selectedLookupToLookupTitle,
      selectedLookupToLookup,
      csvData,
      labels,
      ExcelTableOnChange,
    } = this.props;
    return (
      <div
        className="system_labels_list"
        test-data-id={"systemlabels-list-item"}
      >
        {csvData && csvData.length > 0 ? (
          <div>
            {csvData.map((item, i) => {
              return (
                <div
                  className="input-field"
                  test-data-id={"systemlabels-list-item"}
                  key={i}
                >
                  <h6>{item.Identifier}</h6>
                  <div className="labelsListItem">
                    <input
                      name="systemLabels"
                      type="text"
                      value={item.Message}
                      test-data-id={"systemlabels-list-input"}
                      onChange={(e) => ExcelTableOnChange(e, { param: item })}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-item-label">
            <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
          </div>
        )}
      </div>
    );
  }
}

export default SystemLabelsExceltTable;
