import React from 'react';
import { DatePicker, Select } from '../../../components';
import { CustomInputNew } from "./CustomInput";
import moment from "moment";

class BusinessUserForm extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        const{
            handleDateChange,
            onFormFieldChange,
            demoUserData,
            labels,
            handleBlur
        } = this.props;
        const{
            CreatedDate,
            ExpiryDate,
            Password,
            UserEmail,
            UserName,
        } = demoUserData;
        return(
            <div className="bu-save-form">
                
                <div className={`${!UserName  ? "inValid " : " "} input-field `} >
                    <h6>{labels.USER_NAME_LABEL}</h6>
                    <div>
                    <input
                        name={"UserName"}
                        type={"text"}
                        value={UserName}
                        onChange={e => onFormFieldChange(e)}
                        onBlur={() => handleBlur()}
                        test-data-id={"input-label-identifier"}
                    />
                    </div>
                </div>

                <div className={`${!UserEmail  ? "inValid " : " "} input-field `} >
                    <h6>{labels.EMAIL_LABEL}</h6>
                    <div>
                    <input
                        name={"UserEmail"}
                        type={"text"}
                        value={UserEmail}
                        onBlur={() => handleBlur()}
                        onChange={e => onFormFieldChange(e)}
                        test-data-id={"input-label-text"}
                    />
                    </div>
                </div>
                
                <div className={`${!Password  ? "inValid " : " "} input-field `} >
                    <h6>{labels.ADMIN_PASSWORD_LABEL}</h6>
                    <div>
                    <input
                        name={"Password"}
                        type={"text"}
                        value={Password}
                        onBlur={() => handleBlur()}
                        onChange={e => onFormFieldChange(e)}
                        test-data-id={"input-field-email"}
                    />
                    </div>
                </div>
                <div className="custom-row">
                 <CustomInputNew label={labels.ADMIN_EXPIRY_DATE_LABEL} >
                    <label className="datepicker-wrapper">
                        <DatePicker
                        selected={moment(ExpiryDate)||"" }
                        onChange={handleDateChange}
                        name="startDate"
                        dateFormat="MM/dd/yyyy"
                        disabled={false}
                        />
                    </label>
                </CustomInputNew>
                </div>
            </div>
        );
    }
}

export default BusinessUserForm;