import React, { Component } from "react";
import "./column.scss";
import Button from "../Button/Button";
import { isMobile } from "react-device-detect";
import { Tooltip } from "../Tooltip/Tooltip";
const Head = ({ children, className }) => (
  <div className={`column-head ${className}`}>{children}</div>
);

const HeaderTitle = ({ isActive, children, testId }) => (
  <div className="heading" test-data-id={testId}>
    {isActive && children}
  </div>
);

const Body = ({ children, className }) => (
  <div className={`column-body ${className}`}>{children}</div>
);
const Collapsed = ({
  onClick,
  text,
  tooltipButton,
  children,
  isHelpActive,
  tooltipHelp,
  tooltipPlace,
  testId,
  testIdHelp
}) => (
    <div className={`column-collapsed`}>
      <Button
        onClick={onClick}
        tooltipButton={tooltipButton}
        className="collapseBtn expandBtn"
        isHelpActive={isHelpActive}
        tooltipHelp={tooltipHelp}
        tooltipPlace={tooltipPlace}
        testId={testId}
        testIdHelp={testIdHelp}
      />

      {isHelpActive ? (
        <div className="expand-heading" onClick={onClick}>
          {text}
        </div>
      ) : (
          <Tooltip
            content={tooltipButton}
            trigger={!isMobile ? "mouseenter" : "focus"}
            followCursor
          >
            <div className="expand-heading" onClick={onClick}>
              {text}
            </div>
          </Tooltip>
        )}

      {children}
    </div>
  );
class Column extends Component {
  static Head = Head;
  static Body = Body;
  static Collapsed = Collapsed;
  static HeaderTitle = HeaderTitle;

  render() {
    const { className, children, collapse, tooltip, testId } = this.props;
    return (
      <div
        test-data-id={testId}
        className={`page-column ${className} ${
          collapse ? "page-column-collapse" : ""
          }`}
      >
        {children}
      </div>
    );
  }
}

Column.defaultProps = {
  className: ""
};
Column.Head.defaultProps = {
  className: ""
};
Column.Body.defaultProps = {
  className: ""
};
export default Column;
