import React,{Component} from 'react';
import {Column,Button,List, CustomInput} from "../../components";
import moment from "moment";
import "./businessUsers.scss";
import {  getDemoUsers,getRoles, UpdateDemoUser, GetAllAdmins, RegisterDemoUser, UpdateAdmin, DeleteDemoUser } from '../../Services/apiCalls';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import BusinessUserForm from './components/BusinessUserForm.jsx';
import {setNotificationAction,toggleLoadingAction } from '../../Redux/actions';

class BusinessUsers extends Component{
    constructor(props){
        super(props);
        this.state={
            isCollapsed1:false,
            isCollapsed2:true,
            isCollapsed3:true,
            isUpdate:false,
            demoUserData:{
                CreatedDate: new Date(),
                ExpiryDate: new Date(),
                Password: "",
                UserEmail: "",
                UserName: "",
                DemoUserId: 0,
            },
            filteredDemoUsers:[],
            DemoUsers:[],
        }
    }
    componentDidMount=()=>{
        this.GetDemoUsers();
    } 

    GetDemoUsers = ()=>{
        getDemoUsers()
        .then(res=>{
            this.setState({
                DemoUsers: res.items,
                filteredDemoUsers:res.items
            })
        })
    }


    SaveDemoUser = ()=>{
        const {demoUserData, isUpdate} = this.state;
        const { CreatedDate,ExpiryDate,Password, UserEmail,UserName,DemoUserId} = demoUserData;
        if(!UserName){
            const info = {
                message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
                status: "error"
                };
                this.props.setNotificationAction(info);
                return null;
        }
        if(!UserEmail){
            const info = {
                message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
                status: "error"
                };
                this.props.setNotificationAction(info);
                return null;
        }
        if(!Password){
            const info = {
                message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
                status: "error"
                };
                this.props.setNotificationAction(info);
                return null;
        }
        this.props.toggleLoadingAction(true);
        if(isUpdate){
            var model = {
                CreatedDate: moment(CreatedDate).format(),
                ExpiryDate:  moment(ExpiryDate).format(),
                Password: Password, 
                UserEmail: UserEmail,
                UserName: UserName,
                DemoUserId: this.state.adminData.DemoUserId
            };
            UpdateDemoUser(model)
            .then(res=>{
               if(res.success){
                this.GetDemoUsers();
                   const info = {
                    message: this.props.labels.ADMIN_DEMO_USER_UPDATE_SUCCESS,
                    status: "success"
                    };
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }
            })
        }else{
            var model = {
                CreatedDate: moment(CreatedDate).format(),
                ExpiryDate:  moment(ExpiryDate).format(),
                Password: Password, 
                UserEmail: UserEmail,
                UserName: UserName
            };
            RegisterDemoUser(model)
            .then(res=>{
               if(res.success){
                   this.GetDemoUsers();
                   const info = {
                    message: this.props.labels.ADMIN_DEMO_USER_REGISTER_SUCCESS,
                    status: "success"
                    };
                    this.setState({
                        isUpdate: true,
                        adminData: {
                            ...this.state.adminData,
                            DemoUserId: res.items.DemoUserId
                        }
                    })
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }
            })
        }
        
    }
    DeleteUser = (selectedItem)=>{
        this.props.toggleLoadingAction(true);
        DeleteDemoUser(selectedItem)
        .then(res=>{
            if(res.success){
                this.GetDemoUsers();
                const info = {
                    message: this.props.labels.ADMIN_DEMO_USER_DELETE_SUCCESS,
                    status: "success"
                    };
                this.props.toggleLoadingAction(false);
                this.props.setNotificationAction(info);
            }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                this.props.toggleLoadingAction(false);
                this.props.setNotificationAction(info);
            }
        })
    }

    searchFieldChange = e =>{
        const{
          name,
          value,
          type
        } = e.target;
        var data = this.state.DemoUsers;
        const demoUsersFilter = data.filter(item=> item.UserName.toLowerCase().includes(value.toLowerCase()));
       
        this.setState({
          [name]:value,
          filteredDemoUsers: demoUsersFilter
        });
      };

      onFormFieldChange = e =>{
          const {demoUserData}= this.state;
        const{
          name,
          value,
          type
        } = e.target;      
        this.setState({
            demoUserData:{
                ...demoUserData,
                [name]:value,
            },
          
        });
      };
      handleDateChange=(date)=> {
        const {demoUserData}= this.state;
        
        this.setState({
          demoUserData:{
            ...demoUserData,
            ExpiryDate:date,
        },
        })
      }
      saveAction = (date) =>{
        this.handleDateChange=(date);
        this.SaveDemoUser();
      }
      handleBlur = (date) =>{
        this.SaveDemoUser();
      }

      ListItemClick=(item)=>{
        const {demoUserData} = this.state;
        this.setState({
            isCollapsed2:false,
            isUpdate: true,
            demoUserData: {
                ...demoUserData,
                ExpiryDate: new Date(item.ExpiryDate),
                Password: item.Password,
                UserEmail: item.UserEmail,
                UserName: item.UserName,
                DemoUserId: item.DemoUserId,
                CreatedDate: new Date(item.CreatedDate)
            },     
        })
      }
      handleAddNew = () => {
        this.setState({ 
            isCollapsed2: false,
            isUpdate: false,
            demoUserData: {
                ExpiryDate: new Date(),
                Password: "",
                UserEmail: "",
                UserName: "",
                DemoUserId: "",
                CreatedDate: new Date()
            },  
      });
    }

      onFormSelectChange = (type,selectedOption) =>{
        const { adminData} = this.state;
        if(type == "Roles"){
            this.setState({
                adminData:{
                    ...adminData,
                    selectedRole: selectedOption,
                }
            })
        }
        
    }
    render(){
        const {labels}= this.props;
        const{
            isHelpActive,
            isCollapsed1,
            isCollapsed2,
            filteredDemoUsers,
            Roles,
            demoUsersSearchText,
        } = this.state;
        return(
            <div className="business-users-wraper columns-wrapper">
             <Column collapse={isCollapsed1} className="col-1">
                <Column.Collapsed
                    text={labels.ADMIN_BUSINESS_USER_LABEL}
                    onClick={() =>
                    this.setState({ isCollapsed1: false })
                    }
                    tooltipButton={labels.ADMIN_EXPAND}
                    isHelpActive={isHelpActive}
                    tooltipHelp={labels.ADMIN_EXPAND_BTN}
                    tooltipPlace="left"
                    testId={'column-1-expand'}
                />
                <Column.Head>
                    <div className="heading">
                        <p>
                            {labels.ADMIN_BUSINESS_USER_LABEL}
                        </p>
                    </div>
                    <Button
                        testId={"open-system-label-form-btn"}
                        className="button-primary AddBtn"
                        tooltipButton={labels.ADMIN_OPEN_SYSTEM_LABELS_FORM}
                        tooltipHelp={labels.ADMIN_HELP_OPEN_SYSTEM_LABELS_FORM}
                        onClick={() => this.handleAddNew()}
                        />
                    <Button
                    onClick={() =>
                        this.setState({ isCollapsed1: true })
                    }
                    className="collapseBtn"
                    tooltipButton={labels.ADMIN_COLLAPSE}
                    tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                    testId={'column-1-collapse'}
                    />
                </Column.Head>
                <Column.Body>
                    <div className="admin-management-role-col1">
                        <div className={`${!demoUsersSearchText  ? "inValid " : " "} input-field`}>
                            <h6>{labels.BUSINESS_USER_SEARCH_LABEL}</h6>
                            <div>
                                <input
                                    name={"demoUsersSearchText"}
                                    type={"text"}
                                    value={demoUsersSearchText||""}
                                    onChange={e => this.searchFieldChange(e)}
                                    test-data-id={"admin-management-role-input"}
                                    placeholder={labels.BUSINESS_USER_SEARCH_LABEL}
                                />
                            </div>
                            
                        </div>
                        {filteredDemoUsers && filteredDemoUsers.length > 0 ?
                            <div className="list-wrapper">                
                                    {filteredDemoUsers.map(item=>
                                        <div test-data-id={`roles-list-item`} 
                                        className={`list-item`} 
                                        key={item.DemoUserId}
                                        onClick = {() =>this.ListItemClick(item)}
                                        >
                                            <div
                                                className="list_item_title"> 
                                                <span>{item.UserName}</span>
                                            </div>
                                            <button  test-data-id={`delete-role-btn`} type="button" className="delete-btn" onClick={()=>this.DeleteUser(item)} />
                                        </div>
                                    )}                        
                            </div>
                            :
                            <div className ="no-item-label"><label >
                                {labels.ADMIN_NO_ITEM_TO_DISPLAY}
                            </label>
                        </div>} 
                    </div>
                </Column.Body>
             </Column>

             <Column collapse={isCollapsed2} className="col-1">
                <Column.Collapsed
                    text={labels.BUSINESS_USER_SAVE_LABEL}
                    onClick={() =>
                    this.setState({ isCollapsed2: false })
                    }
                    tooltipButton={labels.ADMIN_EXPAND}
                    isHelpActive={isHelpActive}
                    tooltipHelp={labels.ADMIN_EXPAND_BTN}
                    tooltipPlace="left"
                    testId={'column-2-expand'}
                />
                <Column.Head>
                    <div className="heading">
                        <p>
                            {labels.BUSINESS_USER_SAVE_LABEL}
                        </p>
                    </div>
                    <Button
                    onClick={() =>
                        this.setState({ isCollapsed2: true })
                    }
                    className="collapseBtn"
                    tooltipButton={labels.ADMIN_COLLAPSE}
                    tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                    testId={'column-1-collapse'}
                    />
                </Column.Head>
                <Column.Body>
                <BusinessUserForm 
                    Roles = {Roles}
                    labels={this.props.labels}
                    demoUserData={this.state.demoUserData}
                    isUpdate={this.state.isUpdate}
                    onFormFieldChange={this.onFormFieldChange}
                    adminRegister = {this.SaveAdmin}
                    handleDateChange = {this.saveAction}
                    handleBlur = {this.handleBlur}
                    /> 
                </Column.Body>
             </Column>
             
        </div>
        )
    }
}
function mapStateToProps(state){
    return{
      user:state.user,
      isLoading:state.isLoading,
      labels: state.labels
    };
  }
  
  const actions ={
    setNotificationAction,
    toggleLoadingAction
  }
  
export default connect(mapStateToProps,actions)(withRouter(BusinessUsers));