import axios from "axios";
import { ApiUrls as ApiUrl } from "../../Services/apiUrls";

export const getNavigationMenusApi = ({ appTypeId, featureTypeId }) => {
  return axios
    .get(ApiUrl.SideNavigation.GetUserFeatures({ appTypeId, featureTypeId }))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getUserApi = () => {
  return axios.get(ApiUrl.User.GetUser).then(({ data }) => data);
};
