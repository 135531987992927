import { ApiUrls } from "../../../Services/apiUrls";
import axios from "axios";

export const getLanguages = () => {
  return axios
    .get(ApiUrls.Admin.getLanguages)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSubmoduleBymoduleId = (pid) => {
  return axios
    .get(ApiUrls.SystemLabel.GetSubmoduleBymoduleId(pid))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateSystemLabel = (item) => {
  return axios
    .put(ApiUrls.SystemLabel.updateSystemLabel, item)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const searchSystemLabel = (sm, items) => {
  return axios
    .post(ApiUrls.SystemLabel.searchSystemLabel(sm), items)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getCategories = () => {
  return axios
    .get(ApiUrls.SystemLabel.getCategories)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getUserTypes = () => {
  return axios
    .get(ApiUrls.SystemLabel.getUserTypes)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const saveSystemlabel = (data) => {
  return axios
    .post(ApiUrls.SystemLabel.saveSystemlabel, data)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const GetSystemLabelByLanguageId = (id) => {
  return axios
    .get(ApiUrls.SystemLabel.GetSystemLabelByLanguageId(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const AddSystemLabelsFromExcel = (data) => {
  return axios
    .post(ApiUrls.SystemLabel.AddSystemLabelsFromExcel, data)
    .then(({ data }) => data)
    .catch((response) => response);
};
