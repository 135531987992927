import axios from 'axios';
import {StorageService} from "./storage";
const BASE_URL = "/webapi/api/";
export class auth {
    setDefaultAuthHeaders = () => {
        axios.defaults.headers.common[".ASPXAUTH"] = StorageService.getToken();
    };
    setBaseUrl=()=>{
        axios.defaults.baseURL = BASE_URL;
    }
    getToken=()=>{
        return StorageService.getToken();
    }
}