import React, { Component } from "react";
import "./login-form.scss";
import axios from "axios";
import qs from "querystring";
import { ApiUrls } from "../../Services/apiUrls";
import { validateInputField } from "../../utilities/validator";
import { StorageService } from "../../Services/storage";
import { initialConfig } from "../../Redux/initialConfig";
import { privateRoutes } from "../../Routes/Routes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
import { isValidEmail } from "../../utilities/validations";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Email: "",
      Password: "",
    };
  }
  handleFormFieldChange = (e) => {
    const { name, value, type } = e.target;
    this.setState({
      [name]: value,
      [`isValid${name}`]: !!value,
    });
  };
  handleFormFieldBlur = (e) => {
    const { name, value, type } = e.target;
    this.setState({
      [name]: value,
      [`isValid${name}`]: validateInputField(value, type),
    });
  };
  handleFormSubmit = () => {
    const { Email, Password } = this.state;
    if (!Email || !Password) {
      this.setState({
        isValidEmail: !!isValidEmail(Email),
        isValidPassword: !!Password,
      });
      const info = {
        message: "Please fix the validation errors",
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }
    const loginObject = {
      Email,
      Password,
    };
    this.props.toggleLoadingAction(true);
    axios
      .post(ApiUrls.Account.LOGIN, qs.stringify(loginObject))
      .then((res) => {
        if (res.data.success) {
          const token = res.headers[".aspxauth"];
          const user = {
            UserId: res.data.items.UserId,
            FirstName: res.data.items.FirstName,
            Token: token,
            IsAuthenticated: true,
          };
          StorageService.saveUser({ user });
          if (initialConfig()) {
            this.props.toggleLoadingAction(false);
            this.props.history.push(privateRoutes.SystemLabels.path);
          }
        } else {
          const info = {
            message: res.data.message,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => {
        const info = {
          message: err,
          status: "error",
        };
        this.props.toggleLoadingAction(false);
        this.props.setNotificationAction(info);
        return;
      });
  };
  handleKeyPress = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      //13 is the enter keycode
      this.handleFormSubmit();
    }
  };
  render() {
    const { isValidEmail = true, isValidPassword = true } = this.state;
    const { onFormClose, onForgotPassword } = this.props;
    return (
      <div className="login-form">
        <input
          type="email"
          name="Email"
          className={`${isValidEmail ? "" : "inValid"}`}
          placeholder={"Email"}
          onChange={this.handleFormFieldChange}
          onBlur={this.handleFormFieldBlur}
          test-data-id={"login-email"}
          onKeyPress={this.handleKeyPress}
        />
        <input
          type="password"
          name="Password"
          className={`${isValidPassword ? "" : "inValid"}`}
          placeholder={"Password"}
          onChange={this.handleFormFieldChange}
          onBlur={this.handleFormFieldBlur}
          test-data-id={"login-password"}
          onKeyPress={this.handleKeyPress}
        />
        <button
          className="login-btn"
          onClick={this.handleFormSubmit}
          test-data-id={"login-submit"}
        >
          Login
        </button>
        <button
          className="login-forgot"
          test-data-id={"login-submit"}
          onClick={onForgotPassword}
        >
          Forgot your password?
        </button>
      </div>
    );
  }
}
const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(null, actions)(withRouter(Login));
