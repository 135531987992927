
export const SALES={
    WORKPLACES:"Workplaces",
    WORKPLACE_DETAILS:"Workplaces details",
    WORKPLACE_CONTACTS:"Workplaces contacts",
    WORKPLACE_CONTACT_DETAIL:"Workplaces contact details",
    SUBSCRIPTIONSL:"Subscriptions",
    CONFIGURATIONS:"Configurations",
    BILLING_AND_COMMISSION_HISTORY:"Billing and Commission History",
    SEARCH_FOR_WORKPLACES:"Search for workplaces"        
};
export const COMMON={
    COLLAPSE:"Collapse",
    EXPAND:"Expand",
    EXPAND_HELP:"Click this button to expand the section",
    COLLAPSE_HELP:"Click this button to collapse the section"
}
