import React, { Component } from "react";
import moment from "moment";

import { Column, Button, DatePicker, Select } from "../../../components";
import "../styles.scss";
import { getAdminEvents, GetAllAdmins, getAdminEventsByUserId } from "../../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CustomInputNew } from "./components/CustomInput";
import  TableModel  from "./components/TableModel";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../../Redux/actions";

class AdminActivityManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: false,
      isCollapsed3: true,
      Admins:[],
      selectedAdmin:"",
      Events: [],
      fromDate: new Date(new Date().setDate(new Date().getDate()-1)),
      toDate: Date.now(),
    };
  }
  componentDidMount = () => {
    const {fromDate,toDate}=this.state;
    this.GetAllAdmins();
    this.getEvents(moment(fromDate).format('YYYY-MM-DD'),moment(toDate).format('YYYY-MM-DD'));
    
  };
  GetAllAdmins = () => {
    GetAllAdmins().then((res) => {
      const newList = res.items.map((item) => ({
        ...item,
        value: item.UserId,
        label: item.UserEmail,
      }));
      newList.unshift({ value: -1, label: "--All--" });
      this.setState({
        Admins: newList,
        selectedAdmin:newList[0]
    });
  })
  };
  handleToDateChange = (date) => {
    this.setState({
      toDate: date,
    });
  };
  handleFromDateChange = (date) => {
    this.setState({
      fromDate: date,
    });
  };
  getEvents = (sDate,eDate) => {
    this.props.toggleLoadingAction(true);
    getAdminEvents(moment(sDate).format('YYYY-MM-DD'),moment(eDate).format('YYYY-MM-DD')).then((res) => {
      if(res.success){
        const Events = res.items.map((item) => {
          return {
            ...item,
            UserFirstname: item.User.DisplayName,
            UserEmail: item.User.UserEmail,
            EntityName: item.EntityName,
            Operation: item.Operation==0?"Add" :item.Operation==1?"Update":"Delete",
            executedQuery: item.executedQuery,
            CreatedDate: item.CreatedDate,
          };
        });
        this.setState({
          Events
        });
        this.props.toggleLoadingAction(false);
      }else{this.props.toggleLoadingAction(false);}
      
    });
  };
  getEventsByUserId = (uId,sDate,eDate) => {
    this.props.toggleLoadingAction(true);
    getAdminEventsByUserId(uId,moment(sDate).format('YYYY-MM-DD'),moment(eDate).format('YYYY-MM-DD')).then((res) => {
      if(res.success){
        const Events = res.items.map((item) => {
          return {
            ...item,
            UserFirstname: item.User.DisplayName,
            UserEmail: item.User.UserEmail,
            EntityName: item.EntityName,
            Operation: item.Operation=1?"Add" :"Update",
            executedQuery: item.executedQuery,
            CreatedDate: item.CreatedDate,
          };
        });
        this.setState({
          Events
        });
        this.props.toggleLoadingAction(false);
      }else{this.props.toggleLoadingAction(false);}
      
    });
  };
  handleSearchByDate = ()=>{
    const {selectedAdmin}=this.state;
    if(selectedAdmin.value==-1){
      this.getEvents(this.state.fromDate, this .state.toDate)
    }else{
      this.getEventsByUserId(selectedAdmin.value,this.state.fromDate, this .state.toDate)
    }
    
  }

  onFormFieldChange = (e) => {
    const { name, value, type } = e.target;
    var data = this.state.Roles;
    const rolesFilter = data.filter((item) =>
      item.RoleName.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      [name]: value,
      filteredRoles: rolesFilter,
    });
  };

  onFormSelectChange = (type, selectedOption) => {
    const { selectedAdmin } = this.state;
      this.setState({
        selectedAdmin: selectedOption
      });
  };

  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      Events,
      fromDate,
      toDate,
      Admins,
      selectedAdmin
    } = this.state;
    return (
      <div className="admin-management-role-wraper columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_ROLE_MANAGEMENT_LABEL}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_ROLE_MANAGEMENT_LABEL}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-management-role-col1">
              <div className="fields-row">
                <CustomInputNew label={"User"}>
                  <label className="datepicker-wrapper">
                  <Select
                    name="selectedAdmin"
                    className={`select-input`}
                    placeholder={"Please select Admin"}
                    value={(selectedAdmin && selectedAdmin) || ""}
                    onChange={(selectedOption) =>
                      this.onFormSelectChange("admin", selectedOption, 1)
                    }
                    options={Admins}
                  />
                  </label>
                </CustomInputNew>
                <CustomInputNew label={"From"}>
                  <label className="datepicker-wrapper">
                  <DatePicker
                    selected={fromDate && moment(fromDate)}
                    name="fromDate"
                    dateFormat="DD-MM-YYYY"
                    placeholderText={"DD-MM-YYYY"}
                    onChange={this.handleFromDateChange}
                  />
                  </label>
                </CustomInputNew>
                <CustomInputNew label={"To"}>
                  <label className="datepicker-wrapper">
                  <DatePicker
                    name="toDate"
                    dateFormat="DD-MM-YYYY"
                    selected={toDate && moment(toDate)}
                    onChange={this.handleToDateChange}
                    todayButton="Today"
                    placeholderText={"DD-MM-YYYY"}
                  />
                  </label>
                </CustomInputNew>
                <button test-data-id={"btn-myAccount"}
                  className="btn btn-search"
                  onClick={this.handleSearchByDate}
                  >
                    {"Search"}
                </button>
              </div>
              {Events.length > 0 ? (
               <TableModel 
                primaryKeyField={"Id"} 
                data={Events && Events }
                listItemToDisplay={[
                  {name:"User's Name",valueField:"UserFirstname"}, 
                  {name:"User's Email",valueField:"UserEmail"},
                  {name:"Table",valueField:"EntityName"},
                  {name:"Operation",valueField:"Operation"},
                  {name:"Query",valueField:"executedQuery"},
                  {name:"Date",valueField:"CreatedDate"},
                  ]}
                />
                ) : (
                <div className="empty-list-msg">
                There is no data available to show
                </div>
                )}{" "}
            </div>
          </Column.Body>
        </Column>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(AdminActivityManagement));
