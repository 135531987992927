import React, { Component } from "react";
import { Column, Button } from "../../components";
import "./MyAccount.scss";
import { changePasswordApi } from "../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: true,
      isCollapsed3: true,
      isUpdate: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }
  componentDidMount = () => {};

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === "OldPassword") {
      this.setState({
        oldPassword: value,
      });
    }
    if (name === "NewPassword") {
      this.setState({
        newPassword: value,
      });
    }
    if (name === "ConfirmPassword") {
      this.setState({
        confirmPassword: value,
      });
    }
  };
  handleSubmitBtnClick = () => {
    //const { oldPassword, newPassword, confirmPassword } = this.props;
    const oldPassword = this.state.oldPassword;
    const newPassword = this.state.newPassword;
    const confirmPassword = this.state.confirmPassword;

    if (oldPassword.trim() === null || oldPassword.trim() === "") {
      const info = {
        message: this.props.labels.changePasswordOldPasswordRequried,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }

    if (
      newPassword.trim() === null ||
      newPassword === "" ||
      newPassword.length < 6
    ) {
      const info = {
        message: this.props.labels.changePasswordNewPasswordInvalid,
        status: "error",
      };
      this.props.setNotificationAction(info);
    }

    if (confirmPassword.trim() === null || confirmPassword.trim() === "") {
      const info = {
        message: this.props.labels.changePasswordConfirmPasswordRequried,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }

    if (newPassword !== confirmPassword) {
      const info = {
        message: this.props.labels.changePasswordMissMatchPassword,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }

    var passwordObj = {
      OldPassword: oldPassword,
      NewPassword: newPassword,
      RepeatPassword: confirmPassword,
    };
    this.props.toggleLoadingAction(true);
    changePasswordApi(passwordObj).then((result) => {
      if (result.success) {
        const info = {
          message: this.props.labels.Password_Changed_Success,
          status: "success",
        };
        this.props.toggleLoadingAction(false);
        this.setState({
          newPassword: "",
          oldPassword: "",
          confirmPassword: "",
        });
        this.props.setNotificationAction(info);
      } else {
        const info = {
          message: this.props.labels
            .USER_SETTING_OLDPASSWORD_VALIDATION_MESSAGE,
          status: "error",
        };
        this.props.setNotificationAction(info);
        return;
      }
    });
  };
  ButtonChangePasswordClick = (item) => {
    this.setState({
      isCollapsed2: false,
      isUpdate: true,
    });
  };
  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      oldPassword,
      newPassword,
      confirmPassword,
    } = this.state;
    return (
      <div className="my-accounts-wraper columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_MY_ACCOUNT_LABEL}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_MY_ACCOUNT_LABEL}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-management-role-col1">
              <div className="list-wrapper">
                <div
                  className={`list-item`}
                  onClick={this.ButtonChangePasswordClick}
                >
                  <label
                    test-data-id={"btn-logout"}
                    className={"setFontto15AndBold"}
                  >
                    {"Change Password"}
                  </label>
                </div>
              </div>
            </div>
          </Column.Body>
        </Column>

        <Column collapse={isCollapsed2} className="col-1">
          <Column.Collapsed
            text={"Change Password"}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Change Password"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-save-form">
              <div className={`input-field`}>
                <h6>{"Old Password"}</h6>
                <div>
                  <input
                    name={"OldPassword"}
                    type={"password"}
                    value={oldPassword}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-identifier"}
                  />
                </div>
              </div>
              <div className={`input-field`}>
                <h6>{"New Password"}</h6>
                <div>
                  <input
                    name={"NewPassword"}
                    type={"password"}
                    value={newPassword}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-text"}
                  />
                </div>
              </div>
              <div className={`input-field`}>
                <h6>{"Confirm Password"}</h6>
                <div>
                  <input
                    name={"ConfirmPassword"}
                    type={"password"}
                    value={confirmPassword}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-text"}
                  />
                </div>
              </div>
            </div>
            <button
              test-data-id={"btn-logout"}
              className="btn btn-login btn-SubmitPass"
              onClick={this.handleSubmitBtnClick}
            >
              {"Submit"}
            </button>
          </Column.Body>
        </Column>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(MyAccount));
