import {
    LandingPage,
    Dashboard,
    NoMatch,
    SystemLabels,
    Features,
    Lookups,
    LookupCorrelation,
    AdminManagementRole,
    AdminManagementAdmin,
    ConfigurationVersion,
    ConfigurationTermsAndConditions,
    ConfigurationLegalNotice,
    ConfigurationPrivacyPolicy,
    ConfigurationCookieAgreement,
    AdminActivityManagement,
    AdminFeaturesManagement,
    MyAccount,
    BusinessUsers,
    Products,
    Feedback,
    Email
}  from '../views';


export const publicRoutes={
    default:{
        id:1,
        name:"LandingPage",
        path:'/',
        component:LandingPage
    },
    noMatch:{
        id:2,
        name:"NoMatch",
        path:'',
        component:NoMatch
    },
    emailverification: {
        id: 3,
        name: "adminEmailverification",
        path: "/admin-email-verification",
        component: LandingPage
      },
};

export const privateRoutes={
    dashboard:{
        id:101,
        name:"Dashboard",
        path:'/dashboard',
        component:Dashboard
    },
    SystemLabels:{
        id:104,
        name:"SystemLabels",
        path:'/system-labels',
        component:SystemLabels
    },
    LookupCorrelation:{
        id:105,
        name:"LookupCorrelation",
        path:'/lookup-correlation',
        component:LookupCorrelation
    },
    features:{
        id:106,
        name:"Features",
        path:'/features',
        component:Features
    },
    lookups:{
        id:105,
        name:"Lookups",
        path:'/lookups',
        component:Lookups
    },
    AdminManagementRole:{
        id:105,
        name:"AdminManagementRole",
        path:'/admin-management-role',
        component:AdminManagementRole
    },
    AdminActivityManagement:{
        id:105,
        name:"AdminActivity",
        path:'/admin-management-events',
        component:AdminActivityManagement
    },
    AdminManagementAdmin:{
        id:105,
        name:"AdminManagementAdmin",
        path:'/admin-management-admin',
        component:AdminManagementAdmin
    },
    AdminFeaturesManagement:{
        id:105,
        name:"AdminFeaturesManagement",
        path:'/admin-management-features',
        component:AdminFeaturesManagement
    },
    configurationVersion:{
        id:105,
        name:"ConfigurationVersion",
        path:'/configurationVersion',
        component:ConfigurationVersion
    },
    ConfigurationTermsAndConditions:{
        id:105,
        name:"ConfigurationTermsAndConditions",
        path:'/ConfigurationTermsAndConditions',
        component:ConfigurationTermsAndConditions
    },
    ConfigurationLegalNotice:{
        id:105,
        name:"ConfigurationLegalNotice",
        path:'/ConfigurationLegalNotice',
        component:ConfigurationLegalNotice
    },
    ConfigurationPrivacyPolicy:{
        id:105,
        name:"ConfigurationPrivacyPolicy",
        path:'/ConfigurationPrivacyPolicy',
        component:ConfigurationPrivacyPolicy
    },
    ConfigurationCookieAgreement:{
        id:105,
        name:"ConfigurationCookieAgreement",
        path:'/ConfigurationCookieAgreement',
        component:ConfigurationCookieAgreement
    },
    MyAccount:{
        id:106,
        name:"MyAccount",
        path:'/MyAccount',
        component:MyAccount
    },
    BusinessUsers:{
        id:107,
        name:"BusinessUsers",
        path:'/business-users',
        component:BusinessUsers
    },
    Products:{
        id:108,
        name:"Products",
        path:'/products',
        component:Products
    },
    Feedback:{
        id:109,
        name:"Feedback",
        path:'/feedbacks',
        component:Feedback
    },
    Email:{
        id:106,
        name:"Email",
        path:'/email',
        component:Email
    },
};