import React from "react";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import { CustomInput, Select } from "../../../components";

class AddFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  render() {
    const {
      onFormFieldChange,
      onFormFieldBlur,
      currentSubModule,
      lastFocusField,
      onFormSelectChange,
      modules,
      labels,
      selectedFeature,
    } = this.props;
    const {
      Name = "",
      isValidName = true,
      Label = "",
      isValidLabel = true,
      CssClass = "",
      Order = "",
      Url = "",
      isValidCssClass = true,
      CssClassIcon = "",
      isValidCssClassIcon = true,
      inValidSelectedParent,
      SubNavigation = [],
      appTypes,
      filteredFeatureAreas,
      filteredFeatures,
      selectedAppType,
      selectedFeatureArea,
      selectedParent,
    } = selectedFeature;

    return (
      <div className="feature-form">
        <div className="input-select-field setHeight50p">
          <h6>{"App Types"}</h6>
          <Select
            name="FeeCurrencyType"
            className={`select-input select-appType`}
            placeholder={"Please select a app type"}
            value={(selectedAppType && selectedAppType) || ""}
            onChange={(selectedOption) =>
              onFormSelectChange("AppType", selectedOption, 1)
            }
            clearable={false}
            searchable={false}
            options={appTypes}
          />
        </div>

        <div className="input-select-field setHeight50p">
          <h6>{"Features Area"}</h6>
          <Select
            name="FeeCurrencyType"
            className={`select-input select-featureArea`}
            placeholder={"Please select a feature area"}
            value={(selectedFeatureArea && selectedFeatureArea) || ""}
            onChange={(selectedOption) =>
              onFormSelectChange("FeaturesArea", selectedOption, 1)
            }
            clearable={false}
            searchable={false}
            options={filteredFeatureAreas}
          />
        </div>
        <div className="input-select-field setHeight50p">
          <h6>{"Parent Feature"}</h6>
          <Select
            name="FeeCurrencyType"
            className={`select-input select-parentFeature`}
            placeholder={"Please select a parent feature"}
            value={(selectedParent && selectedParent) || ""}
            onChange={(selectedOption) =>
              onFormSelectChange("Feature", selectedOption, 1)
            }
            clearable={false}
            searchable={false}
            options={filteredFeatures}
          />
        </div>
        <CustomInput
          label={"Name"}
          name={"Name"}
          value={Name || ""}
          onChange={(e) => onFormFieldChange(e, 2)}
          onBlur={(e) => onFormFieldBlur(e, 2)}
          // isLoading={lastFocusField == "Name"}
          validate={true}
          testId={"feature-Name"}
          isValid={isValidName}
        />
        <CustomInput
          label={"Label"}
          name={"Label"}
          value={Label || ""}
          onChange={(e) => onFormFieldChange(e, 2)}
          onBlur={(e) => onFormFieldBlur(e, 2)}
          // isLoading={lastFocusField == "Label"}
          validate={false}
          testId={"feature-Label"}
          isValid={isValidLabel}
        />
        <CustomInput
          label={"CssClassIcon"}
          name={"CssClassIcon"}
          value={CssClassIcon || ""}
          onChange={(e) => onFormFieldChange(e, 2)}
          onBlur={(e) => onFormFieldBlur(e, 2)}
          // isLoading={lastFocusField == "CssClassIcon"}
          validate={false}
          testId={"feature-CssClassIcon"}
          isValid={isValidCssClassIcon}
        />
        <CustomInput
          label={"URL"}
          name={"Url"}
          value={Url || ""}
          onChange={(e) => onFormFieldChange(e, 2)}
          onBlur={(e) => onFormFieldBlur(e, 2)}
          // isLoading={lastFocusField == "CssClass"}
          validate={false}
          testId={"feature-CssClass"}
          isValid={isValidCssClass}
        />

        <CustomInput
          label={"Order"}
          name={"Order"}
          value={Order || ""}
          onChange={(e) => onFormFieldChange(e, 2)}
          onBlur={(e) => onFormFieldBlur(e, 2)}
          // isLoading={lastFocusField == "CssClass"}
          validate={false}
          testId={"feature-CssClass"}
          isValid={isValidCssClass}
        />
        
      </div>
    );
  }
}

export default AddFeature;
