const USER_INFO="User";
const LABELS="Labels";

export class StorageService{

    static saveUser=({user}) =>{
        localStorage.setItem(USER_INFO,JSON.stringify(user));
    }
    static getUser=()=>{
        const user=localStorage.getItem(USER_INFO);
        return JSON.parse(user);
    }
    static isAuthenticated = ()=>{
        const user = JSON.parse(localStorage.getItem(USER_INFO));
        if(user){
            return user.IsAuthenticated && user.IsAuthenticated;
        }
        return false;
    }
    static removeUser = () =>{
        localStorage.removeItem(USER_INFO);
    }
    static getToken = () =>{
        const user = JSON.parse(localStorage.getItem(USER_INFO));
        if(user){
            return user.Token;
        }
        return "";
    }
    static saveLabels = (labels) =>{
        localStorage.setItem(LABELS,JSON.stringify(labels));
    }
    static getLabels = () =>{
        return JSON.parse(localStorage.getItem(LABELS)) || [];
    }
}
