import { ApiUrls } from "../../Services/apiUrls";
import axios from "axios";

export const GetLookupDataByType = (lt) => {
  return axios
    .get(ApiUrls.Admin.GetLookupDataByType(lt))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const GetCorrelationData = (lt, pk) => {
  return axios
    .get(ApiUrls.Admin.CorrelationDataGet(lt, pk))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const UpdateLookupCorrelatedDataItem = (
  lookupType,
  lookupPk,
  pk,
  crValue
) => {
  return axios
    .get(
      ApiUrls.Admin.UpdateLookupCorrelatedDataItem(
        lookupType,
        lookupPk,
        pk,
        crValue
      )
    )
    .then(({ data }) => data)
    .catch((response) => response);
};
export const AddCorrelationData = (lookupType, pk, Id) => {
  return axios
    .get(ApiUrls.Admin.AddCorrelationData(lookupType, pk, Id))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const DeleteLookupCorrelatedDataItem = (pk, lookupType, Id) => {
  return axios
    .get(ApiUrls.Admin.DeleteLookupCorrelatedDataItem(pk, lookupType, Id))
    .then(({ data }) => data)
    .catch((response) => response);
};
