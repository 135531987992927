import React from "react";
import { getAllCountriesApi } from "../../../../Services/apiCalls";
import loadingIcon from "../../../../Assests/Images/loading.gif";
import { CustomInput, Select } from "../../../../components";

class AdminForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      onFormSelectChange,
      adminFieldChange,
      Roles,
      addSystemlabelFieldChange,
      adminData,
      languages,
      addSystemLabelSelectChange,
      handleSystemlabelSave,
      labels,
      isUpdate,
      adminRegister,
      adminFormBlur
    } = this.props;
    const { firstName, lastName, email, password, selectedRole } = adminData;
    return (
      <div className="admin-save-form">
        <div
          className={`${
            !selectedRole.value ? "inValid " : " "
          } input-select-field `}
        >
          <h6>{labels.ADMIN_ROLES_LABEL}</h6>
          <Select
            name="roles"
            className={`select-input select-role`}
            placeholder={"Please select a role"}
            value={(selectedRole && selectedRole) || ""}
            onChange={(selectedOption) =>
              onFormSelectChange("Roles", selectedOption)
            }
            onBlur={() => adminFormBlur()}
            clearable={false}
            searchable={false}
            options={Roles}
          />
        </div>

        <div className={`${!firstName ? "inValid " : " "} input-field `}>
          <h6>{labels.FIRSTNAME_LABEL}</h6>
          <div>
            <input
              name={"firstName"}
              type={"text"}
              value={firstName}
              onChange={(e) => adminFieldChange(e)}
              test-data-id={"input-label-identifier"}
              onBlur={() => adminFormBlur()}
            />
          </div>
        </div>

        <div className={`${!lastName ? "inValid " : " "} input-field `}>
          <h6>{labels.LASTNAME_LABEL}</h6>
          <div>
            <input
              name={"lastName"}
              type={"text"}
              value={lastName}
              onChange={(e) => adminFieldChange(e)}
              test-data-id={"input-label-text"}
              onBlur={() => adminFormBlur()}
            />
          </div>
        </div>

        <div className={`${!email ? "inValid " : " "} input-field `}>
          <h6>{labels.ADMIN_EMAIL_LABEL}</h6>
          <div>
            <input
              disabled={isUpdate ? true : false}
              name={"email"}
              type={"text"}
              value={email}
              onChange={(e) => adminFieldChange(e)}
              test-data-id={"input-field-email"}
            />
          </div>
        </div>

        {isUpdate ? (
          ""
        ) : (
          <div className={`${!password ? "inValid " : " "} input-field `}>
            <h6>{labels.ADMIN_PASSWORD_LABEL}</h6>
            <div>
              <input
                name={"password"}
                type={"text"}
                value={password}
                onChange={(e) => adminFieldChange(e)}
                test-data-id={"input-field-password"}
                onBlur={adminRegister}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AdminForm;
