
export const ApiUrls={
    Account:{
        LOGIN: `Account/AdminLogin`,
        ADMIN_ALLOWED_FEATURE: `RoleFeatures/GetAdminAllowedFeatures`,
        SIGNUP: `User`,
        VERIFICATION:token=>`account/AdminEmailConfirmation?confirmationToken=${token}`,
        ForgotPassword: `Account/SendEmail`,
    },
    User: {
        GetUser: "User/GetUserDetail"
    },
    Admin:{
        getLanguages: `Admin/GetLanguages`,
        GetLookupDataByType: (lt)=>`Admin/GetLookupData?lookupType=${lt}`,
        CorrelationDataGet: (lt,pk)=>`Admin/GetCorrelatedData?lookupType=${lt}&pk=${pk}`,
        UpdateLookupCorrelatedDataItem: (lt,ltpk,pk,crValue)=>`Admin/UpdateLookupCorrelatedDataItem?lookupType=${lt}&lookupPk=${ltpk}&pk=${pk}&correlationValue=${crValue}`,
        AddCorrelationData: (lt,pk,Id)=>`Admin/AddCorrelationData?lookupType=${lt}&pk=${pk}&correlatedItemId=${Id}`,
        DeleteLookupCorrelatedDataItem: (pk,lt,Id)=>`Admin/DeleteLookupCorrelatedDataItem?mainItemPk=${pk}&lookupType=${lt}&pk=${Id}`,
    },

    Email:{
        GetEmails: `Email/GetAllEmails`,
        SendEmail: `Email/SendEmail`,
        DeleteEmail: (Id)=>`Email/${Id}`,
    },

    SystemLabel: {
        systemLabel: `SystemLabel/GetSystemLabel`,
        GetSubmoduleBymoduleId: (id)=>`SystemLabel/GetModulesByParrentId?PID=${id}`,
        searchSystemLabel: (sm)=>`SystemLabel/SystemLabelFIlter?searchmode=${sm}`,
        GetSystemLabelByLanguageId: (Id)=>`SystemLabel/GetSystemLabelByLanguageId?languageId=${Id}`,
        updateSystemLabel:`SystemLabel/Put`,
        getCategories: `SystemLabel/GetSystemlabelCategories`,
        getUserTypes: `SystemLabel/GetSystemlabelUserTypes`,
        saveSystemlabel: `SystemLabel/Post`,        
        AddSystemLabelsFromExcel:`SystemLabel/AddSystemLabelsFromExcel`
    },

    Module: {
        GetModules: `Module/AllModuleLookup`
    },
    FeaturesAreas: {
        GetFeaturesAreas: `FeaturesAreas/AllFeaturesAreasLookup`
    },

    AppTypes: {
        GetAppTypes: `AppTypes/AllAppTypesLookup`
    },

    Features: {
        GETALL: `MobileFeatures/GetAllFeatures`,
        GetFeaturesByModule: (moduleId, appTypeId ) =>
          `MobileFeatures/GetFeaturesByModule?moduleId=${moduleId}&appTypeId=${appTypeId}`,
        PUT:(featureId) =>
        `MobileFeatures?id=${featureId}`,
        updateUserFeature:() =>
        `UserFeatures/UpdateUserFeature`,
        UpdateRoleFeature: `RoleFeatures/UpdateRoleFeature?_dc=1606838749343`,
        updateMultiUserFeatures:() =>
        `UserFeatures/UpdateMultipleUserFeature`,
        Delete:(featureId) =>
        `MobileFeatures/DeleteFeature?id=${featureId}`,
        POST: `MobileFeatures/PostFeature`,
        GetFeatures: (appType, featureType, userId ) =>
          `MobileFeatures/GetUserFeatures?appType=${appType}&featureType=${featureType}&userId=${userId}`,
        GetRoleFeatures: (roleId ) =>
          `RoleFeatures/GetRoleFeatures?_dc=1606814362571&roleId=${roleId}`,
    },
    
    SubModule: {
        GetSubModules: `SubModule/AllSubModuleLookup`,
        POST: `SubModule/Post`,
        UPDATE:`SubModule/Put`,
        DELETE:id=>`SubModule/Delete/?subModuleId=${id}`
    },

    SubFeature: {
        GetSubFeatures: (subModuleId) => `SubFeature/AllSubFeatureLookup?subModuleId=${subModuleId}`,
        POST: `SubFeature/Post`,
        UPDATE:`SubFeature/Put`,
        DELETE:id=>`SubFeature/Delete/?subFeatureId=${id}`
    },
    
    SideNavigation: {
        GetUserFeatures: ({ featureTypeId, appTypeId }) =>
          `UserFeatures/GetUserFeaturesByFeatureType?featureTypeId=${featureTypeId}&appTypeId=${appTypeId}`,
    },
    Workplace:{
        GETALL: `Workplace`,
        GET: (id)=>`Workplace/${id}`,
        POST: `Workplace`,
        UPDATE:`Workplace`,
        DELETE:id=>`Workplace/Delete/${id}`
    },
    WorkplaceContacts:{
        GETALL: `WorkplaceContacts`,
        GET: (id)=>`WorkplaceContacts/${id}`,
        POST: `WorkplaceContacts`,
        UPDATE:`WorkplaceContacts`,
        DELETE:id=>`WorkplaceContacts/Delete/${id}`
    },
    Countries:{
        GETALL: `Country`
    },
    WorkplaceSubscriptionConfiguration:{
        GET: (id)=>`WorkplaceSubscriptionConfiguration/GetSubscriptionConfigurationWithValues?workplaceSubscriptionId=${id}`,
        POST: `WorkplaceSubscriptionConfiguration/AddConfigurationValue`,
        Update:(id,value,workplaceId,subConfigId)=>`WorkplaceSubscriptionConfiguration/UpdateConfigurationValue?id=${id}&value=${value}&workplaceId=${workplaceId}&subConfigId=${subConfigId}`,
        Delete: (id)=>`WorkplaceSubscriptionConfiguration/DeleteSubscriptionAndConfiguration?workplaceSubscriptionId=${id}`,
    },
    Languages:{
        GETALL: `Language/GetAllLanguages`
    },
    Lookups:{
        GETALL: `Language/GetAllLookUps`
    },
    SelectedLookups:{
        GETALL:(lookupType)=>`Admin/GetLookupData?lookupType=${lookupType}`
    },
    AddLookup:{
        GETALL:(lookupType,ItemName)=>`Admin/AddLookupData?lookupType=${lookupType}&itemName=${ItemName}`
    },
    DeleteLookup:{
        GETALL:(lookupType, lookupitemId, ItemName)=>`Admin/DeleteLookupDataItem?lookupType=${lookupType}&pk=${lookupitemId}&searchVal=${ItemName}`
    },
    LookupTypeStatus:{
        GETALL: `Language/GetAllLookupsStatus`
    },
    LookupCandidateType:{
        GETALL: (lookupType)=>`BuisnessIntelligence/GetBIData?lookupType=${lookupType}`
    },
    Users:{
        GetAllUsers: `User/GetAllUsers`,
        GetAllAdmins: `User/GetAllAdmins`,
        RegisterAdmin: `Account/RegisterAdmin?_dc=1606741510233`,
        UpdateAdmin: `Account/UpdateAdmin?_dc=1606799121115`,
        DeleteUser: `Account/DeleteUser?_dc=1606812032281`,

    },
    Roles:{
        GetRoles: `Account/GetAllRoles?_dc=1606543191874`,
        CreateRole: `Account/CreateRole`,
        DeleteRole: `Account/DeleteRole`,
    },
    Events:{
        GETALL:(dateFrom,dateTo)=>`UserEvents/GetAllEvents?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        GETEventsByUserId:(uId,dateFrom,dateTo)=>`UserEvents/GetAllEventsByUserIdAndDate?userId=${uId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    },
    ConfigurationGet:{
        GETALL: (releaseTitle)=>`configuration/GetConfigurationContent?title=${releaseTitle}`
    },
    AddConfig:{
        POST:`configuration/AddUpdateConfiguration`
    },
    Settings: {
        ChangePassword: `Account/ChangePassword`,        
      },
    Scripts:{
        CREATEDB: (dbName,isQuick,wpSubId)=>`Scripts/CreateDatabase?databaseName=${dbName}&isQuick=${isQuick}&wpSubId=${wpSubId}`,
        FILLDB: (dbName,isQuick)=>`Scripts/InsertLookup?databaseName=${dbName}&isQuick=${isQuick}`,
        CLONE_CORE:(domainName)=>`Scripts/CloneProdooCore?domainName=${domainName}`,
        CLONE_WEB:(domainName)=>`Scripts/CloneProdooWeb?domainName=${domainName}`,
        CLONE_ADMIN:(domainName)=>`Scripts/CloneProdooAdmin?domainName=${domainName}`,
        CREATE_PRODOO_WEB:(domainName)=>`Scripts/CreateProdooWebsite?domainName=${domainName}`,
        CREATE_PRODOO_WEBAPI:(domainName)=>`Scripts/CreateProdooWebApi?domainName=${domainName}`,
        CREATE_PRODOO_ADMIN:(domainName)=>`Scripts/CreateProdooAdminWebsite?domainName=${domainName}`,
        CREATE_PRODOO_ADMIN_WEBAPI:(domainName)=>`Scripts/CreateProdooAdminWebApi?domainName=${domainName}`,
        CREATE_ADMIN:(email,domainName)=>`Scripts/CreateProdooAdmin?email=${email}&domainName=${domainName}`,
        IS_DOMAIN_AVAILABLE:(domainName)=>`Scripts/IsDomainAvailable?domainName=${domainName}`,
        STOP_WEBSITES: (domainName,wpSubId)=>`Scripts/StopIGEWebsite?domainName=${domainName}&wpSubId=${wpSubId}`,
        RESUME_WEBSITES: (domainName,wpSubId)=>`Scripts/ResumeIGEWebsite?domainName=${domainName}&wpSubId=${wpSubId}`,
        DEALLOCATE_RESOURCES: (domainName,wpSubId,type)=>`Scripts/DeallocateResources?domainName=${domainName}&wpSubId=${wpSubId}&type=${type}`,
        UPGRADE_WEBSITES:(domainName)=>`Scripts/UpgradeProdooWebsite?domainName=${domainName}`,
    },
    
    BuisnessUsers: {
        GETALL: `DemoUser/getAllDemoUsers`,
        POST:`DemoUser/Post`,
        PUT:`DemoUser/Put`,
        Delete:`DemoUser/ProdooAdmin.model.DemoUser`
    },
    Products: {
        GETALL: `Product/GetAllProducts`,
        POST: `Product/Post`,
        Put: `Product/Put`,
        Delete: `Product/Delete`,
    },
    AdminFeedback: {
        GETALL: `Feedback/GetAdminFeedbacks`,
        REPLY: `Feedback/ReplyFeedback`,
        Delete: `Feedback/DeleteFeedback`,
    },
}
export const ProdooApiUrls = {
    AffiliateSite:{
        POST: `Affiliate/Add`,
    }
}