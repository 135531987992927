export const ACTION_TYPES={
    ACCOUNT:{
        SAVE_USER:"SAVE_USER",
        DELETE_USER:"DELETE_USER"
    },
    NOTIFICATION:{
        SET_NOTIFICATION:"SET_NOTIFICATION"
    },
    LABELS:{
        SET_LABELS:"SET_LABELS"
    },
    LOADING:{
        TOGGLE_LOADING:"TOGGLE_LOADING",
    }
}