import React, { Component } from "react";
import CKEditor from "ckeditor4-react";
import { Column, Button } from "../../components";
import "./configuration.scss";

import { getConfiguration, AddConfigItemApi } from "../../Services/apiCalls";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
class ConfigurationTermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      searchKey: "",
      TitleConfig: "Terms and Condition",
    };
  }
  componentDidMount = () => {
    let releaseTitle = this.state.TitleConfig;
    getConfiguration(releaseTitle)
      .then((res) => {
        this.setState({
          searchKey: res.items,
        });
      })
      .catch((err) => console.log(err));
  };
  //New Code
  handleSearchBlurChange = (e) => {
    let Configvalue = this.state.searchKey;
    let ConfigName = this.state.TitleConfig;
    let model = {
      ConfigurationId: 0,
      ConfigName: ConfigName,
      ConfigValue: Configvalue,
      IsActive: true,
    };
    //for api call
    this.props.toggleLoadingAction(true);
    AddConfigItemApi(model)
      .then((res) => {
        this.props.toggleLoadingAction(false);
      })
      .catch((err) => console.log(err));
  };

  handleSearchChange = (e) => {
    this.setState({
      searchKey: e.editor.getData(),
    });
  };
  //End new Code
  render() {
    const { labels } = this.props;
    const { isHelpActive, isCollapsed1 } = this.state;
    return (
      <div className="columns-wrapper lookup-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={"Configuration"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Terms and Condition"}</p>
            </div>
          </Column.Head>
          <Column.Body>
            <form test-data-id={`configuration-terms-conditions`}>
              <CKEditor
                type="classic"
                data={this.state.searchKey}
                onChange={this.handleSearchChange}
              />
            </form>
            <Button
              className="btn btn-AddForConfigEditor"
              text="Add"
              onClick={this.handleSearchBlurChange}
            />
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(ConfigurationTermsAndConditions));
