import React from "react";
import loadingIcon from "../../Assests/Images/loader.gif";

class CustomInput extends React.Component {
  render() {
    const {
      label = "",
      name,
      value,
      onChange,
      onBlur,
      isLoading,
      type = "text",
      validate = false,
      testId,
      inputType=1,
      isValid=true,
      item=null
    } = this.props;
    return (
      <div
        className={`${!isValid || (validate && !value) ? "inValid " : " "} input-field setHeight50p ${
          !label ? "no-label" : ""
        }`}
      >
        <h6>{label}</h6>
        <div>
          <input
            name={name}
            type={type}
            value={!value ? "" : value}
            onChange={e => onChange(e, inputType,item,type)}
            onBlur={e => onBlur(e, inputType,item,type)}
            test-data-id={testId}
          />
           {isLoading && <div id="loader-wrapper"><div test-data-id ={"loader"} id="loader"/></div> }
        </div>
      </div>
    );
  }
}

export default CustomInput;
